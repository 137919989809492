define("ln-ember-form-elements/templates/components/form-elements/radio-group", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.HTMLBars.template({
    "id": "1EawVBXD",
    "block": "{\"symbols\":[\"option\"],\"statements\":[[4,\"each\",[[23,0,[\"formatedOptions\"]]],null,{\"statements\":[[0,\"  \"],[1,[28,\"form-elements/styled-checkbox\",null,[[\"theme\",\"label\",\"value\",\"readonly\",\"onChange\"],[[23,0,[\"theme\"]],[23,1,[\"text\"]],[23,1,[\"value\"]],[23,1,[\"readonly\"]],[28,\"action\",[[23,0,[]],\"onOptionChange\",[23,1,[\"id\"]]],null]]]],false],[0,\"\\n\"]],\"parameters\":[1]},null]],\"hasEval\":false}",
    "meta": {
      "moduleName": "ln-ember-form-elements/templates/components/form-elements/radio-group.hbs"
    }
  });
});