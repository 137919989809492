define("ln-ember-data-models/transforms/string-array", ["exports", "ember-data/transform"], function (_exports, _transform) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = _transform.default.extend({
    deserialize: function deserialize(serialized) {
      return serialized;
    },
    serialize: function serialize(deserialized) {
      if (Ember.isNone(deserialized)) {
        return null;
      }
      return deserialized.map(function (item) {
        return "".concat(item);
      });
    }
  });
});