define("ln-ember-form-elements/templates/components/form-elements/styled-button", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.HTMLBars.template({
    "id": "JwUMyJKO",
    "block": "{\"symbols\":[\"@confirm\",\"@confirmPlace\",\"&default\"],\"statements\":[[4,\"if\",[[23,0,[\"loading\"]]],null,{\"statements\":[[0,\"  \"],[7,\"div\",true],[10,\"class\",\"loader\"],[8],[0,\"\\n    \"],[7,\"svg\",true],[10,\"class\",\"circular\"],[10,\"viewBox\",\"25 25 50 50\"],[8],[0,\"\\n      \"],[7,\"circle\",true],[10,\"class\",\"path\"],[10,\"cx\",\"50\"],[10,\"cy\",\"50\"],[10,\"r\",\"20\"],[10,\"fill\",\"none\"],[10,\"stroke-width\",\"4\"],[10,\"stroke-miterlimit\",\"10\"],[8],[9],[0,\"\\n    \"],[9],[0,\"\\n  \"],[9],[0,\"\\n\"]],\"parameters\":[]},null],[0,\"\\n\"],[14,3],[0,\"\\n\\n\"],[4,\"if\",[[23,0,[\"dialogVisible\"]]],null,{\"statements\":[[4,\"dialog-tooltip-on-parent\",null,[[\"confirm\",\"cancel\",\"place\"],[[28,\"action\",[[23,0,[]],\"confirm\"],null],[28,\"action\",[[23,0,[]],\"cancel\"],null],[23,2,[]]]],{\"statements\":[[0,\"    \"],[1,[23,1,[]],false],[0,\"\\n\"]],\"parameters\":[]},null]],\"parameters\":[]},null]],\"hasEval\":false}",
    "meta": {
      "moduleName": "ln-ember-form-elements/templates/components/form-elements/styled-button.hbs"
    }
  });
});