define("ln-ember-data-models/models/vdc/fragments/article-review-marketing-object", ["exports", "ember-data", "ember-data-model-fragments/fragment"], function (_exports, _emberData, _fragment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var attr = _emberData.default.attr;

  /**
   * @typedef {Object} VdcArticleReviewMarketingObjectFragmentModelAttributes
   * @property {number} id
   * @property {number} marketing_category_id
   * @property {string} marketing_category_name
   * @property {string} name
   */

  /**
   * @class VdcArticleReviewMarketingObjectFragment
   * @extends Fragment
   */
  var VdcArticleReviewMarketingObjectFragment = _fragment.default.extend({
    /**
     * @memberof VdcArticleReviewMarketingObjectFragment
     * @instance
     *
     * @type {VdcArticleReviewMarketingObjectFragmentModelAttributes}
     */
    model_attributes: attr('raw'),
    /**
     * @memberof VdcArticleReviewMarketingObjectFragment
     * @instance
     *
     * @type {Number}
     */
    position: attr('number'),
    marketing_object: Ember.computed('_marketingObjectLoaded', 'model_attributes.id', function () {
      var _this = this;
      if (!this.model_attributes.id) {
        return null;
      }
      var marketingObject = this.store.peekRecord('vdc/marketing-object', this.model_attributes.id);
      if (!marketingObject && !this._marketingObjectLoaded) {
        return this.store.findRecord('vdc/marketing-object', this.model_attributes.id).finally(function () {
          // Side-effect is needed for caching
          // eslint-disable-next-line ember/no-side-effects
          _this.set('_marketingObjectLoaded', true);
        });
      }
      return marketingObject;
    }),
    _marketingObjectLoaded: null
  });
  var _default = _exports.default = VdcArticleReviewMarketingObjectFragment;
});