define("ln-ember-data-models/models/myliga/fragments/time-entry-assignment-option", ["exports", "ember-data", "ember-data-model-fragments/fragment"], function (_exports, _emberData, _fragment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var attr = _emberData.default.attr;

  /**
   * @class Myliga/fragments/TimeEntryAssignmentOptionFragment
   * @extends Fragment
   */
  var _default = _exports.default = _fragment.default.extend({
    /**
     * @memberof Myliga/fragments/TimeEntryAssignmentOptionFragment
     * @instance
     *
     * @type {number}
     */
    department_id: attr('id', {
      readOnly: true
    }),
    /**
     * @memberof Myliga/fragments/TimeEntryAssignmentOptionFragment
     * @instance
     *
     * @type {string}
     */
    department_name: attr('nullable-string', {
      readOnly: true
    }),
    /**
     * @memberof Myliga/fragments/TimeEntryAssignmentOptionFragment
     * @instance
     *
     * @type {number}
     */
    liga3000_project_id: attr('id', {
      readOnly: true
    }),
    /**
     * @memberof Myliga/fragments/TimeEntryAssignmentOptionFragment
     * @instance
     *
     * @type {string}
     */
    liga3000_project_name: attr('nullable-string', {
      readOnly: true
    }),
    /**
     * @memberof Myliga/fragments/TimeEntryAssignmentOptionFragment
     * @instance
     *
     * @type {string}
     */
    liga3000_project_number: attr('nullable-string', {
      readOnly: true
    }),
    /**
     * @memberof Myliga/fragments/TimeEntryAssignmentOptionFragment
     * @instance
     *
     * @type {string}
     */
    liga3000_project_label: attr('nullable-string', {
      readOnly: true
    }),
    /**
     * @memberof Myliga/fragments/TimeEntryAssignmentOptionFragment
     * @instance
     *
     * @type {number}
     */
    offered_service_id: attr('id', {
      readOnly: true
    }),
    /**
     * @memberof Myliga/fragments/TimeEntryAssignmentOptionFragment
     * @instance
     *
     * @type {string}
     */
    offered_service_name: attr('nullable-string', {
      readOnly: true
    }),
    /**
     * @memberof Myliga/fragments/TimeEntryAssignmentOptionFragment
     * @instance
     *
     * @type {Array}
     */
    project_leader_user_metas: attr({
      defaultValue: function defaultValue() {
        return [];
      }
    }),
    /**
     * @memberof Myliga/fragments/TimeEntryAssignmentOptionFragment
     * @instance
     *
     * @type {boolean}
     */
    unlocked: attr('boolean', {
      readOnly: true
    })
  });
});