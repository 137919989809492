define("ln-ember-data-models/models/vdc/navigation", ["exports", "ember-data"], function (_exports, _emberData) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var attr = _emberData.default.attr,
    Model = _emberData.default.Model;

  /**
   * @class Vdc/NavigationModel
   * @extends DS.Model
   */
  var _default = _exports.default = Model.extend({
    /**
     * @memberof Vdc/NavigationModel
     * @instance
     *
     * @type {Number}
     */
    animation_icon_id: attr('id'),
    /**
     * @memberof Vdc/NavigationModel
     * @instance
     *
     * @type {String}
     */
    animation_icon_url: attr('nullable-string'),
    /**
     * @memberof Vdc/NavigationModel
     * @instance
     *
     * @type {Number}
     */
    application_id: attr('id'),
    /**
     * @memberof Vdc/NavigationModel
     * @instance
     *
     * @type {Number}
     */
    icon_id: attr('id'),
    /**
     * @memberof Vdc/NavigationModel
     * @instance
     *
     * @type {String}
     */
    icon_url: attr('nullable-string'),
    /**
     * @memberof Vdc/NavigationModel
     * @instance
     *
     * @type {Boolean}
     */
    is_mobile_only: attr('boolean', {
      defaultValue: function defaultValue() {
        return false;
      }
    }),
    /**
     * @memberof Vdc/NavigationModel
     * @instance
     *
     * @type {Boolean}
     */
    is_themed: attr('boolean', {
      defaultValue: function defaultValue() {
        return false;
      }
    }),
    /**
     * @memberof Vdc/NavigationModel
     * @instance
     *
     * @type {String}
     */
    item_type: attr('nullable-string'),
    /**
     * @memberof Vdc/NavigationModel
     * @instance
     *
     * @type {String}
     */
    name: attr('nullable-string'),
    /**
     * @memberof Vdc/NavigationModel
     * @instance
     *
     * @type {Number}
     */
    parent_id: attr('id'),
    /**
     * @memberof Vdc/NavigationModel
     * @instance
     *
     * @type {Number}
     */
    position: attr('number'),
    /**
     * @memberof Vdc/NavigationModel
     * @instance
     *
     * @type {Boolean}
     */
    sub_nav_is_menu: attr('boolean', {
      defaultValue: function defaultValue() {
        return false;
      }
    }),
    /**
     * @memberof Vdc/NavigationModel
     * @instance
     *
     * @type {Array}
     */
    sub_navigations: attr(),
    /**
     * @memberof Vdc/NavigationModel
     * @instance
     *
     * @type {String}
     */
    url: attr('nullable-string'),
    /**
     * @memberof Vdc/NavigationModel
     * @instance
     *
     * @type {Number}
     */
    version: attr('number')
  });
});