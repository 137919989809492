define("ln-ember-data-models/models/vdc/marketing-category", ["exports", "ember-data"], function (_exports, _emberData) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var attr = _emberData.default.attr,
    belongsTo = _emberData.default.belongsTo,
    Model = _emberData.default.Model;

  /**
   * @class Vdc/MarketingCategoryModel
   * @extends DS.Model
   */
  var _default = _exports.default = Model.extend({
    /**
     * @memberof Vdc/MarketingCategoryModel
     * @instance
     *
     * @type {Number}
     */
    client_id: attr('id'),
    /**
     * @memberof Vdc/MarketingCategoryModel
     * @instance
     *
     * @type {Number}
     */
    fallback_image_id: attr('id'),
    /**
     * @memberof Vdc/MarketingCategoryModel
     * @instance
     *
     * @type {String}
     */
    full_path: attr('string'),
    /**
     * @memberof Vdc/MarketingCategoryModel
     * @instance
     *
     * @type {String}
     */
    name: attr('string'),
    /**
     * @memberof Vdc/MarketingCategoryModel
     * @instance
     *
     * @type {Number}
     */
    parent_id: attr('id'),
    /**
     * @memberof Vdc/MarketingCategoryModel
     * @instance
     *
     * @type {Number}
     */
    position: attr('number', {
      defaultValue: 0
    }),
    /**
     * @memberof Vdc/MarketingCategoryModel
     * @instance
     *
     * @type {Number}
     */
    version: attr('number'),
    /**
     * @memberof Vdc/MarketingCategoryModel
     * @instance
     *
     * @type {Vdc/PictureModel}
     */
    fallback_image: belongsTo('vdc/picture')
  });
});