define("ln-ember-data-models/models/myliga/block", ["exports", "ember-data"], function (_exports, _emberData) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  /* eslint ligadigital/ember-model-version-attribute: Off */

  var Model = _emberData.default.Model,
    attr = _emberData.default.attr;

  /**
   * @class Myliga/BlockModel
   * @extends DS.Model
   */
  var _default = _exports.default = Model.extend({
    /**
     * @memberof Myliga/BlockModel
     * @instance
     *
     * @type {String}
     */
    title: attr('nullable-string'),
    /**
     * @memberof Myliga/BlockModel
     * @instance
     *
     * @type {String}
     */
    type: attr('nullable-string'),
    /**
     * @memberof Myliga/BlockModel
     * @instance
     *
     * @type {String}
     */
    size: attr('nullable-string'),
    /**
     * @memberof Myliga/BlockModel
     * @instance
     *
     * @type {Object}
     */
    content: attr(),
    /**
     * @memberof Myliga/BlockModel
     * @instance
     *
     * @type {String}
     */
    author_name: attr('nullable-string'),
    /**
     * @memberof Myliga/BlockModel
     * @instance
     *
     * @type {String}
     */
    created_at: attr('date')
  });
});