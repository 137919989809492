define("ln-ember-toolkit/utils/format-compact-price", ["exports", "ln-ember-toolkit/utils/format-compact-number", "ln-ember-toolkit/utils/format-number"], function (_exports, _formatCompactNumber, _formatNumber) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = formatCompactPrice;
  /**
    1234.56 → '1,2k€'
    123456 → '123,5k€'
    123456789 → '123,5m€'
  */
  function formatCompactPrice(rawPrice) {
    var options = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : {};
    var _options$currency = options.currency,
      currency = _options$currency === void 0 ? true : _options$currency;
    if (rawPrice === null || typeof rawPrice === 'undefined') {
      return '';
    }
    var strVal = String(rawPrice).trim();
    if (!(0, _formatNumber.isNumeric)(strVal)) {
      return strVal;
    }
    var localized = (0, _formatCompactNumber.default)(rawPrice);
    if (localized === '') {
      return '';
    }
    return "".concat(localized).concat(currency ? '€' : '');
  }
});