define("ln-ember-form-elements/utils/weak-union-by", ["exports", "ln-ember-form-elements/utils/weak-find"], function (_exports, _weakFind) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = weakUnionBy;
  /**
   * Add unique item to collection.<br>
   * Unique is checked by the property parameter. </br>
   * Mainly used for add Options in the option list
   *
   * @param {array} collection
   * @param {array} newItems
   * @param {string} [property="id"]
   * @return {array}
   */
  function weakUnionBy(collection, newItems) {
    var property = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : 'id';
    newItems.forEach(function (item) {
      var newItem = Ember.assign({}, item);
      var foundItem = (0, _weakFind.default)(collection, item, property);
      if (foundItem) {
        Ember.A(collection).removeObject(foundItem);
      }
      collection.push(newItem);
    });
    return collection;
  }
});