define("ln-ember-data-models/models/vdc/mapped-attribute-set", ["exports", "ember-data"], function (_exports, _emberData) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var Model = _emberData.default.Model,
    attr = _emberData.default.attr,
    hasMany = _emberData.default.hasMany;

  /**
   * @class Vdc/MappedAttributeSetModel
   * @extends DS.Model
   */
  var _default = _exports.default = Model.extend({
    /**
     * @memberof Vdc/MappedAttributeSetModel
     * @instance
     *
     * @type {Number}
     */
    version: attr('number'),
    /**
     * @memberof Vdc/MappedAttributeSetModel
     * @instance
     *
     * @type {AttributeModel[]}
     */
    attributes: hasMany('vdc/attribute')
  });
});