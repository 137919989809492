define("ln-ember-form-elements/components/form-elements/date-range-input-base", ["exports", "moment", "ln-ember-form-elements/components/form-elements/date-input-base", "ln-ember-form-elements/templates/components/form-elements/date-range-input"], function (_exports, _moment, _dateInputBase, _dateRangeInput) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  function _slicedToArray(r, e) { return _arrayWithHoles(r) || _iterableToArrayLimit(r, e) || _unsupportedIterableToArray(r, e) || _nonIterableRest(); }
  function _nonIterableRest() { throw new TypeError("Invalid attempt to destructure non-iterable instance.\nIn order to be iterable, non-array objects must have a [Symbol.iterator]() method."); }
  function _unsupportedIterableToArray(r, a) { if (r) { if ("string" == typeof r) return _arrayLikeToArray(r, a); var t = {}.toString.call(r).slice(8, -1); return "Object" === t && r.constructor && (t = r.constructor.name), "Map" === t || "Set" === t ? Array.from(r) : "Arguments" === t || /^(?:Ui|I)nt(?:8|16|32)(?:Clamped)?Array$/.test(t) ? _arrayLikeToArray(r, a) : void 0; } }
  function _arrayLikeToArray(r, a) { (null == a || a > r.length) && (a = r.length); for (var e = 0, n = Array(a); e < a; e++) n[e] = r[e]; return n; }
  function _iterableToArrayLimit(r, l) { var t = null == r ? null : "undefined" != typeof Symbol && r[Symbol.iterator] || r["@@iterator"]; if (null != t) { var e, n, i, u, a = [], f = !0, o = !1; try { if (i = (t = t.call(r)).next, 0 === l) { if (Object(t) !== t) return; f = !1; } else for (; !(f = (e = i.call(t)).done) && (a.push(e.value), a.length !== l); f = !0); } catch (r) { o = !0, n = r; } finally { try { if (!f && null != t.return && (u = t.return(), Object(u) !== u)) return; } finally { if (o) throw n; } } return a; } }
  function _arrayWithHoles(r) { if (Array.isArray(r)) return r; }
  /**
   * @class formElements/DateRangeInputBaseComponent
   * @extends formElements/DateInputBaseComponent
   * @abstract
   */
  var _default = _exports.default = _dateInputBase.default.extend({
    /**
     * @memberof formElements/DateRangeInputBaseComponent
     * @instance
     *
     * @type {String}
     * @default null
     */
    startLabel: null,
    /**
     * @memberof formElements/DateRangeInputBaseComponent
     * @instance
     *
     * @type {String}
     * @default null
     */
    endLabel: null,
    /**
     * If this is a truthy value the input field will be set into the error state.
     *
     * @memberof formElements/DateRangeInputBaseComponent
     * @instance
     *
     * @type {any}
     * @default null
     */
    startError: null,
    /**
     * If this is a truthy value the input field will be set into the error state.
     *
     * @memberof formElements/DateRangeInputBaseComponent
     * @instance
     *
     * @type {any}
     * @default null
     */
    endError: null,
    classNames: ['form-elements--date-range-input-base-component'],
    layout: _dateRangeInput.default,
    date: null,
    /**
     * @memberof formElements/DateRangeInputBaseComponent
     * @instance
     *
     * @type {String}
     * @default "Select a date"
     */
    startPlaceholder: 'Select a date',
    /**
     * @memberof formElements/DateRangeInputBaseComponent
     * @instance
     *
     * @type {String}
     * @default "Select a date"
     */
    endPlaceholder: 'Select a date',
    displayValue: Ember.computed('range.{start,end}', 'value.{start,end}', 'showDropDown', {
      get: function get() {
        var _ref = this.get('value') || {},
          start = _ref.start,
          end = _ref.end;
        return {
          start: this.formatDate(start),
          end: this.formatDate(end)
        };
      },
      set: function set(key, value) {
        return value;
      }
    }),
    range: Ember.computed('value.{start,end}', function () {
      var _ref2 = this.get('value') || {},
        start = _ref2.start,
        end = _ref2.end;
      if (!start) {
        return {
          start: null,
          end: null
        };
      }
      if (!end) {
        end = start;
      }
      var _sort = [start, end].sort(function (a, b) {
        return (0, _moment.default)(a).isAfter(b);
      });
      var _sort2 = _slicedToArray(_sort, 2);
      start = _sort2[0];
      end = _sort2[1];
      return {
        start: start,
        end: end
      };
    })
  });
});