function _typeof(o) { "@babel/helpers - typeof"; return _typeof = "function" == typeof Symbol && "symbol" == typeof Symbol.iterator ? function (o) { return typeof o; } : function (o) { return o && "function" == typeof Symbol && o.constructor === Symbol && o !== Symbol.prototype ? "symbol" : typeof o; }, _typeof(o); }
define("ln-ember-form-elements/components/form-elements/date-time-range-input", ["exports", "moment", "ln-ember-form-elements/components/form-elements/date-range-input-base", "ln-ember-form-elements/templates/components/form-elements/date-time-range-input", "ln-ember-form-elements/utils/date-helper"], function (_exports, _moment, _dateRangeInputBase, _dateTimeRangeInput, _dateHelper) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  function _defineProperty(e, r, t) { return (r = _toPropertyKey(r)) in e ? Object.defineProperty(e, r, { value: t, enumerable: !0, configurable: !0, writable: !0 }) : e[r] = t, e; }
  function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == _typeof(i) ? i : i + ""; }
  function _toPrimitive(t, r) { if ("object" != _typeof(t) || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != _typeof(i)) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
  function _slicedToArray(r, e) { return _arrayWithHoles(r) || _iterableToArrayLimit(r, e) || _unsupportedIterableToArray(r, e) || _nonIterableRest(); }
  function _nonIterableRest() { throw new TypeError("Invalid attempt to destructure non-iterable instance.\nIn order to be iterable, non-array objects must have a [Symbol.iterator]() method."); }
  function _unsupportedIterableToArray(r, a) { if (r) { if ("string" == typeof r) return _arrayLikeToArray(r, a); var t = {}.toString.call(r).slice(8, -1); return "Object" === t && r.constructor && (t = r.constructor.name), "Map" === t || "Set" === t ? Array.from(r) : "Arguments" === t || /^(?:Ui|I)nt(?:8|16|32)(?:Clamped)?Array$/.test(t) ? _arrayLikeToArray(r, a) : void 0; } }
  function _arrayLikeToArray(r, a) { (null == a || a > r.length) && (a = r.length); for (var e = 0, n = Array(a); e < a; e++) n[e] = r[e]; return n; }
  function _iterableToArrayLimit(r, l) { var t = null == r ? null : "undefined" != typeof Symbol && r[Symbol.iterator] || r["@@iterator"]; if (null != t) { var e, n, i, u, a = [], f = !0, o = !1; try { if (i = (t = t.call(r)).next, 0 === l) { if (Object(t) !== t) return; f = !1; } else for (; !(f = (e = i.call(t)).done) && (a.push(e.value), a.length !== l); f = !0); } catch (r) { o = !0, n = r; } finally { try { if (!f && null != t.return && (u = t.return(), Object(u) !== u)) return; } finally { if (o) throw n; } } return a; } }
  function _arrayWithHoles(r) { if (Array.isArray(r)) return r; }
  /**
   *
   * ### Value
   *
   * The value is a object holding `start` and `end` in the iso format or `null`.
   *
   * ### Examples
   *
   * #### Basic usage:
   *
   * For the basic usage the only required attributes are the current `value` and
   * handler for `onChange` events.
   *
   * ```Handlebars
   * {{form-elements/date-time-range-input
   *   value=dateValue
   *   onChange=(action "onDateChange")
   * }}
   * ```
   *
   * ```JavaScript
   * Component.extend({
   *   actions: {
   *     onDateChange(value) {
   *       this.set('dateValue', value)
   *     }
   *   }
   * })
   * ```
   *
   * ### Demo
   *
   * <iframe src="showcase/index.html#/demo/date-time-range-input" style="width: 360px;border: 1px solid black;height:450px;padding: 20px;"></iframe>
   *
   * @class formElements/DateTimeRangeInputComponent
   * @extends formElements/DateInputRangeBaseComponent */
  var _default = _exports.default = _dateRangeInputBase.default.extend({
    /**
     * @memberof formElements/DateInputRangeBaseComponent
     * @instance
     *
     * @type {object}
     * @property {string} start -  ISO date format
     * @property {string} end -  ISO date format
     * @default null
     */
    value: null,
    classNames: ['form-elements--date-time-range-input-component'],
    layout: _dateTimeRangeInput.default,
    timeValue: Ember.computed('value.{start,end}', {
      get: function get() {
        var _ref = this.get('value') || {},
          start = _ref.start,
          end = _ref.end;
        return {
          start: (0, _dateHelper.formatDate)(start, 'HH:mm'),
          end: (0, _dateHelper.formatDate)(end, 'HH:mm')
        };
      },
      set: function set(key, value) {
        return value;
      }
    }),
    actions: {
      onCalendarChange: function onCalendarChange(value) {
        var _ref2 = this.get('value') || {},
          start = _ref2.start,
          end = _ref2.end;
        var startTime = start ? (0, _moment.default)(start).format('HH:mm') : '00:00';
        var endTime = end ? (0, _moment.default)(end).format('HH:mm') : '00:00';
        if (start && end && start !== end || !start) {
          start = value;
          end = value;
          endTime = startTime;
        } else {
          end = value;
        }
        start = this.applyTime(start, startTime);
        end = this.applyTime(end, endTime);
        var _sort = [start, end].sort(function (a, b) {
          return (0, _moment.default)(a).isAfter(b) ? 1 : -1;
        });
        var _sort2 = _slicedToArray(_sort, 2);
        start = _sort2[0];
        end = _sort2[1];
        this.triggerChange({
          start: start,
          end: end
        });
      },
      onInputChange: function onInputChange(type, value) {
        if (!value) {
          return this.triggerChange(null);
        }
        var dateFormat = this.get('formElements.defaults.formatDateTime');
        var date = (0, _moment.default)("".concat(value, " +0200"), "".concat(dateFormat, " ZZ"), true);
        if (!date.isValid()) {
          return;
        }
        var range = Object.assign({}, this.get('range'), _defineProperty({}, type, date.toISOString()));
        if (this.isValid(range)) {
          this.triggerChange(range);
        }
      },
      onTimeInputChange: function onTimeInputChange(type, time) {
        if (!/^\d\d:\d\d$/.test(time)) {
          return;
        }
        var range = Object.assign({}, this.get('range'), _defineProperty({}, type, this.applyTime(this.get("range.".concat(type)), time)));
        if (this.isValid(range)) {
          this.triggerChange(range);
        }
      }
    },
    formatDate: function formatDate(date) {
      return (0, _dateHelper.formatDate)(date, this.get('formElements.defaults.formatDateTime'));
    },
    getVisibleDate: function getVisibleDate() {
      return (0, _moment.default)(this.get('value.start') || undefined, _moment.default.ISO_8601).format('YYYY-MM-DD');
    },
    isValidInput: function isValidInput(value) {
      var _ref3 = value || {},
        start = _ref3.start,
        end = _ref3.end;
      return (0, _moment.default)(start, _moment.default.ISO_8601, true).isValid() && (Ember.isNone(end) || (0, _moment.default)(end, _moment.default.ISO_8601, true).isValid());
    },
    isValid: function isValid(value) {
      var _ref4 = value || {},
        start = _ref4.start,
        end = _ref4.end;
      return (0, _moment.default)(start, _moment.default.ISO_8601, true).isValid() && (0, _moment.default)(end, _moment.default.ISO_8601, true).isValid();
    },
    triggerChange: function triggerChange(range) {
      var _ref5 = range || {},
        start = _ref5.start,
        end = _ref5.end;
      this.get('onChange')(range && {
        start: start && (0, _moment.default)(start).toISOString(),
        end: end && (0, _moment.default)(end).toISOString()
      });
    }
  });
});