define("ln-ember-data-models/transforms/enum-string", ["exports", "ember-data"], function (_exports, _emberData) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var Transform = _emberData.default.Transform;
  function validateEnum(value, options) {
    if (Ember.isNone(value)) {
      return null;
    }
    var values = Ember.isArray(options) ? options.map(function (option) {
      return typeof option === 'string' ? option : option.id;
    }) : Object.values(options);
    if (!values.includes(value)) {
      return null;
    }
    return value;
  }
  var _default = _exports.default = Transform.extend({
    deserialize: function deserialize(serialized) {
      return serialized;
    },
    serialize: function serialize(deserialized, _ref) {
      var options = _ref.options;
      return validateEnum(deserialized, options);
    }
  });
});