define("ln-ember-form-elements/templates/components/form-elements/option-button", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.HTMLBars.template({
    "id": "F9alvtdh",
    "block": "{\"symbols\":[\"option\"],\"statements\":[[4,\"if\",[[23,0,[\"label\"]]],null,{\"statements\":[[7,\"label\",true],[11,\"for\",[23,0,[\"elementId\"]]],[8],[1,[23,0,[\"label\"]],false],[9]],\"parameters\":[]},null],[0,\"\\n\\n\"],[4,\"each\",[[23,0,[\"renderOptions\"]]],null,{\"statements\":[[4,\"form-elements/styled-button\",null,[[\"theme\",\"action\"],[[28,\"if\",[[23,1,[\"active\"]],[23,0,[\"highlightTheme\"]],[23,0,[\"theme\"]]],null],[28,\"action\",[[23,0,[]],\"setValue\",[23,1,[]]],null]]],{\"statements\":[[0,\"    \"],[1,[23,1,[\"label\"]],false],[0,\"\\n\"]],\"parameters\":[]},null]],\"parameters\":[1]},null]],\"hasEval\":false}",
    "meta": {
      "moduleName": "ln-ember-form-elements/templates/components/form-elements/option-button.hbs"
    }
  });
});