define("ln-ember-data-models/models/vdc/motif", ["exports", "ember-data", "ember-data-model-fragments/attributes"], function (_exports, _emberData, _attributes) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var attr = _emberData.default.attr,
    belongsTo = _emberData.default.belongsTo,
    Model = _emberData.default.Model;

  /**
   * @class Vdc/MotifModel
   * @extends DS.Model
   */
  var _default = _exports.default = Model.extend({
    /**
     * @memberof Vdc/MotifModel
     * @instance
     *
     * @type {Number}
     */
    version: attr('number'),
    /**
     * @memberof Vdc/MotifModel
     * @instance
     *
     * @type {Boolean}
     */
    active: attr('boolean', {
      defaultValue: function defaultValue() {
        return true;
      }
    }),
    /**
     * @memberof Vdc/MotifModel
     * @instance
     *
     * @type {String}
     */
    name: attr('nullable-string'),
    /**
     * @memberof Vdc/MotifModel
     * @instance
     *
     * @type {String}
     */
    description: attr('nullable-string'),
    /**
     * @memberof Vdc/MotifModel
     * @instance
     *
     * @type {Boolean}
     */
    valid_in_all_projects: attr('boolean', {
      defaultValue: function defaultValue() {
        return false;
      }
    }),
    /**
     * @memberof Vdc/MotifModel
     * @instance
     *
     * @type {String}
     */
    date_begin: attr('date-string'),
    /**
     * @memberof Vdc/MotifModel
     * @instance
     *
     * @type {String}
     */
    date_end: attr('date-string'),
    /**
     * @memberof Vdc/MotifModel
     * @instance
     *
     * @type {Array}
     */
    files: attr({
      defaultValue: function defaultValue() {
        return [];
      }
    }),
    /**
     * @memberof Vdc/MotifModel
     * @instance
     *
     * @type {Number}
     */
    picture_id: attr('id'),
    /**
     * @memberof Vdc/MotifModel
     * @instance
     *
     * @type {Number}
     */
    client_id: attr('id'),
    /**
     * @memberof Vdc/MotifModel
     * @instance
     *
     * @type {Array}
     */
    project_ids: attr('number-array', {
      defaultValue: function defaultValue() {
        return [];
      }
    }),
    /**
     * @memberof Vdc/MotifModel
     * @instance
     *
     * @type {Number}
     */
    approved_croppings: attr('number'),
    /**
     * @memberof Vdc/MotifModel
     * @instance
     *
     * @type {Number}
     */
    total_croppings: attr('number'),
    /**
     * @memberof Vdc/MotifModel
     * @instance
     *
     * @type {Object}
     */
    picture: belongsTo('vdc/picture'),
    /**
     * @memberof Vdc/MotifModel
     * @instance
     *
     * @type {Vdc/AttributeSetModel}
     */
    attribute_set: belongsTo('vdc/attribute-set'),
    /**
     * @memberof Vdc/MotifModel
     * @instance
     *
     * @type {Vdc/fragments/AttributeValueFragment[]}
     */
    attribute_values: (0, _attributes.fragmentArray)('vdc/fragments/attribute-value'),
    /**
     * @memberof Vdc/MotifModel
     * @instance
     *
     * @type {Number}
     */
    notApprovedCroppingsCount: Ember.computed('approved_croppings', 'total_croppings', function () {
      return this.get('total_croppings') - this.get('approved_croppings');
    })
  });
});