define("ln-ember-data-models/models/vdc/article-review", ["exports", "ember-data", "ln-ember-data-models/utils/enum-option", "ember-data-model-fragments/attributes"], function (_exports, _emberData, _enumOption, _attributes) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.statusOptions = _exports.default = void 0;
  var attr = _emberData.default.attr,
    belongsTo = _emberData.default.belongsTo,
    Model = _emberData.default.Model,
    hasMany = _emberData.default.hasMany;

  /**
   * @name Vdc/ArticleReviewModel/statusOptions
   * @type {EnumOption[]}
   */
  var statusOptions = _exports.statusOptions = [_enumOption.default.create({
    id: 'New'
  }), _enumOption.default.create({
    id: 'Draft'
  }), _enumOption.default.create({
    id: 'ReadyForReview'
  }), _enumOption.default.create({
    id: 'AdaptWithComments'
  }), _enumOption.default.create({
    id: 'Approved'
  }), _enumOption.default.create({
    id: 'Canceled'
  }), _enumOption.default.create({
    id: 'Empty'
  }), _enumOption.default.create({
    id: 'DoNotReviewNow'
  }), _enumOption.default.create({
    id: 'BriefReview'
  }), _enumOption.default.create({
    id: 'BriefWithComment'
  }), _enumOption.default.create({
    id: 'BriefSubmitted'
  })];

  /**
   * @class Vdc/ArticleReviewModel
   * @extends DS.Model
   */
  var ArticleReviewModel = Model.extend({
    /**
     * @memberof Vdc/ArticleReviewModel
     * @instance
     *
     * @type {Number}
     */
    version: attr('number'),
    /**
     * @memberof Vdc/ArticleReviewModel
     * @instance
     *
     * @type {Number}
     */
    project_id: attr('id'),
    /**
     * @memberof Vdc/ArticleReviewModel
     * @instance
     *
     * @type {Number}
     */
    country_id: attr('id'),
    /**
     * @memberof Vdc/ArticleReviewModel
     * @instance
     *
     * @type {Number}
     */
    language_id: attr('id'),
    /**
     * @memberof Vdc/ArticleReviewModel
     * @instance
     *
     * @type {Number}
     */
    article_id: attr('id'),
    /**
     * @memberof Vdc/ArticleReviewModel
     * @instance
     *
     * @type {Number}
     */
    picture_id: attr('id'),
    /**
     * @memberof Vdc/ArticleReviewModel
     * @instance
     *
     * @type {Boolean}
     */
    approved_by_market: attr('boolean', {
      defaultValue: function defaultValue() {
        return false;
      }
    }),
    /**
     * @memberof Vdc/ArticleReviewModel
     * @instance
     *
     * @type {Boolean}
     */
    approved_by_hq: attr('boolean', {
      defaultValue: function defaultValue() {
        return false;
      }
    }),
    /**
     * @memberof Vdc/ArticleReviewModel
     * @instance
     *
     * @type {Boolean}
     */
    active: attr('boolean', {
      defaultValue: function defaultValue() {
        return true;
      }
    }),
    /**
     * @memberof Vdc/ArticleReviewModel
     * @instance
     *
     * @type {Boolean}
     */
    outdated: attr('boolean', {
      defaultValue: function defaultValue() {
        return false;
      }
    }),
    /**
     * @memberof Vdc/ArticleReviewModel
     * @instance
     *
     * @type {String}
     */
    approval_date: attr('date-string'),
    /**
     * @memberof Vdc/ArticleReviewModel
     * @instance
     *
     * @type {String}
     */
    status: attr('enum-string', {
      options: statusOptions
    }),
    /**
     * @memberof Vdc/ArticleReviewModel
     * @instance
     *
     * @type {Vdc/TextModuleTranslation[]}
     */
    translations: hasMany('vdc/text-module-translation', {
      readOnly: true,
      async: false
    }),
    /**
     * @memberof Vdc/ArticleReviewModel
     * @instance
     *
     * @type {Vdc/Comment[]}
     */
    comments: hasMany('vdc/comment', {
      readOnly: true,
      async: false
    }),
    /**
     * @memberof Vdc/ArticleReviewModel
     * @instance
     *
     * @type {Vdc/Language}
     */
    language: belongsTo('vdc/language', {
      readOnly: true,
      async: false
    }),
    /**
     * @memberof Vdc/ArticleReviewModel
     * @instance
     *
     * @type {Vdc/Article}
     */
    article: belongsTo('vdc/article', {
      readOnly: true,
      async: false
    }),
    /**
     * @memberof Vdc/ArticleReviewModel
     * @instance
     *
     * @type {Vdc/Revision}
     */
    preview_picture: belongsTo('vdc/revision', {
      readOnly: true,
      async: false
    }),
    /**
     * @memberof Vdc/ArticleReviewModel
     * @instance
     *
     * @type {Vdc/Revision[]}
     */
    pictures: hasMany('vdc/revision', {
      readOnly: true,
      async: false
    }),
    /**
     * @memberof Vdc/ArticleReviewModel
     * @instance
     *
     * @type {Vdc/Article}
     */
    country: belongsTo('vdc/country', {
      readOnly: true,
      async: false
    }),
    /**
      * @memberof Vdc/ArticleReviewModel
      * @instance
      *
      * @type {Vdc/fragments/ArticleReviewMarketingObjectFragment[]}
      */
    marketing_objects: (0, _attributes.fragmentArray)('vdc/fragments/article-review-marketing-object'),
    /**
      * @memberof Vdc/ArticleReviewModel
      * @instance
      *
      * @type {Vdc/fragments/ArticleReviewMarketingObjectFragment[]}
      */
    marketing_object_priorities: (0, _attributes.fragmentArray)('vdc/fragments/article-review-marketing-object-priority'),
    /**
     * @memberof Vdc/ArticleReviewModel
     * @instance
     *
     * @type {Vdc/fragments/ArticleMarketingCategoryFragment[]}
     */
    marketing_categories: (0, _attributes.fragmentArray)('vdc/fragments/article-marketing-category')
  });
  ArticleReviewModel.reopenClass({
    /**
     * @memberof Vdc/ArticleReviewModel
     * @static
     *
     * @type {EnumOption[]}
     */
    statusOptions: statusOptions
  });
  var _default = _exports.default = ArticleReviewModel;
});