define("ln-ember-form-elements/utils/-private/escape-mask", ["exports", "imask"], function (_exports, _imask) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = escapeMask;
  function escapeChar(char) {
    return "".concat(_imask.MaskedPattern.ESCAPE_CHAR).concat(char);
  }
  function escapeMask(mask) {
    if (!mask) {
      return '';
    }
    // eslint-disable-next-line no-useless-escape
    var reservedChars = /[0a*\[\]{}`]/g;
    return "".concat(mask).replace(reservedChars, escapeChar);
  }
});