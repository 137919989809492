define("ln-ember-data-models/models/vdc/notification", ["exports", "ember-data"], function (_exports, _emberData) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var attr = _emberData.default.attr,
    belongsTo = _emberData.default.belongsTo,
    Model = _emberData.default.Model;

  /**
   * @class Vdc/NotificationModel
   * @extends DS.Model
   */
  var _default = _exports.default = Model.extend({
    /**
     * @memberof Vdc/NotificationModel
     * @instance
     *
     * @type {Number}
     */
    version: attr('number'),
    /**
     * @memberof Vdc/NotificationModel
     * @instance
     *
     * @type {string}
     */
    subject: attr('nullable-string'),
    /**
     * @memberof Vdc/NotificationModel
     * @instance
     *
     * @type {string}
     */
    message: attr('nullable-string'),
    /**
     * @memberof Vdc/NotificationModel
     * @instance
     *
     * @type {string}
     */
    datetime: attr('date-time-string'),
    /**
     * @memberof Vdc/NotificationModel
     * @instance
     *
     * @type {Number}
     */
    context_id: attr('id'),
    /**
     * @memberof Vdc/NotificationModel
     * @instance
     *
     * @type {string}
     */
    context_type: attr('nullable-string'),
    /**
     * @memberof Vdc/NotificationModel
     * @instance
     *
     * @type {boolean}
     */
    read_state: attr('boolean', {
      defaultValue: function defaultValue() {
        return false;
      }
    }),
    /**
     * @memberof Vdc/NotificationModel
     * @instance
     *
     * @type {boolean}
     */
    opened: attr('boolean', {
      defaultValue: function defaultValue() {
        return true;
      }
    }),
    /**
     * @memberof Vdc/NotificationModel
     * @instance
     *
     * @type {boolean}
     */
    archived: attr('boolean', {
      defaultValue: function defaultValue() {
        return false;
      }
    }),
    /**
     * @memberof Vdc/NotificationModel
     * @instance
     *
     * @type {boolean}
     */
    flagged: attr('boolean', {
      defaultValue: function defaultValue() {
        return false;
      }
    }),
    /**
     * @memberof Vdc/NotificationModel
     * @instance
     *
     * @type {Number}
     */
    sender_id: attr('id'),
    /**
     * @memberof Vdc/NotificationModel
     * @instance
     *
     * @type {string}
     */
    priority: attr('nullable-string'),
    /**
     * @memberof Vdc/NotificationModel
     * @instance
     *
     * @type {string}
     */
    system_message_type: attr('nullable-string'),
    /**
     * @memberof Vdc/NotificationModel
     * @instance
     *
     * @type {string}
     */
    link: attr('nullable-string'),
    /**
     * @memberof Vdc/NotificationModel
     * @instance
     *
     * @type {Vdc/UserModel}
     */
    sender: belongsTo('vdc/user')
  });
});