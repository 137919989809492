define("ln-ember-form-elements/components/form-elements/date-time-input", ["exports", "moment", "ln-ember-form-elements/components/form-elements/date-input-base", "ln-ember-form-elements/templates/components/form-elements/date-time-input", "ln-ember-form-elements/utils/date-helper"], function (_exports, _moment, _dateInputBase, _dateTimeInput, _dateHelper) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  function _slicedToArray(r, e) { return _arrayWithHoles(r) || _iterableToArrayLimit(r, e) || _unsupportedIterableToArray(r, e) || _nonIterableRest(); }
  function _nonIterableRest() { throw new TypeError("Invalid attempt to destructure non-iterable instance.\nIn order to be iterable, non-array objects must have a [Symbol.iterator]() method."); }
  function _unsupportedIterableToArray(r, a) { if (r) { if ("string" == typeof r) return _arrayLikeToArray(r, a); var t = {}.toString.call(r).slice(8, -1); return "Object" === t && r.constructor && (t = r.constructor.name), "Map" === t || "Set" === t ? Array.from(r) : "Arguments" === t || /^(?:Ui|I)nt(?:8|16|32)(?:Clamped)?Array$/.test(t) ? _arrayLikeToArray(r, a) : void 0; } }
  function _arrayLikeToArray(r, a) { (null == a || a > r.length) && (a = r.length); for (var e = 0, n = Array(a); e < a; e++) n[e] = r[e]; return n; }
  function _iterableToArrayLimit(r, l) { var t = null == r ? null : "undefined" != typeof Symbol && r[Symbol.iterator] || r["@@iterator"]; if (null != t) { var e, n, i, u, a = [], f = !0, o = !1; try { if (i = (t = t.call(r)).next, 0 === l) { if (Object(t) !== t) return; f = !1; } else for (; !(f = (e = i.call(t)).done) && (a.push(e.value), a.length !== l); f = !0); } catch (r) { o = !0, n = r; } finally { try { if (!f && null != t.return && (u = t.return(), Object(u) !== u)) return; } finally { if (o) throw n; } } return a; } }
  function _arrayWithHoles(r) { if (Array.isArray(r)) return r; }
  /**
   *
   * ### Value
   *
   * The value is a string in the iso format or `null`.
   *
   * ### Examples
   *
   * #### Basic usage:
   *
   * For the basic usage the only required attributes are the current `value` and
   * handler for `onChange` events.
   *
   * ```Handlebars
   * {{form-elements/date-time-input
   *   value=dateValue
   *   onChange=(action "onDateChange")
   * }}
   * ```
   *
   * ```JavaScript
   * Component.extend({
   *   actions: {
   *     onDateChange(value) {
   *       this.set('dateValue', value)
   *     }
   *   }
   * })
   * ```
   *
   * ### Demo
   *
   * <iframe src="showcase/index.html#/demo/date-time-input" style="width: 360px;border: 1px solid black;height:450px;padding: 20px;"></iframe>
   *
   * @class formElements/DateTimeInputComponent
   * @extends formElements/DateInputBaseComponent
   */
  var _default = _exports.default = _dateInputBase.default.extend({
    /**
     * The value should be in the format `YYYY-MM-DD`.
     *
     * @memberof formElements/DateTimeInputComponent
     * @instance
     *
     * @type {string} - ISO date format
     * @default null
     */
    value: null,
    classNames: ['form-elements--date-time-input-component'],
    layout: _dateTimeInput.default,
    hasValue: Ember.computed.bool('value').readOnly(),
    init: function init() {
      this._super.apply(this, arguments);
      this.set('timeValue', (0, _dateHelper.formatDate)(this.get('value'), 'HH:mm'));
    },
    actions: {
      onIncrementFocusedElements: function onIncrementFocusedElements() {
        this._super.apply(this, arguments);
        this.ensureHasValue();
      },
      onDecrementFocusedElements: function onDecrementFocusedElements() {
        var dateFormat = this.get('formElements.defaults.formatDateTime');

        /**
         * If input is cleared, or displayValue does not match required date-time format,
         * reset the value to the original one.
         */
        if (!this.get('displayValue') || this.get('displayValue') !== (0, _moment.default)(this.get('value')).format(dateFormat)) {
          var date = (0, _moment.default)(this.get('value'), "".concat(dateFormat, " Z"));
          this.triggerChange(date);
        }
        this._super.apply(this, arguments);
      },
      onCalendarChange: function onCalendarChange(value) {
        value = this.applyTime(value, (0, _moment.default)(this.get('value')).format('HH:mm'));
        this.triggerChange((0, _moment.default)(value));
      },
      onInputChange: function onInputChange(value) {
        if (!value) {
          return;
        }
        var dateFormat = this.get('formElements.defaults.formatDateTime');
        var date = (0, _moment.default)("".concat(value), "".concat(dateFormat), true);
        if (this.isValid(date)) {
          this.triggerChange(date);
        }
      },
      onTimeInputFocusOut: function onTimeInputFocusOut(time) {
        var _this = this;
        var value = this.applyTime(this.get('value'), time);
        if (value) {
          this.triggerChange((0, _moment.default)(value));
        }
        Ember.run.next(function () {
          _this.send('onDecrementFocusedElements');
        });
      }
    },
    formatDate: function formatDate(date) {
      return (0, _dateHelper.formatDate)(date, this.get('formElements.defaults.formatDateTime'));
    },
    triggerChange: function triggerChange(value) {
      this.set('displayValue', (0, _moment.default)(value).format(this.get('formElements.defaults.formatDateTime')));
      this.set('timeValue', (0, _dateHelper.formatDate)(value, 'HH:mm'));
      this.get('onChange')(value && (0, _moment.default)(value).toISOString());
    },
    getVisibleDate: function getVisibleDate() {
      return (0, _moment.default)(this.get('value') || undefined).format('YYYY-MM-DD');
    },
    isValid: function isValid(value) {
      return (0, _moment.default)(value).isValid();
    },
    ensureHasValue: function ensureHasValue() {
      if (!this.get('hasValue')) {
        var date = new Date();
        if (this.get('defaultTime')) {
          var _this$get$split = this.get('defaultTime').split(':'),
            _this$get$split2 = _slicedToArray(_this$get$split, 2),
            hours = _this$get$split2[0],
            minutes = _this$get$split2[1];
          date.setHours(hours, minutes);
        }
        this.triggerChange((0, _moment.default)(date));
      }
    }
  });
});