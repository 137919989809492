define("ln-ember-toolkit/utils/format-number", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = formatNumber;
  _exports.isNumeric = isNumeric;
  function isNumeric(val) {
    return val.match(/^-?[0-9]+([.][0-9]+)?$/);
  }
  function formatNumber(val) {
    var options = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : {};
    if (val === null || typeof val === 'undefined') {
      return '';
    }
    var strVal = String(val).trim();
    if (!isNumeric(strVal)) {
      return strVal;
    }
    var parsed = parseFloat(strVal);

    // see: https://stackoverflow.com/a/11832950
    var rounded = Math.round(parsed * 100) / 100;
    return Number(rounded).toLocaleString('de', Object.assign({
      useGrouping: true
    }, options));
  }
});