define("ln-ember-form-elements/components/form-elements/calendar/month-list", ["exports", "moment", "jquery", "ln-ember-form-elements/templates/components/form-elements/calendar/month-list", "ln-ember-form-elements/utils/computed-properties"], function (_exports, _moment, _jquery, _monthList, _computedProperties) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  function _slicedToArray(r, e) { return _arrayWithHoles(r) || _iterableToArrayLimit(r, e) || _unsupportedIterableToArray(r, e) || _nonIterableRest(); }
  function _nonIterableRest() { throw new TypeError("Invalid attempt to destructure non-iterable instance.\nIn order to be iterable, non-array objects must have a [Symbol.iterator]() method."); }
  function _iterableToArrayLimit(r, l) { var t = null == r ? null : "undefined" != typeof Symbol && r[Symbol.iterator] || r["@@iterator"]; if (null != t) { var e, n, i, u, a = [], f = !0, o = !1; try { if (i = (t = t.call(r)).next, 0 === l) { if (Object(t) !== t) return; f = !1; } else for (; !(f = (e = i.call(t)).done) && (a.push(e.value), a.length !== l); f = !0); } catch (r) { o = !0, n = r; } finally { try { if (!f && null != t.return && (u = t.return(), Object(u) !== u)) return; } finally { if (o) throw n; } } return a; } }
  function _arrayWithHoles(r) { if (Array.isArray(r)) return r; }
  function _toConsumableArray(r) { return _arrayWithoutHoles(r) || _iterableToArray(r) || _unsupportedIterableToArray(r) || _nonIterableSpread(); }
  function _nonIterableSpread() { throw new TypeError("Invalid attempt to spread non-iterable instance.\nIn order to be iterable, non-array objects must have a [Symbol.iterator]() method."); }
  function _unsupportedIterableToArray(r, a) { if (r) { if ("string" == typeof r) return _arrayLikeToArray(r, a); var t = {}.toString.call(r).slice(8, -1); return "Object" === t && r.constructor && (t = r.constructor.name), "Map" === t || "Set" === t ? Array.from(r) : "Arguments" === t || /^(?:Ui|I)nt(?:8|16|32)(?:Clamped)?Array$/.test(t) ? _arrayLikeToArray(r, a) : void 0; } }
  function _iterableToArray(r) { if ("undefined" != typeof Symbol && null != r[Symbol.iterator] || null != r["@@iterator"]) return Array.from(r); }
  function _arrayWithoutHoles(r) { if (Array.isArray(r)) return _arrayLikeToArray(r); }
  function _arrayLikeToArray(r, a) { (null == a || a > r.length) && (a = r.length); for (var e = 0, n = Array(a); e < a; e++) n[e] = r[e]; return n; }
  var abs = Math.abs,
    cos = Math.cos,
    round = Math.round,
    ceil = Math.ceil,
    floor = Math.floor,
    PI = Math.PI;
  var _default = _exports.default = Ember.Component.extend({
    theme: null,
    layout: _monthList.default,
    classNames: ['form-elements--calendar--month-list-component'],
    classNameBindings: ['animated', 'themeClass'],
    targetPosition: null,
    numberOfElments: 9,
    elementHeight: 50,
    speed: 0,
    lastTouchY: 0,
    position: (0, _computedProperties.resetOnChange)('value', 0),
    // Format: YYYY-MM
    value: (0, _computedProperties.assertValue)((0, _moment.default)().format('YYYY-MM'), function (value) {
      return /^\d\d\d\d-\d\d$/.test(value);
    }),
    // Value format: YYYY-MM
    onSelect: (0, _computedProperties.defaultAction)(function (value) {
      this.set('value', value);
    }),
    valueOffset: (0, _computedProperties.resetOnChange)('value', 0),
    lastTime: 0,
    touchMoved: false,
    // px / ms
    animationSpeed: 0.2,
    themeClass: Ember.computed('theme', function () {
      return "theme-".concat(this.get('theme') || 'light');
    }),
    offset: Ember.computed('position', function () {
      return this.get('position') % this.get('elementHeight');
    }),
    valueOffsetUpdate: Ember.observer('position', function () {
      var position = this.get('position');
      var fn = position < 0 ? floor : ceil;
      var valueOffset = fn(this.get('position') / this.get('elementHeight') * -1);
      if (valueOffset !== this.get('valueOffset')) {
        this.set('valueOffset', valueOffset);
      }
    }),
    elements: Ember.computed('numberOfElments', 'valueOffset', 'value', function () {
      var _this = this;
      return _toConsumableArray(Array(this.get('numberOfElments'))).map(function (e, i) {
        var date = _this.date(i - 4 + _this.get('valueOffset'));
        return {
          year: date.format('YYYY'),
          month: date.format('MMMM'),
          value: date.format('YYYY-MM')
        };
      });
    }),
    elementStyle: Ember.computed('offset', function () {
      return Ember.String.htmlSafe("transform: translate(0, ".concat(this.get('offset') - this.get('elementHeight'), "px)"));
    }),
    stopPositionOffset: Ember.computed('offset', function () {
      var offset = this.get('offset');
      var correction = 0;
      if (offset > 25) {
        correction = 50 - offset;
      } else if (offset < -25) {
        correction = (50 + offset) * -1;
      } else {
        correction = offset * -1;
      }
      return correction;
    }),
    date: function date(idx) {
      return (0, _moment.default)(this.get('value'), 'YYYY-MM').add(idx, 'months').startOf('month');
    },
    didInsertElement: function didInsertElement() {
      var _this2 = this;
      this._super.apply(this, arguments);
      (0, _jquery.default)(this.element).on('wheel', function (event) {
        return _this2.onWheel(event);
      });
      (0, _jquery.default)(this.element).on('touchstart', function (event) {
        return _this2.onTouchStart(event);
      });
      (0, _jquery.default)(this.element).on('touchmove', function (event) {
        return _this2.onTouchMove(event);
      });
    },
    willREmoveElement: function willREmoveElement() {
      this._super.apply(this, arguments);
      (0, _jquery.default)(this.element).off('wheel touchstart touchmove');
    },
    onWheel: function onWheel(event) {
      event.preventDefault();
      this.stopAnimatation();
      var deltaY = event.originalEvent.deltaY,
        timeStamp = event.timeStamp;
      var _this$getProperties = this.getProperties(['lastTime', 'position']),
        lastTime = _this$getProperties.lastTime,
        position = _this$getProperties.position;
      var timeDelta = lastTime - timeStamp;
      this.setProperties({
        lastTime: timeStamp,
        position: position - deltaY,
        speed: deltaY / timeDelta
      });
      Ember.run.debounce(this, this.setStopPosition, 100);
    },
    onTouchStart: function onTouchStart(event) {
      event.preventDefault();
      this.stopAnimatation();
      var timeStamp = event.timeStamp,
        _event$changedTouches = _slicedToArray(event.changedTouches, 1),
        touchY = _event$changedTouches[0].clientY;
      this.setProperties({
        lastTouchY: touchY,
        lastTime: timeStamp
      });
    },
    onTouchMove: function onTouchMove(event) {
      event.preventDefault();
      var timeStamp = event.timeStamp,
        _event$changedTouches2 = _slicedToArray(event.changedTouches, 1),
        touchY = _event$changedTouches2[0].clientY;
      var _this$getProperties2 = this.getProperties(['lastTime', 'lastTouchY', 'position']),
        lastTime = _this$getProperties2.lastTime,
        lastTouchY = _this$getProperties2.lastTouchY,
        position = _this$getProperties2.position;
      var deltaY = lastTouchY - touchY;
      var timeDelta = lastTime - timeStamp;
      this.setProperties({
        lastTouchY: touchY,
        lastTime: timeStamp,
        position: position - deltaY,
        speed: deltaY / timeDelta,
        touchMoved: position !== 0 ? true : false
      });
      Ember.run.debounce(this, this.setStopPosition, 100);
    },
    setStopPosition: function setStopPosition() {
      var _this3 = this;
      this.startAnimatation(this.get('stopPositionOffset'), function () {
        Ember.run.next(function () {
          return _this3.get('onSelect')(_this3.date(_this3.get('valueOffset')).format('YYYY-MM'), {
            clicked: false
          });
        });
      });
    },
    stopAnimatation: function stopAnimatation() {
      this.set('targetPosition', null);
    },
    startAnimatation: function startAnimatation(offset) {
      var _this4 = this;
      var done = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : function () {};
      this.set('startPosition', this.get('position'));
      this.set('targetPosition', round((this.get('position') + offset) * 50) / 50);
      var speed = this.get('animationSpeed') * ceil(abs(offset / this.get('elementHeight')));
      var animationLastTime = performance.now();
      var _animate = function animate(time) {
        if (_this4.get('targetPosition') !== null) {
          var progress = abs((_this4.get('position') - _this4.get('startPosition')) / (_this4.get('targetPosition') - _this4.get('startPosition')));
          var easing = isNaN(progress) ? 1 : 0.5 + (cos(PI * progress) + 1) / 2;
          var distance = (time - animationLastTime) * (speed * easing);
          var targetPosition = round(_this4.get('targetPosition'));
          var position = isNaN(_this4.get('position')) ? 0 : round(_this4.get('position'));
          if (speed === 0 || targetPosition < position + distance && targetPosition > position - distance) {
            _this4.setProperties({
              targetPosition: null,
              position: targetPosition
            });
            return done();
          }
          var dir = targetPosition > position ? 1 : -1;
          _this4.set('position', position + dir * distance);
          animationLastTime = time;
          requestAnimationFrame(_animate);
        }
      };
      requestAnimationFrame(_animate);
    },
    actions: {
      onElementClick: function onElementClick(value) {
        var _this5 = this;
        var touchMoved = this.get('touchMoved');
        var monthDiff = (0, _moment.default)(value, 'YYYY-MM').diff((0, _moment.default)(this.get('value'), 'YYYY-MM'), 'months');

        /*
         * Since 'touchMove' is basically animating/scrolling to the right position,
         * we just want to set the value and fire 'onSelect' in order to update
         * parent component value state.
         *
         * The reason we are triggerring this action on 'touchEnd' is because
         * mobile devices do not trigger div onclick. And the reason we are
         * using 'selectedMonth' as next value is because 'value' is basically
         * data from where touch started (where user tapped on initially).
         */
        if (touchMoved) {
          this.set('touchMoved', false);
          var selectedMonth = this.date(this.get('valueOffset')).format('YYYY-MM');
          return Ember.run.next(function () {
            return _this5.get('onSelect')(selectedMonth, {
              clicked: true,
              touchMoved: true
            });
          });
        }
        if (monthDiff === 0) {
          return Ember.run.next(function () {
            return _this5.get('onSelect')(value, {
              clicked: true
            });
          });
        }
        if (abs(monthDiff) > 3) {
          return Ember.run.next(function () {
            return _this5.get('onSelect')(value, {
              clicked: true
            });
          });
        }
        this.startAnimatation(this.get('position') - monthDiff * this.get('elementHeight'), function () {
          Ember.run.next(function () {
            return _this5.get('onSelect')(_this5.date(_this5.get('valueOffset')).format('YYYY-MM'), {
              clicked: true
            });
          });
        });
      }
    }
  });
});