define("ln-ember-data-models/serializers/vdc/order", ["exports", "ln-ember-data-models/serializers/application"], function (_exports, _application) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  /**
   * @class Vdc/OrderSerializer
   * @extends ApplicationSerializer
   */
  var _default = _exports.default = _application.default.extend({
    get attrs() {
      return {
        vdc_store: {
          key: 'store',
          deserialize: 'records'
        }
      };
    }
  });
});