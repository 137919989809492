define("ln-ember-form-elements/components/form-elements/styled-button", ["exports", "ln-ember-form-elements/templates/components/form-elements/styled-button", "jquery"], function (_exports, _styledButton, _jquery) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  /**
   *
   * Renders an styled button.<br>
   *
   * ### Examples:
   *
   * ```Handlebars
   * {{form-elements/styled-button icon="delete"}}
   * ```
   *
   * ```Handlebars
   * {{#form-elements/styled-button click=(action "refresh"") theme="dark"}}
   *    {{loc "Refresh"}}
   *  {{/form-elements/styled-button}}
   * ```
   *
   * @class formElements/StyledButtonComponent
   * @extends @ember/component
   */
  var _default = _exports.default = Ember.Component.extend({
    formElements: Ember.inject.service(),
    layout: _styledButton.default,
    tagName: 'button',
    classNames: [
    // Deprecated: { id: ln-ember-form-elements-class-names, until: 3.0.0 }
    'button',
    // Deprecated: { id: ln-ember-form-elements-class-names, until: 3.0.0 }
    'styled-button-component', 'form-elements--styled-button-component'],
    classNameBindings: ['buttonType', 'disabled', 'loading', 'icon', 'saved', 'error:has-error', 'theme', 'themeClass'],
    attributeBindings: ['tabindex', 'disabled'],
    tabindex: undefined,
    /**
     * Disabled the button if set to true.
     *
     * @memberof formElements/StyledButtonComponent
     * @instance
     *
     * @type boolean
     * @default false
     */
    disabled: false,
    /**
     * Puts the button into the loading state if set to true
     *
     * @memberof formElements/StyledButtonComponent
     * @instance
     *
     * @type boolean
     * @default false
     */
    loading: false,
    /**
     * Puts the button into the error state if set to true
     *
     * @memberof formElements/StyledButtonComponent
     * @instance
     *
     * @type boolean
     * @default false
     */
    error: false,
    /**
     * Defines the icon of the button.<br>
     * Prepackaged icons are:
     * * `edit`
     * * `delete`
     * * `remove`
     * * `close`
     * * `settings`
     * * `settings-highlighted`
     *
     * For custom icons just pass in the name of the icon.
     * The button will have the class `icon` and the passed in name of the icon.
     *
     * @memberof formElements/StyledButtonComponent
     * @instance
     *
     * @type string
     * @default null
     */
    icon: null,
    /**
     * Defines the theme of the button.<br>
     * Predefined themes are:
     * * `light`
     * * `light-highlight`
     * * `dark`
     * * `dark-highlight`
     *
     * @memberof formElements/StyledButtonComponent
     * @instance
     *
     * @type string
     * @default light
     */
    theme: null,
    themeClass: Ember.computed('theme', function () {
      return "theme-".concat(this.get('theme') || 'light');
    }),
    buttonType: Ember.computed('icon', function () {
      return !this.get('icon') ? 'default' : 'icon';
    }),
    resetError: Ember.observer('error', function () {
      var _this = this;
      if (!this.get('error') || !(0, _jquery.default)(this.element)) {
        return;
      }
      (0, _jquery.default)(this.element).one('animationend', function () {
        if (_this.attrs && typeof _this.attrs.errorAnimationEnd === 'function') {
          _this.attrs.errorAnimationEnd();
        }
      });
    }),
    init: function init() {
      var _this2 = this;
      this._super.apply(this, arguments);
      this.handleMouseEnter = function () {
        for (var _len = arguments.length, args = new Array(_len), _key = 0; _key < _len; _key++) {
          args[_key] = arguments[_key];
        }
        _this2._compatibleSendAction.apply(_this2, ['mouse-enter'].concat(args));
      };
      this.handleMouseLeave = function () {
        for (var _len2 = arguments.length, args = new Array(_len2), _key2 = 0; _key2 < _len2; _key2++) {
          args[_key2] = arguments[_key2];
        }
        _this2._compatibleSendAction.apply(_this2, ['mouse-leave'].concat(args));
      };
      if (!this.get('theme')) {
        this.set('theme', this.get('formElements.defaults.theme'));
      }
    },
    didInsertElement: function didInsertElement() {
      this._super.apply(this, arguments);
      this.element.addEventListener('mouseenter', this.handleMouseEnter);
      this.element.addEventListener('mouseleave', this.handleMouseLeave);
    },
    didUpdateAttrs: function didUpdateAttrs() {
      var _this3 = this;
      var _ref = this.attrs.saved || {},
        saved = _ref.value;
      if (saved) {
        Ember.run.later(function () {
          if (_this3.get('isDestroyed')) {
            return;
          }
          _this3.set('saved', false);
        }, 1000);
      }
    },
    willDestroyElement: function willDestroyElement() {
      this._super.apply(this, arguments);
      this.element.removeEventListener('mouseenter', this.handleMouseEnter);
      this.element.removeEventListener('mouseleave', this.handleMouseLeave);
    },
    actions: {
      confirm: function confirm() {
        if (!this.get('disabled') && !this.get('loading')) {
          this._compatibleSendAction();
        }
        return false;
      },
      cancel: function cancel() {
        this.set('dialogVisible', false);
        return false;
      }
    },
    click: function click() {
      if (!this.get('disabled') && !this.get('loading')) {
        if (this.attrs.confirm) {
          this.set('dialogVisible', true);
        } else {
          this._compatibleSendAction();
        }
      }
      return false;
    },
    _compatibleSendAction: function _compatibleSendAction() {
      var action = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : 'action';
      for (var _len3 = arguments.length, args = new Array(_len3 > 1 ? _len3 - 1 : 0), _key3 = 1; _key3 < _len3; _key3++) {
        args[_key3 - 1] = arguments[_key3];
      }
      if (typeof this.get(action) === 'string') {
        return this.sendAction.apply(this, [action].concat(args));
      } else if (typeof this.get(action) === 'function') {
        return this.get(action).apply(void 0, args);
      }
    }
  });
});