define("ln-ember-data-models/models/myliga/fragments/picture", ["exports", "ember-data", "ember-data-model-fragments/fragment"], function (_exports, _emberData, _fragment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var attr = _emberData.default.attr;

  /**
   * @class Myliga/fragments/PictureFragment
   * @extends Fragment
   */
  var _default = _exports.default = _fragment.default.extend({
    /**
     * @memberof Myliga/fragments/PictureFragment
     * @instance
     *
     * @type {string}
     */
    url: attr('nullable-string'),
    /**
     * @memberof Myliga/fragments/PictureFragment
     * @instance
     *
     * @type {string}
     */
    preview_url: attr('nullable-string')
  });
});