define("ln-ember-data-models/models/myliga/planned-time-entry", ["exports", "ln-ember-data-models/models/myliga/time-entry"], function (_exports, _timeEntry) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  /**
   * @class Myliga/PlannedTimeEntryModel
   * @extends Myliga/TimeEntryModel
   */
  var _default = _exports.default = _timeEntry.default.extend();
});