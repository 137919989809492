define("ln-ember-form-elements/components/flash-messages/flash-message", ["exports", "jquery", "ln-ember-toolkit/mixins/resize-events", "ln-ember-form-elements/templates/components/flash-messages/flash-message"], function (_exports, _jquery, _resizeEvents, _flashMessage) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  /**
   * @class flashMessages/FlashMessageComponent
   * @extends @ember/component
   *
   * @see flashMessages/FlashMessagesComponent
   */
  var _default = _exports.default = Ember.Component.extend(_resizeEvents.default, {
    layout: _flashMessage.default,
    attributeBindings: ['style'],
    classNames: [
    // Deprecated: { id: ln-ember-form-elements-class-names, until: 3.0.0 }
    'flash-message-component', 'flash-messages--flash-message-component'],
    classNameBindings: ['message.type', 'message.visible', 'resizing'],
    allMessages: null,
    message: null,
    onHeightChange: function onHeightChange() {},
    onRemove: function onRemove() {},
    onCloseClick: function onCloseClick() {},
    style: Ember.computed('allMessages.@each.{visible,height}', function () {
      var allMessages = this.get('allMessages');
      var message = this.get('message');
      if (!allMessages) {
        return null;
      }
      var offset = allMessages.reduce(function (height, current) {
        if (Ember.get(current, 'visible') !== true && Ember.get(current, 'id') > Ember.get(message, 'id') || Ember.get(current, 'id') < Ember.get(message, 'id')) {
          return height;
        }
        return height + Ember.get(current, 'height');
      }, 0);
      var z = message.visible ? 1 : 0;
      return Ember.String.htmlSafe("transform: translate3d(0, -".concat(offset, "px, ").concat(z, "px)"));
    }),
    onResize: Ember.on('resize', function () {
      var _this = this;
      this.set('resizing', true);
      this.updateHeight();
      Ember.run.later(function () {
        _this.set('resizing', false);
      }, 50);
    }),
    didReceiveAttrs: function didReceiveAttrs() {
      var _this2 = this;
      if (this.get('message.visible') === false) {
        (0, _jquery.default)(this.element).one('transitionend', function () {
          _this2.get('onRemove')();
        });
      }
    },
    didRender: function didRender() {
      this._super.apply(this, arguments);
      this.updateHeight();
    },
    actions: {
      removeMessage: function removeMessage(event) {
        this.get('onCloseClick')(event.altKey);
      }
    },
    updateHeight: function updateHeight() {
      var _this3 = this;
      Ember.run.scheduleOnce('afterRender', function () {
        if (!(0, _jquery.default)(_this3.element) || _this3.get('isDestroyed')) {
          return;
        }
        var height = (0, _jquery.default)(_this3.element).outerHeight(true);
        _this3.set('height', height);
        _this3.get('onHeightChange')(height);
      });
    }
  });
});