define("ln-ember-toolkit/utils/string-utils", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.toLabel = toLabel;
  /**
   * @module StringUtils
   */

  /**
   * @function
   * @param  {String} name
   * @return {String}
   */
  function toLabel(name) {
    // TODO That test is not enough, is it?
    var wasPlural = /_ids$/.test(name);
    name = (name || '').replace(/_ids?$/, '').replace(/[_-]/g, ' ').replace(/([^\s])([0-9]+)$/g, '$1 $2').replace(/([a-z\d])([A-Z])/g, '$1 $2');
    name = name.charAt(0).toUpperCase() + name.slice(1);
    if (wasPlural) {
      // TODO: That's from Ember-Data, right?
      name = name.pluralize();
    }
    return name;
  }
});