define("ln-ember-data-models/models/vdc/project-type", ["exports", "ember-data", "ember-data-model-fragments/attributes", "ln-ember-data-models/models/vdc/fragments/project-type-settings"], function (_exports, _emberData, _attributes, _projectTypeSettings) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var attr = _emberData.default.attr,
    hasMany = _emberData.default.hasMany,
    Model = _emberData.default.Model;

  /**
   * @class Vdc/ProjectTypeModel
   * @extends DS.Model
   */
  var _default = _exports.default = Model.extend({
    /**
     * @memberof Vdc/ProjectTypeModel
     * @instance
     *
     * @type {Number}
     */
    version: attr('number'),
    /**
     * @memberof Vdc/ProjectTypeModel
     * @instance
     *
     * @type {String}
     */
    name: attr('nullable-string'),
    /**
     * @memberof Vdc/ProjectTypeModel
     * @instance
     *
     * @type {Number}
     */
    client_id: attr('id'),
    /**
     * @memberof Vdc/ProjectTypeModel
     * @instance
     *
     * @type {Number}
     */
    attribute_set_id: attr('id'),
    /**
     * @memberof Vdc/ProjectTypeModel
     * @instance
     *
     * @type {Array}
     */
    attribute_ids: attr('number-array', {
      defaultValue: function defaultValue() {
        return [];
      }
    }),
    /**
     * @memberof Vdc/ProjectTypeModel
     * @instance
     *
     * @type {Vdc/PictureModel}
     */
    pictures: hasMany('vdc/picture', {
      async: false
    }),
    /**
     * @memberof Vdc/ProjectTypeModel
     * @instance
     *
     * @type {Vdc/fragments/ProjectTypeSettingsFragment}
     */
    settings: (0, _attributes.fragment)('vdc/fragments/project-type-settings', {
      defaultValue: function defaultValue() {
        return (0, _projectTypeSettings.getDefaults)();
      }
    })
  });
});