define('ln-ember-guide-tooltips/components/guide-tooltips', ['exports', 'ln-ember-guide-tooltips/templates/components/guide-tooltips'], function (exports, _guideTooltips) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var Component = Ember.Component,
      service = Ember.inject.service;
  exports.default = Component.extend({
    layout: _guideTooltips.default,

    classNames: ['guide-tooltips-component'],

    guideTooltips: service(),

    actions: {
      next: function next(config) {
        this.get('guideTooltips').next(config);
      }
    }

  });
});