define("ln-ember-data-models/models/myliga/clap", ["exports", "ember-data", "ember-data-model-fragments/attributes"], function (_exports, _emberData, _attributes) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var attr = _emberData.default.attr,
    Model = _emberData.default.Model;

  /**
   * @class Myliga/ClapModel
   * @extends DS.Model
   */
  var _default = _exports.default = Model.extend({
    /**
     * @memberof Myliga/ClapModel
     * @instance
     *
     * @type {Number}
     */
    version: attr('number'),
    /**
     * @memberof Myliga/ClapModel
     * @instance
     *
     * @type {Number}
     */
    user_id: attr('id'),
    /**
     * @memberof Myliga/ClapModel
     * @instance
     *
     * @type {String}
     */
    text: attr('nullable-string'),
    /**
     * @memberof Myliga/ClapModel
     * @instance
     *
     * @type {Boolean}
     */
    visible_for_superior: attr('boolean'),
    /**
     * @memberof Myliga/ClapModel
     * @instance
     *
     * @type {Boolean}
     */
    visible_for_user: attr('boolean'),
    /**
     * @memberof Myliga/ClapModel
     * @instance
     *
     * @type {String}
     */
    time_created: attr('date-time-string'),
    /**
     * @memberof Myliga/ClapModel
     * @instance
     *
     * @type {Number}
     */
    author_id: attr('id'),
    /**
     * @memberof Myliga/ClapModel
     * @instance
     *
     * @type {Myliga/fragments/UserMeta}
     */
    author_info: (0, _attributes.fragment)('myliga/fragments/user-meta'),
    /**
     * @memberof Myliga/ClapModel
     * @instance
     * @type {Myliga/fragments/UserMeta}
     */
    user_info: (0, _attributes.fragment)('myliga/fragments/user-meta')
  });
});