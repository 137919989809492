define('ln-ember-config-utils/utils/inject-theme', ['exports', 'ember'], function (exports, _ember) {
  exports['default'] = injectTheme;
  var ComputedProperty = _ember['default'].ComputedProperty;
  var getOwner = _ember['default'].getOwner;
  var EmberObject = _ember['default'].Object;

  function injectTheme() {

    return new ComputedProperty(function () {
      var config = getOwner(this).resolveRegistration('config:environment');
      return EmberObject.create(config.APP.theme);
    });
  }
});