define("ln-ember-data-models/models/vdc/cost-rule-position", ["exports", "ember-data", "ln-ember-data-models/utils/enum-option"], function (_exports, _emberData, _enumOption) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.typeOptions = _exports.default = void 0;
  var attr = _emberData.default.attr,
    Model = _emberData.default.Model;

  /**
   * @name Vdc/CostRuleConditionModel/typeOptions
   * @type {EnumOption[]}
   */
  var typeOptions = _exports.typeOptions = [_enumOption.default.create({
    id: 'AgencyService'
  }), _enumOption.default.create({
    id: 'Logistic'
  }), _enumOption.default.create({
    id: 'Production'
  })];

  /**
   * @class Vdc/CostRulePositionModel
   * @extends DS.Model
   */
  var CostRulePositionModel = Model.extend({
    /**
     * @memberof Vdc/CostRulePositionModel
     * @instance
     *
     * @type {Number}
     */
    version: attr('number'),
    /**
     * @memberof Vdc/CostRulePositionModel
     * @instance
     *
     * @type {String}
     */
    name: attr('nullable-string'),
    /**
     * @memberof Vdc/CostRulePositionModel
     * @instance
     *
     * @type {Number}
     */
    sales_price: attr('number'),
    /**
     * @memberof Vdc/CostRulePositionModel
     * @instance
     *
     * @type {Number}
     */
    purchase_price: attr('number'),
    /**
     * @memberof Vdc/CostRulePositionModel
     * @instance
     *
     * @type {Number}
     */
    cost_rule_id: attr('id'),
    /**
     * @memberof Vdc/CostRulePositionModel
     * @instance
     *
     * @type {String}
     */
    type: attr('enum-string', {
      options: typeOptions
    })
  });
  CostRulePositionModel.reopenClass({
    /**
     * @memberof Vdc/CostRuleConditionModel
     * @static
     *
     * @type {EnumOption[]}
     */
    typeOptions: typeOptions
  });
  var _default = _exports.default = CostRulePositionModel;
});