define("ln-ember-data-models/models/vdc/text-module-translation", ["exports", "ember-data", "ln-ember-data-models/utils/enum-option"], function (_exports, _emberData, _enumOption) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.statusOptions = _exports.default = void 0;
  var attr = _emberData.default.attr,
    hasMany = _emberData.default.hasMany,
    Model = _emberData.default.Model;

  /**
   * @name Vdc/TextModuleTranslationModel/statusOptions
   * @type {EnumOption[]}
   */
  var statusOptions = _exports.statusOptions = [_enumOption.default.create({
    id: 'New'
  }), _enumOption.default.create({
    id: 'Approved'
  }), _enumOption.default.create({
    id: 'InReview'
  })];

  /**
   * @class Vdc/TextModuleTranslationModel
   * @extends DS.Model
   */
  var TextModuleTranslationModel = Model.extend({
    /**
     * @memberof Vdc/TextModuleTranslationModel
     * @instance
     *
     * @type {Number}
     */
    version: attr('number'),
    /**
     * @memberof Vdc/TextModuleTranslationModel
     * @instance
     *
     * @type {String}
     */
    status: attr('enum-string', {
      options: statusOptions
    }),
    /**
     * @memberof Vdc/TextModuleTranslationModel
     * @instance
     *
     * @type {Number}
     */
    language_id: attr('id'),
    /**
     * @memberof Vdc/TextModuleTranslationModel
     * @instance
     *
     * @type {Number}
     */
    text_module_id: attr('id'),
    /**
     * @memberof Vdc/TextModuleTranslationModel
     * @instance
     *
     * @type {Number}
     */
    country_id: attr('id'),
    /**
     * @memberof Vdc/TextModuleTranslationModel
     * @instance
     *
     * @type {String}
     */
    text: attr('nullable-string'),
    /**
     * @memberof Vdc/TextModuleTranslationModel
     * @instance
     *
     * @type {String}
     */
    text_module_name: attr('nullable-string', {
      readOnly: true
    }),
    /**
     * @memberof Vdc/TextModuleTranslationModel
     * @instance
     *
     * @type {String}
     */
    text_module_text: attr('nullable-string', {
      readOnly: true
    }),
    /**
     * @memberof Vdc/TextModuleTranslationModel
     * @instance
     *
     * @type {Vdc/CommentModel[]}
     */
    comments: hasMany('vdc/comment', {
      readOnly: true
    })
  });
  TextModuleTranslationModel.reopenClass({
    /**
     * @memberof Vdc/TextModuleTranslationModel
     * @static
     *
     * @type {EnumOption[]}
     */
    statusOptions: statusOptions
  });
  var _default = _exports.default = TextModuleTranslationModel;
});