define("ln-ember-data-models/models/vdc/fragments/estimated-dimensions", ["exports", "ember-data", "ember-data-model-fragments/fragment"], function (_exports, _emberData, _fragment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var attr = _emberData.default.attr;

  /**
   * @class Vdc/fragments/EstimatedDimensionsFragment
   * @extends Fragment
   */
  var EstimatedDimensionsFragment = _fragment.default.extend({
    /**
     * @memberof Vdc/fragments/EstimatedDimensionsFragment
     * @instance
     *
     * @type {Number}
     */
    height: attr('number'),
    /**
     * @memberof Vdc/fragments/EstimatedDimensionsFragment
     * @instance
     *
     * @type {Number}
     */
    width: attr('number'),
    /**
     * @memberof Vdc/fragments/EstimatedDimensionsFragment
     * @instance
     *
     * @type {Number}
     */
    depth: attr('number'),
    /**
     * @memberof Vdc/fragments/EstimatedDimensionsFragment
     * @instance
     *
     * @type {Number}
     */
    weight: attr('number')
  });
  var _default = _exports.default = EstimatedDimensionsFragment;
});