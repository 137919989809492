define("ln-ember-form-elements/templates/components/form-elements/image-upload", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.HTMLBars.template({
    "id": "7Zc1WSjt",
    "block": "{\"symbols\":[\"file\"],\"statements\":[[4,\"if\",[[23,0,[\"label\"]]],null,{\"statements\":[[0,\"  \"],[7,\"div\",true],[10,\"class\",\"label\"],[8],[1,[23,0,[\"label\"]],false],[9],[0,\"\\n\"]],\"parameters\":[]},null],[0,\"\\n\"],[7,\"div\",true],[10,\"class\",\"scroll-content\"],[8],[0,\"\\n  \"],[7,\"input\",true],[10,\"class\",\"file-input\"],[10,\"title\",\"\"],[10,\"accept\",\"image/*\"],[10,\"multiple\",\"\"],[10,\"type\",\"file\"],[8],[9],[0,\"\\n\\n\"],[4,\"if\",[[23,0,[\"fileObjects\",\"length\"]]],null,{\"statements\":[[4,\"each\",[[23,0,[\"fileObjects\"]]],[[\"key\"],[\"id\"]],{\"statements\":[[0,\"      \"],[7,\"div\",true],[11,\"data-id\",[23,1,[\"id\"]]],[11,\"class\",[29,[\"preview-image \",[28,\"if\",[[23,1,[\"isRemoved\"]],\"is-removed\"],null],\" \",[28,\"if\",[[23,1,[\"isFile\"]],\"is-file\"],null]]]],[11,\"style\",[23,1,[\"style\"]]],[8],[0,\"\\n\"],[0,\"        \"],[1,[28,\"form-elements/styled-button\",null,[[\"action\",\"icon\",\"theme\"],[[28,\"action\",[[23,0,[]],\"removeFile\",[23,1,[]]],null],\"close\",\"dark\"]]],false],[0,\"\\n      \"],[9],[0,\"\\n\"]],\"parameters\":[1]},null],[0,\"\\n    \"],[7,\"div\",true],[10,\"class\",\"dummy\"],[11,\"onclick\",[28,\"action\",[[23,0,[]],\"clickDummy\"],null]],[10,\"role\",\"button\"],[8],[9],[0,\"\\n\"]],\"parameters\":[]},null],[9],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "ln-ember-form-elements/templates/components/form-elements/image-upload.hbs"
    }
  });
});