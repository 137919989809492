define("ln-ember-data-models/models/vdc/order", ["exports", "ember-data", "ember-data-model-fragments/attributes", "ln-ember-data-models/utils/enum-option"], function (_exports, _emberData, _attributes, _enumOption) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.statusOptions = _exports.orderCancelOptions = _exports.invoiceTypes = _exports.fileOptions = _exports.default = void 0;
  var attr = _emberData.default.attr,
    belongsTo = _emberData.default.belongsTo,
    hasMany = _emberData.default.hasMany,
    Model = _emberData.default.Model;

  /**
   * @name Vdc/OrderModel/statusOptions
   * @type {EnumOption[]}
   */
  var statusOptions = _exports.statusOptions = [_enumOption.default.create({
    id: 'Compose'
  }), _enumOption.default.create({
    id: 'New'
  }), _enumOption.default.create({
    id: 'Production'
  }), _enumOption.default.create({
    id: 'Delivered'
  }), _enumOption.default.create({
    id: 'Canceled'
  }), _enumOption.default.create({
    id: 'Approved'
  }), _enumOption.default.create({
    id: 'ReadyForCostCalculation'
  }), _enumOption.default.create({
    id: 'ReadyForPONumber',
    name: 'Ready for PO number'
  })];

  /**
   * @name Vdc/OrderModel/invoiceTypes
   * @type {EnumOption[]}
   */
  var invoiceTypes = _exports.invoiceTypes = [_enumOption.default.create({
    id: 'Commercial'
  }), _enumOption.default.create({
    id: 'ProForma'
  })];

  /**
   * @name Vdc/OrderModel/fileOptions
   * @type {EnumOption[]}
   */
  var fileOptions = _exports.fileOptions = [_enumOption.default.create({
    id: 'WithoutAttachments',
    name: 'Normal'
  }), _enumOption.default.create({
    id: 'WithAttachments',
    name: 'Open Files'
  }), _enumOption.default.create({
    id: 'Mixed'
  })];

  /**
   * @memberof Vdc/OrderModel/orderCancelOptions
   * @static
   *
   * @type {EnumOption[]}
   */
  var orderCancelOptions = _exports.orderCancelOptions = [_enumOption.default.create({
    id: 'Assembeled'
  }), _enumOption.default.create({
    id: 'Canceled'
  })];

  /**
   * @class Vdc/OrderModel
   * @extends DS.Model
   */
  var OrderModel = Model.extend({
    /**
     * @memberof Vdc/OrderModel
     * @instance
     *
     * @type {Number}
     */
    version: attr('number'),
    /**
     * @memberof Vdc/OrderModel
     * @instance
     *
     * @type {Number}
     */
    delivery_type_id: attr('id'),
    /**
     * @memberof Vdc/OrderModel
     * @instance
     *
     * @type {Number}
     */
    number: attr('number'),
    /**
     * @memberof Vdc/OrderModel
     * @instance
     *
     * @type {Number}
     */
    project_id: attr('id'),
    /**
     * @memberof Vdc/OrderModel
     * @instance
     *
     * @type {String}
     */
    approved_delivery_date: attr('date-time-string'),
    /**
     * @memberof Vdc/OrderModel
     * @instance
     *
     * @type {Array}
     */
    assembly_ids: attr('number-array', {
      defaultValue: function defaultValue() {
        return [];
      }
    }),
    /**
     * @memberof Vdc/OrderModel
     * @instance
     *
     * @type {Number}
     */
    purchase_price: attr('number'),
    /**
     * @memberof Vdc/OrderModel
     * @instance
     *
     * @type {String}
     */
    order_date: attr('date-time-string'),
    /**
     * @memberof Vdc/OrderModel
     * @instance
     *
     * @type {String}
     */
    desired_delivery_date: attr('date-time-string'),
    /**
     * @memberof Vdc/OrderModel
     * @instance
     *
     * @type {Number}
     */
    invoice_address_id: attr('id'),
    /**
     * @memberof Vdc/OrderModel
     * @instance
     *
     * @type {String}
     */
    po_number: attr('nullable-string'),
    /**
     * @memberof Vdc/OrderModel
     * @instance
     *
     * @type {Number}
     */
    store_id: attr('id'),
    /**
     * @memberof Vdc/OrderModel
     * @instance
     *
     * @type {String}
     */
    order_cancel_assembly_status: attr('nullable-string'),
    /**
     * @memberof Vdc/OrderModel
     * @instance
     *
     * @type {Boolean}
     */
    eligible_for_compose_to_ready_for_po_number: attr('boolean', {
      defaultValue: function defaultValue() {
        return false;
      }
    }),
    /**
     * @memberof Vdc/OrderModel
     * @instance
     *
     * @type {Number}
     */
    article_attachment_full_file_duration_access: attr('number'),
    /**
     * @memberof Vdc/OrderModel
     * @instance
     *
     * @type {String}
     */
    order_ready_for_po_number_change_date: attr('date-string'),
    /**
     * @memberof Vdc/OrderModel
     * @instance
     *
     * @type {Number}
     */
    currency_id: attr('id'),
    /**
     * @memberof Vdc/OrderModel
     * @instance
     *
     * @type {Number}
     */
    sales_price: attr('number'),
    /**
     * @memberof Vdc/OrderModel
     * @instance
     *
     * @type {String}
     */
    delivery_specification: attr('nullable-string'),
    /**
     * @memberof Vdc/OrderModel
     * @instance
     *
     * @type {Number}
     */
    delivery_address_id: attr('id'),
    /**
     * @memberof Vdc/OrderModel
     * @instance
     *
     * @type {Number}
     */
    user_id: attr('id'),
    /**
     * @memberof Vdc/OrderModel
     * @instance
     *
     * @type {String}
     */
    status: attr('nullable-string'),
    cost_positions: attr(),
    /**
     * @memberof Vdc/OrderModel
     * @instance
     *
     * @type {Object}
     */
    delivery_type: belongsTo('vdc/delivery-type'),
    /**
     * @memberof Vdc/OrderModel
     * @instance
     *
     * @type {Vdc/ProjectModel}
     */
    project: belongsTo('vdc/project'),
    /**
     * @memberof Vdc/OrderModel
     * @instance
     *
     * @type {Vdc/AddressModel}
     */
    invoice_address: belongsTo('vdc/address'),
    /**
     * @memberof Vdc/OrderModel
     * @instance
     *
     * @type {Vdc/CountryModel}
     */
    store_country: belongsTo('vdc/country'),
    /**
     * @memberof Vdc/OrderModel
     * @instance
     *
     * @type {Vdc/AddressModel}
     */
    delivery_address: belongsTo('vdc/address'),
    /**
     * Since `store` is a reserved property in Ember Data, the attribute store is remapped in the serializer
     *
     * @memberof Vdc/OrderModel
     * @instance
     *
     * @type {Vdc/StoreModel}
     */
    vdc_store: belongsTo('vdc/store'),
    /**
     * @memberof Vdc/OrderModel
     * @instance
     *
     * @type {Vdc/UserModel}
     */
    user: belongsTo('vdc/user'),
    /**
     * @memberof Vdc/OrderModel
     * @instance
     *
     * @type {Vdc/OrderPosition[]}
     */
    order_positions: hasMany('vdc/order_position', {
      async: true
    }),
    /**
     * @memberof Vdc/OrderModel
     * @instance
     *
     * @type {Vdc/fragments/AdditionalCostFragment[]}
     */
    additional_costs: (0, _attributes.fragmentArray)('vdc/fragments/additional-cost'),
    /**
     * @memberof Vdc/OrderModel
     * @instance
     *
     * @type {Vdc/fragments/ExportDocumentFragment}
     */
    export_documents: (0, _attributes.fragment)('vdc/fragments/export-document')
  });
  OrderModel.reopenClass({
    /**
     * @memberof Vdc/OrderModel
     * @static
     *
     * @type {EnumOption[]}
     */
    statusOptions: statusOptions,
    /**
     * @memberof Vdc/OrderModel
     * @static
     *
     * @type {EnumOption[]}
     */
    fileOptions: fileOptions,
    /**
     * @memberof Vdc/OrderModel
     * @static
     *
     * @type {EnumOption[]}
     */
    orderCancelOptions: orderCancelOptions,
    /**
     * @memberof Vdc/OrderModel
     * @static
     *
     * @type {EnumOption[]}
     */
    invoiceTypes: invoiceTypes
  });
  var _default = _exports.default = OrderModel;
});