define("ln-ember-data-models/models/vdc/picture", ["exports", "ember-data", "ember-data-model-fragments/attributes"], function (_exports, _emberData, _attributes) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var Model = _emberData.default.Model,
    attr = _emberData.default.attr;

  /**
   * @class Vdc/PictureModel
   * @extends DS.Model
   */
  var _default = _exports.default = Model.extend({
    /**
     * @memberof Vdc/PictureModel
     * @instance
     *
     * @type {Number}
     */
    version: attr('number'),
    /**
     * @memberof Vdc/PictureModel
     * @instance
     *
     * @type {Number}
     */
    position: attr('number'),
    /**
     * @memberof Vdc/PictureModel
     * @instance
     *
     * @type {String}
     */
    filename: attr('nullable-string'),
    /**
     * @memberof Vdc/PictureModel
     * @instance
     *
     * @type {String}
     */
    url: attr('nullable-string'),
    /**
     * @memberof Vdc/PictureModel
     * @instance
     *
     * @type {Boolean}
     */
    preview_ready: attr('boolean', {
      defaultValue: function defaultValue() {
        return false;
      }
    }),
    /**
     * @memberof Vdc/PictureModel
     * @instance
     *
     * @type {String}
     */
    preview_url: attr('nullable-string'),
    /**
     * @memberof Vdc/PictureModel
     * @instance
     *
     * @type {String[]}
     */
    preview_urls: attr({
      defaultValue: function defaultValue() {
        return [];
      }
    }),
    /**
     * @memberof Vdc/PictureModel
     * @instance
     *
     * @type {String}
     */
    mask: attr('nullable-string'),
    /**
     * @memberof Vdc/PictureModel
     * @instance
     *
     * @type {Number}
     */
    filesize: attr('number'),
    /**
     * @memberof Vdc/PictureModel
     * @instance
     *
     * @type {Boolean}
     */
    gallery: attr('boolean', {
      defaultValue: false
    }),
    /**
     * @memberof Vdc/PictureModel
     * @instance
     *
     * @type {Vdc/fragments/ImageDimensionFragment}
     */
    image_dimensions: (0, _attributes.fragment)('vdc/fragments/image-dimension')
  });
});