define("ln-ember-form-elements/utils/tabbable-element", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.nextTabbableElementFor = nextTabbableElementFor;
  _exports.previousTabbableElementFor = previousTabbableElementFor;
  function tabIndex(element) {
    return Number(element.getAttribute('tabindex')) || 0;
  }
  function uniqueTabIndexes(elements) {
    var _ref = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : {},
      _ref$reversed = _ref.reversed,
      reversed = _ref$reversed === void 0 ? false : _ref$reversed;
    return elements.reduce(function (idxs, element) {
      var idx = tabIndex(element);
      if (!idxs.includes(idx)) {
        idxs.push(idx);
      }
      idxs.sort();
      if (reversed) {
        idxs.reverse();
      }
      return idxs;
    }, []);
  }
  function findFirstSame(elements, index) {
    return elements.find(function (element) {
      return tabIndex(element) === index;
    });
  }
  var querySelectables = function querySelectables(element) {
    var selectors = ['[tabindex]:not([tabindex="-1"])', 'input:not([tabindex="-1"])', 'select:not([tabindex="-1"])', 'textarea:not([tabindex="-1"])', 'button:not([tabindex="-1"])', 'object:not([tabindex="-1"])', 'a[href]:not([tabindex="-1"])', 'area[href]:not([tabindex="-1"])'];
    var originalId = element.id;
    if (element && element.tagName.toLowerCase() !== 'body') {
      if (element.id === '') {
        element.id = "random-id-".concat(new Date().getTime(), "-").concat(Math.floor(Math.random() * 1000));
      }
      selectors.push("#".concat(element.id));
    }
    var selectables = Array.prototype.slice.call(document.querySelectorAll(selectors.join(',')));
    if (element.id !== originalId) {
      element.id = originalId;
    }
    return selectables;
  };
  function findTabbableElemementFor(element, dir) {
    if (!element) {
      return document.querySelector('body');
    }
    dir = dir || 1;
    var elementTabIndex = tabIndex(element);
    var selectables = querySelectables(element);
    if (dir === -1) {
      selectables.reverse();
    }
    var selectablesNext = selectables.slice(selectables.indexOf(element) + 1);
    var nextSame = findFirstSame(selectablesNext, elementTabIndex);
    if (nextSame) {
      return nextSame;
    }
    var nextTabIndex = uniqueTabIndexes(selectables, {
      reversed: dir === -1
    }).find(function (idx) {
      return dir === 1 ? idx > elementTabIndex : idx < elementTabIndex;
    });
    var nextHigh = findFirstSame(selectables, nextTabIndex);
    if (nextHigh) {
      return nextHigh;
    }
    return document.querySelector('body');
  }
  function nextTabbableElementFor(element) {
    return findTabbableElemementFor(element, 1);
  }
  function previousTabbableElementFor(element) {
    return findTabbableElemementFor(element, -1);
  }
});