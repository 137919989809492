define("ln-ember-contact-menu/components/micro-menu", ["exports", "ln-ember-contact-menu/templates/components/micro-menu"], function (_exports, _microMenu) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var MM_MOBILE_SPACE_ALLOWED = 250;
  var MM_DESKTOP_SPACE_ALLOWED = 310;
  var _default = _exports.default = Ember.Component.extend({
    microMenu: Ember.inject.service(),
    mediaMatcher: Ember.inject.service(),
    router: Ember.inject.service(),
    layout: _microMenu.default,
    classNames: ['micro-menu-component'],
    classNameBindings: ['microMenu.hasActiveItem:has-active'],
    component: Ember.computed.alias('microMenu.component'),
    isRendered: false,
    targetElement: Ember.computed('microMenu.elementId', function () {
      return document.getElementById(this.get('microMenu.elementId'));
    }),
    isNorthern: Ember.computed('targetElement', function () {
      var targetElement = this.get('targetElement');
      var isMobile = this.get('mediaMatcher.isMobile');
      if (isMobile) {
        return hasEnoughSpaceBelow(targetElement, MM_MOBILE_SPACE_ALLOWED);
      } else {
        return hasEnoughSpaceBelow(targetElement, MM_DESKTOP_SPACE_ALLOWED);
      }
    }),
    init: function init() {
      this._super.apply(this, arguments);
      this.set('popoverId', "".concat(Ember.guidFor(this), "-popover"));
    },
    didInsertElement: function didInsertElement() {
      var _this = this;
      this.router.on('routeWillChange', function () {
        if (_this.microMenu.isActive) {
          _this._close();
        }
      });
    },
    didRender: function didRender() {
      var _this2 = this;
      if (this.get('microMenu.isActive')) {
        Ember.run.next(function () {
          if (_this2.isDestroying) {
            return;
          }
          _this2.set('isRendered', true);
        });
      }
    },
    _close: Ember._action(function () {
      this.get('microMenu').close();
    }),
    _onChangePopoverVisibility: Ember._action(function (state) {
      // cleanup the micro-menu state if the popover is closed from the outside
      // (e.g. by pressing the escape key)
      if (!state) {
        this._close();
      }
    }),
    _didActivateItem: Ember._action(function () {
      this.get('microMenu').set('hasActiveItem', true);
    })
  });
  /**
   * Checks if there is enough space below the element (at least 310px on desktop and 250px on mobile)
   *
   * @param {HTMLElement} element
   * @param {number} spaceAllowedBelow
   * @return {boolean}
   */
  function hasEnoughSpaceBelow(element, spaceAllowedBelow) {
    var elementRect = element.getBoundingClientRect();
    var spaceBelowElement = window.innerHeight - elementRect.bottom;
    return spaceBelowElement > spaceAllowedBelow;
  }
});