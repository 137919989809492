define("ln-ember-form-elements/templates/components/form-elements/styled-select", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.HTMLBars.template({
    "id": "jb3/DlEe",
    "block": "{\"symbols\":[\"@tabindex\"],\"statements\":[[0,\"\\n\"],[4,\"if\",[[23,0,[\"label\"]]],null,{\"statements\":[[7,\"label\",true],[11,\"for\",[28,\"concat\",[[23,0,[\"elementId\"]],\"-select\"],null]],[8],[1,[23,0,[\"label\"]],false],[9]],\"parameters\":[]},null],[0,\"\\n\\n\"],[4,\"if\",[[23,0,[\"multiple\"]]],null,{\"statements\":[[0,\"  \"],[7,\"span\",true],[11,\"class\",[29,[\"multiple-label \",[28,\"if\",[[23,0,[\"hasMultipleSelected\"]],\"selection\",\"placeholder\"],null],\" \",[28,\"if\",[[23,0,[\"showCount\"]],\"padded\",\"\"],null]]]],[8],[1,[23,0,[\"labelMultipleSelected\"]],false],[9],[0,\"\\n\"]],\"parameters\":[]},null],[0,\"\\n\"],[7,\"select\",true],[11,\"id\",[28,\"concat\",[[23,0,[\"elementId\"]],\"-select\"],null]],[11,\"tabindex\",[23,1,[]]],[11,\"multiple\",[23,0,[\"multiple\"]]],[10,\"style\",\"width:100%\"],[8],[9],[0,\"\\n\"],[0,\"\\n\"],[4,\"if\",[[23,0,[\"showCount\"]]],null,{\"statements\":[[0,\"  \"],[7,\"div\",true],[10,\"class\",\"selected-count\"],[11,\"onclick\",[28,\"action\",[[23,0,[]],\"removeAll\"],null]],[10,\"role\",\"button\"],[8],[0,\"\\n    \"],[1,[23,0,[\"selectedCount\"]],false],[0,\"\\n  \"],[9],[0,\"\\n\"]],\"parameters\":[]},null],[0,\"\\n\"],[4,\"if\",[[23,0,[\"removeIsAllowed\"]]],null,{\"statements\":[[0,\"  \"],[1,[28,\"form-elements/styled-button\",null,[[\"action\",\"icon\",\"mouse-enter\",\"mouse-leave\",\"tabindex\",\"theme\"],[[28,\"action\",[[23,0,[]],\"removeAll\"],null],\"remove\",[28,\"action\",[[23,0,[]],\"removeMouseEnter\"],null],[28,\"action\",[[23,0,[]],\"removeMouseLeave\"],null],\"-1\",[23,0,[\"theme\"]]]]],false],[0,\"\\n\"]],\"parameters\":[]},null]],\"hasEval\":false}",
    "meta": {
      "moduleName": "ln-ember-form-elements/templates/components/form-elements/styled-select.hbs"
    }
  });
});