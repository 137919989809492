define("ln-ember-data-models/models/vdc/order-position", ["exports", "ember-data"], function (_exports, _emberData) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var attr = _emberData.default.attr,
    belongsTo = _emberData.default.belongsTo,
    Model = _emberData.default.Model;

  /**
   * @class Vdc/OrderPositionModel
   * @extends DS.Model
   */
  var _default = _exports.default = Model.extend({
    /**
     * @memberof Vdc/OrderPositionModel
     * @instance
     *
     * @type {Number}
     */
    version: attr('number'),
    /**
     * @memberof Vdc/OrderPositionModel
     * @instance
     *
     * @type {Number}
     */
    amount: attr('number'),
    /**
     * @memberof Vdc/OrderPositionModel
     * @instance
     *
     * @type {Number}
     */
    amount_returnable: attr('number'),
    /**
     * @memberof Vdc/OrderPositionModel
     * @instance
     *
     * @type {Number}
     */
    amount_returned: attr('number'),
    /**
     * @memberof Vdc/OrderPositionModel
     * @instance
     *
     * @type {Number}
     */
    additional_fee: attr('number'),
    /**
     * @memberof Vdc/OrderPositionModel
     * @instance
     *
     * @type {Number}
     */
    product_amount: attr('number'),
    /**
     * @memberof Vdc/OrderPositionModel
     * @instance
     *
     * @type {Number}
     */
    assembly_position_id: attr('id'),
    /**
     * @memberof Vdc/OrderPositionModel
     * @instance
     *
     * @type {Number}
     */
    fixture_id: attr('id'),
    /**
     * @memberof Vdc/OrderPositionModel
     * @instance
     *
     * @type {Number}
     */
    instore_area_id: attr('id'),
    /**
     * @memberof Vdc/OrderPositionModel
     * @instance
     *
     * @type {Number}
     */
    order_id: attr('id'),
    /**
     * @memberof Vdc/OrderPositionModel
     * @instance
     *
     * @type {Number}
     */
    product_id: attr('id'),
    /**
     * @memberof Vdc/OrderPositionModel
     * @instance
     *
     * @type {Object}
     */
    product: attr(),
    /**
     * @memberof Vdc/OrderPositionModel
     * @instance
     *
     * @type {Number}
     */
    purchase_price: attr('number'),
    /**
     * @memberof Vdc/OrderPositionModel
     * @instance
     *
     * @type {Number}
     */
    sales_price: attr('number'),
    /**
     * @memberof Vdc/OrderPositionModel
     * @instance
     *
     * @type {Array}
     */
    sub_positions: attr(),
    /**
     * @memberof Vdc/OrderPositionModel
     * @instance
     *
     * @type {Number}
     */
    window_id: attr('id'),
    /**
     * @memberof Vdc/OrderPositionModel
     * @instance
     *
     * @type {Vdc/FixtureModel}
     */
    fixture: belongsTo('vdc/fixture'),
    /**
     * @memberof Vdc/OrderPositionModel
     * @instance
     *
     * @type {Vdc/InstoreAreaModel}
     */
    instore_area: belongsTo('vdc/instore-area'),
    /**
     * @memberof Vdc/OrderPositionModel
     * @instance
     *
     * @type {Vdc/WindowModel}
     */
    window: belongsTo('vdc/window'),
    /**
     * @memberof Vdc/OrderPositionModel
     * @instance
     *
     * @type {Vdc/PictureModel}
     */
    preview_picture: belongsTo('vdc/picture'),
    /**
     * @memberof Vdc/ArticleModel
     * @instance
     *
     * @type {Vdc/ArticleAttachmentModel}
     */
    attachment_full_file: belongsTo('vdc/article-attachment', {
      async: false
    }),
    /**
     * @memberof Vdc/ArticleModel
     * @instance
     *
     * @type {Vdc/ArticleAttachmentModel}
     */
    attachment_preview_file: belongsTo('vdc/article-attachment', {
      async: false
    })
  });
});