define("ln-ember-data-models/adapters/myliga/objective-component", ["exports", "ln-ember-data-models/adapters/abstract/myliga/sub-resource"], function (_exports, _subResource) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  /**
   * @class Myliga/ObjectiveComponentAdapter
   * @extends abstract/myliga/SubResourceAdapter
   */
  var _default = _exports.default = _subResource.default.extend({
    parentResource: 'objective',
    resourcePath: 'component'
  });
});