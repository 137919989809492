define("ln-ember-form-elements/templates/components/form-elements/styled-checkbox", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.HTMLBars.template({
    "id": "lTIzr5ES",
    "block": "{\"symbols\":[],\"statements\":[[1,[28,\"input\",null,[[\"type\",\"checked\",\"id\",\"disabled\"],[\"checkbox\",[23,0,[\"inputValue\"]],[23,0,[\"checkboxId\"]],[23,0,[\"readonlyAttribute\"]]]]],false],[0,\"\\n\"],[7,\"label\",true],[11,\"for\",[23,0,[\"checkboxId\"]]],[8],[1,[23,0,[\"label\"]],false],[9],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "ln-ember-form-elements/templates/components/form-elements/styled-checkbox.hbs"
    }
  });
});