define("ln-ember-api-service/services/api", ["exports", "jquery", "ln-ember-api-service/utils/url", "ln-ember-api-service/utils/normalize-error"], function (_exports, _jquery, _url, _normalizeError) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  function _toConsumableArray(r) { return _arrayWithoutHoles(r) || _iterableToArray(r) || _unsupportedIterableToArray(r) || _nonIterableSpread(); }
  function _nonIterableSpread() { throw new TypeError("Invalid attempt to spread non-iterable instance.\nIn order to be iterable, non-array objects must have a [Symbol.iterator]() method."); }
  function _unsupportedIterableToArray(r, a) { if (r) { if ("string" == typeof r) return _arrayLikeToArray(r, a); var t = {}.toString.call(r).slice(8, -1); return "Object" === t && r.constructor && (t = r.constructor.name), "Map" === t || "Set" === t ? Array.from(r) : "Arguments" === t || /^(?:Ui|I)nt(?:8|16|32)(?:Clamped)?Array$/.test(t) ? _arrayLikeToArray(r, a) : void 0; } }
  function _iterableToArray(r) { if ("undefined" != typeof Symbol && null != r[Symbol.iterator] || null != r["@@iterator"]) return Array.from(r); }
  function _arrayWithoutHoles(r) { if (Array.isArray(r)) return _arrayLikeToArray(r); }
  function _arrayLikeToArray(r, a) { (null == a || a > r.length) && (a = r.length); for (var e = 0, n = Array(a); e < a; e++) n[e] = r[e]; return n; }
  function _typeof(o) { "@babel/helpers - typeof"; return _typeof = "function" == typeof Symbol && "symbol" == typeof Symbol.iterator ? function (o) { return typeof o; } : function (o) { return o && "function" == typeof Symbol && o.constructor === Symbol && o !== Symbol.prototype ? "symbol" : typeof o; }, _typeof(o); }
  /**
   * @class ApiService
   * @extends Ember.Service
   */
  var _default = _exports.default = Ember.Service.extend({
    _token: null,
    apiEvents: Ember.inject.service(),
    config: null,
    init: function init() {
      var _this = this;
      this._super.apply(this, arguments);
      var apiEvents = this.get('apiEvents');
      this.set('config', apiEvents.getConfig());
      apiEvents.on('config-change', function (config) {
        _this.set('config', config);
      });
      if (false === 'loadToken' in this) {
        // the whole api-events pattern is bad.
        // The recommended approach is to implement a "loadToken" method in the ApiService extension
        // so it does not depend on the `trigger-session` event
        (false && !(false) && Ember.deprecate('"loadToken" is not defined', false, {
          id: 'ln-ember-api-service.missing-loadToken',
          until: '5.0.0',
          url: 'https://ligadigital.atlassian.net/browse/LGOS-764'
        }));
        this.set('session', apiEvents.getSession());
        apiEvents.on('session-change', function (session) {
          _this.set('session', session);
        });
      }
      this.set('language', apiEvents.getLanguage());
      apiEvents.on('language-change', function (language) {
        _this.set('language', language);
      });
    },
    /**
     * ```javascript
     * this.get('api').read('vdc', 'stores')
     *   .then(function() { console.log('\o/'); })
     *   .catch(function() { console.log('/o\'); });
     * ```
     * ```javascript
     * this.get('api').read('myliga', ['pins', id, 'likes'])
     *   .then(function() { console.log('\o/'); })
     *   .catch(function() { console.log('/o\'); });
     * ```
     *
     * @memberof ApiService
     * @instance
     *
     * @method read
     * @param  {String} namespace
     * @param  {String|String[]} uri
     * @return {Ember.RSVP.Promise}
     */
    read: function read(namespace, uri) {
      return this.request({
        url: this.buildUrl(namespace, uri),
        method: 'GET'
      });
    },
    /**
     * ```javascript
     * this.get('api').create('vdc', ['/stores', {name: 'test'}])
     *   .then(function() { console.log('\o/'); })
     *   .catch(function() { console.log('/o\'); });
     * ```
     *
     * @memberof ApiService
     * @instance
     *
     * @method create
     * @param  {String} namespace
     * @param  {String|String[]} uri
     * @param  {Object} data
     * @param  {Object} options
     * @returns {Ember.RSVP.Promise}
     */
    create: function create(namespace, uri, data) {
      var options = arguments.length > 3 && arguments[3] !== undefined ? arguments[3] : {};
      (false && !(Ember.typeOf(namespace) === 'string') && Ember.assert('create(): namespace needs to be a string', Ember.typeOf(namespace) === 'string'));
      options = Object.assign({
        // defaults (can be overridden)
        contentType: 'application/json'
      }, options);
      options.url = this.buildUrl(namespace, uri);
      options.method = 'POST';
      options.data = this.encodeData(data, options.contentType);
      return this.request(options);
    },
    /**
     * ```javascript
     * this.get('api').update('vdc', ['/stores', {name: 'test'}])
     *   .then(function() { console.log('\o/'); })
     *   .catch(function() { console.log('/o\'); });
     * ```
     *
     * @memberof ApiService
     * @instance
     *
     * @method put
     * @param  {String} namespace
     * @param  {String|String[]} uri
     * @param  {Object} data
     * @param  {Object} options
     * @return {Ember.RSVP.Promise}
     */
    update: function update(namespace, uri, data) {
      var options = arguments.length > 3 && arguments[3] !== undefined ? arguments[3] : {};
      options = Object.assign({
        // defaults (can be overridden)
        contentType: 'application/json'
      }, options);
      options.url = this.buildUrl(namespace, uri);
      options.method = 'PUT';
      options.data = this.encodeData(data, options.contentType);
      return this.request(options);
    },
    /**
     * ```javascript
     * this.get('api').partialUpdate('vdc', ['/stores', { name: 'test' }])
     *   .then(() => console.log('Success'))
     *   .catch((error) => console.error(error.message));
     * ```
     *
     * @memberof ApiService
     * @instance
     *
     * @method patch
     * @param  {String} namespace
     * @param  {String|String[]} uri
     * @param  {Object} data
     * @param  {Object} options
     * @return {Ember.RSVP.Promise}
     */
    partialUpdate: function partialUpdate(namespace, uri, data) {
      var options = arguments.length > 3 && arguments[3] !== undefined ? arguments[3] : {};
      options = Object.assign({
        // defaults (can be overridden)
        contentType: 'application/json'
      }, options);
      options.url = this.buildUrl(namespace, uri);
      options.method = 'PATCH';
      options.data = this.encodeData(data, options.contentType);
      return this.request(options);
    },
    /**
     * ```javascript
     * this.get('api').delete('vdc', ['stores', 1])
     *   .then(function() { console.log('\o/'); })
     *   .catch(function() { console.log('/o\'); });
     * ```
     *
     * @memberof ApiService
     * @instance
     *
     * @method delete
     * @param  {String} namespace
     * @param  {String|String[]} uri
     * @param  {Object} data
     * @param  {Object} options
     * @return {Ember.RSVP.Promise}
     */
    delete: function _delete(namespace, uri, data) {
      var options = arguments.length > 3 && arguments[3] !== undefined ? arguments[3] : {};
      var requestOptions = {
        url: this.buildUrl(namespace, uri),
        method: 'DELETE'
      };
      if (_typeof(data) === 'object') {
        requestOptions = Object.assign({
          // defaults (can be overridden)
          contentType: 'application/json'
        }, requestOptions, options);
        requestOptions.data = this.encodeData(data, requestOptions.contentType);
      }
      return this.request(requestOptions);
    },
    /**
     * ```javascript
     * this.get('api').copy('vdc', ['stores'])
     *   .then(function() { console.log('\o/'); })
     *   .catch(function() { console.log('/o\'); });
     * ```
     *
     * @memberof ApiService
     * @instance
     *
     * @method copy
     * @param  {String} namespace
     * @param  {String|String[]} uri
     * @return {Ember.RSVP.Promise}
     */
    copy: function copy(namespace, uri) {
      return this.request({
        url: this.buildUrl(namespace, uri),
        method: 'COPY'
      });
    },
    /**
     * @memberof ApiService
     * @instance
     *
     * @private
     * @method encodeData
     * @param  {Object} data
     * @param  {String|String[]} contentType
     * @return {Object}
     */
    encodeData: function encodeData(data, contentType) {
      if (contentType === 'application/json') {
        return JSON.stringify(data);
      } else if (contentType === 'multipart/form-data') {
        var formData = new FormData();
        Object.keys(data).forEach(function (key) {
          var value = data[key];
          if (value instanceof File) {
            formData.append(key, value, value.name);
          } else {
            formData.append(key, value);
          }
        });
        return formData;
      }
      return data;
    },
    /**
     * @memberof ApiService
     * @instance
     *
     * @private
     * @method headers
     * @param  {String} contentType
     * @return {object}
     */
    headers: function headers() {
      var contentType = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : 'application/json';
      var headers = {
        'Accept': 'application/json',
        'Accept-Language': this.get('language')
      };
      var token = this._token;
      if (token) {
        headers.Authorization = "Bearer ".concat(token);
      }
      if (contentType === 'application/json') {
        contentType += '; charset=UTF-8';
      }
      if (contentType) {
        headers['Content-Type'] = contentType;
      }
      return headers;
    },
    /**
     * @memberof ApiService
     * @instance
     *
     * @private
     * @method buildUrl
     * @param  {String} namespace
     * @param  {String|String[]} uri
     * @return {Array}
     */
    buildUrl: function buildUrl(namespace, uri) {
      if (Ember.typeOf(uri) === 'array') {
        uri = _url.default.api.apply(_url.default, _toConsumableArray(uri));
      }
      (false && !(Ember.typeOf(uri) === 'string') && Ember.assert('[ln-ember-api-service] buildUrl() uri needs to be a string', Ember.typeOf(uri) === 'string'));
      (false && !(Ember.typeOf(namespace) === 'string') && Ember.assert('[ln-ember-api-service] buildUrl() namespace needs to be a string', Ember.typeOf(namespace) === 'string'));
      (false && !(this.namespaceExists(namespace)) && Ember.assert("[ln-ember-api-service] buildUrl() namespace \"".concat(namespace, "\" does not exits for Url  \"").concat(uri, "\""), this.namespaceExists(namespace)));
      if (!uri.match(/^\//)) {
        uri = "/".concat(uri);
      }
      var baseUrl = this.baseUrlForNamespace(namespace);
      return "".concat(baseUrl).concat(uri);
    },
    /**
     * @memberof ApiService
     * @instance
     *
     * @private
     * @method request
     * @param  {object} options
     * @return {Object|Ember.RSVP.Promise}
     */
    request: function request(options) {
      var _this2 = this;
      var tokenPromise = Ember.RSVP.resolve('loadToken' in this ? this.loadToken() : this.get('session.token'));
      return tokenPromise.then(function (token) {
        _this2.set('_token', token);
        _this2.requestOptionsFor(options);
        var data = _this2.getRequestedData(options);
        return Object.assign(data.promise, {
          abort: function abort() {
            return data.xhr.abort();
          }
        });
      });
    },
    /**
     * @memberof ApiService
     * @instance
     *
     * @private
     * @method getRequestedData
     * @param  {object} options
     * @return {Object|Ember.RSVP.Promise}
     */
    getRequestedData: function getRequestedData(options) {
      var _this3 = this;
      var xhr;
      return {
        promise: new Ember.RSVP.Promise(function (resolve, reject) {
          xhr = _jquery.default.ajax(Object.assign({}, options, {
            success: function success(resp) {
              return resolve(resp);
            },
            error: function error(response) {
              if (xhr.statusText === 'abort') {
                // Resolve is here, because we don't need an error/failure message
                // and it's empty because we don't have something for aborted request
                resolve();
                return;
              }
              _this3.get('apiEvents').triggerErrorResponse(response);
              var payload = Ember.get(response, 'responseJSON') || Ember.get(response, 'responseText');
              reject((0, _normalizeError.default)(Ember.get(response, 'status'), payload));
            }
          }));
          return xhr;
        }),
        xhr: xhr
      };
    },
    /**
     * @memberof ApiService
     * @instance
     *
     * @private
     * @method requestOptionsFor
     * @param  {Object} options
     * @return {Object}
     */
    requestOptionsFor: function requestOptionsFor(options) {
      if (options.contentType === 'multipart/form-data') {
        // Content-Type Header including the boundary is set automatically by
        // jQuery / FormData.
        options.contentType = false;
      }
      Object.assign(options, {
        dataType: 'json',
        headers: this.headers(options.contentType),
        processData: options.contentType === 'application/json'
      });
      if (options.progress && typeof options.progress === 'function') {
        Object.assign(options, {
          xhr: function xhr() {
            var myXhr = _jquery.default.ajaxSettings.xhr();
            if (myXhr.upload) {
              myXhr.upload.addEventListener('progress', function () {
                options.progress.apply(options, arguments);
              }, false);
            }
            return myXhr;
          }
        });
      }
      return options;
    },
    /**
     * @memberof ApiService
     * @instance
     *
     * @private
     * @method baseUrlForNamespace
     * @param  {String} namespace
     * @return {String}
     */
    baseUrlForNamespace: function baseUrlForNamespace(namespace) {
      var _this$get = this.get("config.api-".concat(namespace)),
        url = _this$get.url,
        version = _this$get.version;
      return "".concat(url, "/").concat(version);
    },
    namespaceExists: function namespaceExists(namespace) {
      var _ref = this.get("config.api-".concat(namespace)) || {},
        _ref$url = _ref.url,
        url = _ref$url === void 0 ? null : _ref$url,
        _ref$version = _ref.version,
        version = _ref$version === void 0 ? null : _ref$version;
      return !Ember.isNone(url) && !Ember.isNone(version);
    }
  });
});