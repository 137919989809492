define("ln-ember-toolkit/utils/mock-ligaos", ["exports", "ln-ember-toolkit/utils/liga-os"], function (_exports, _ligaOs) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = mockLigaOS;
  function _toConsumableArray(r) { return _arrayWithoutHoles(r) || _iterableToArray(r) || _unsupportedIterableToArray(r) || _nonIterableSpread(); }
  function _nonIterableSpread() { throw new TypeError("Invalid attempt to spread non-iterable instance.\nIn order to be iterable, non-array objects must have a [Symbol.iterator]() method."); }
  function _unsupportedIterableToArray(r, a) { if (r) { if ("string" == typeof r) return _arrayLikeToArray(r, a); var t = {}.toString.call(r).slice(8, -1); return "Object" === t && r.constructor && (t = r.constructor.name), "Map" === t || "Set" === t ? Array.from(r) : "Arguments" === t || /^(?:Ui|I)nt(?:8|16|32)(?:Clamped)?Array$/.test(t) ? _arrayLikeToArray(r, a) : void 0; } }
  function _iterableToArray(r) { if ("undefined" != typeof Symbol && null != r[Symbol.iterator] || null != r["@@iterator"]) return Array.from(r); }
  function _arrayWithoutHoles(r) { if (Array.isArray(r)) return _arrayLikeToArray(r); }
  function _arrayLikeToArray(r, a) { (null == a || a > r.length) && (a = r.length); for (var e = 0, n = Array(a); e < a; e++) n[e] = r[e]; return n; }
  function mockLigaOS() {
    var overrides = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
    var config = configFactory(overrides.config);
    var theme = themeFactory(overrides.theme);
    var session = sessionFactory(overrides.session);
    var settings = settingsFactory(overrides.settings);
    var language = overrides.language || 'en';
    return Ember.assign(_ligaOs.default, {
      _openedUrl: '',
      getConfig: function getConfig() {
        return new Promise(function (resolve) {
          return resolve(config);
        });
      },
      getLanguage: function getLanguage() {
        return new Promise(function (resolve) {
          return resolve(language);
        });
      },
      getSession: function getSession() {
        return new Promise(function (resolve) {
          return resolve(session);
        });
      },
      getTheme: function getTheme() {
        return new Promise(function (resolve) {
          return resolve(theme);
        });
      },
      getSetting: function getSetting(key, defaultValue) {
        return Ember.RSVP.resolve(typeof settings[key] !== 'undefined' ? settings[key] : defaultValue);
      },
      call: function call(name) {
        var args = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : [];
        if (typeof this[name] !== 'function') {
          throw new Error("Unknown method to call: \"".concat(name, "\""));
        }
        return this[name].apply(this, _toConsumableArray(args));
      },
      checkBrowserSupport: function checkBrowserSupport() {
        return new Promise(function (resolve) {
          return resolve(true);
        });
      },
      notifyAppLoaded: function notifyAppLoaded() {},
      hideCommunicator: function hideCommunicator() {},
      showCommunicator: function showCommunicator() {},
      setUrl: function setUrl() {},
      openUrl: function openUrl(url) {
        this._openedUrl = url;
      },
      // for convenient access from tests
      getOpenedUrl: function getOpenedUrl() {
        return this._openedUrl;
      }
    });
  }
  function settingsFactory(overrides) {
    overrides = overrides || {};
    return Ember.assign({
      TimeboardGuideSeen: true
    }, overrides);
  }
  function configFactory(overrides) {
    overrides = overrides || {};
    return Ember.assign({
      'api-myliga': {
        // ln-ember-data-models currently misbehaves when the request path doesn't have a domain name.
        // @see: https://bitbucket.org/ligadigital/ln-ember-data-models/src/7b7348eae3323f6483f0f2418b172a4c1b4eb61c/addon/adapters/application.js?at=master&fileviewer=file-view-default#application.js-238
        url: 'http://somewhere.far.enough/api',
        version: 'v1'
      },
      'api-vdc': {
        url: 'http://somewhere.even.further/api',
        version: 'v1'
      },
      'api-contact': {
        url: 'http://somewhere.where.contacts.live/api',
        version: ''
      },
      'api-timeboard': {
        url: 'http://somewhere.where.timeboard.live/api',
        version: 'v1'
      },
      'api-ex3000': {
        url: 'http://ln-api-ex3000.ln-test.liga.cloud'
      },
      resizer: {
        url: 'http://ln-image-resizer.ln-test.liga.cloud'
      }
    }, overrides);
  }
  function themeFactory(overrides) {
    overrides = overrides || {};
    return Ember.assign({
      type: 'LIGANOVA',
      background_image: {
        preview_url: 'about:blank'
      },
      logo_image: {
        preview_url: 'about:blank'
      }
    }, overrides);
  }
  function sessionFactory() {
    var overrides = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
    var current = new Date();
    var exp = new Date(current.getTime() + 1000 * 60 * 60 * 24 * 365);
    var user_id = overrides.user_id,
      _overrides$expire_dat = overrides.expire_date,
      expire_date = _overrides$expire_dat === void 0 ? exp : _overrides$expire_dat,
      _overrides$permission = overrides.permissions,
      permissions = _overrides$permission === void 0 ? [] : _overrides$permission;
    if (user_id) {
      var tokenObj = {
        expire_date: expire_date,
        userInfo: {
          id: user_id,
          permissions: permissions
        }
      };
      delete overrides.permissions;
      overrides.token = "eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.".concat(btoa(JSON.stringify(tokenObj)), ".KWRjB1jgUODDbPe_T85VXfQVtiRVzlYDiQjqRj6-m3Y");
    }
    return Ember.assign({}, overrides);
  }
});