define("ln-ember-data-models/models/vdc/fragments/project-type-settings", ["exports", "ember-data", "ember-data-model-fragments/fragment"], function (_exports, _emberData, _fragment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  _exports.getDefaults = getDefaults;
  var attr = _emberData.default.attr;
  function getDefaults() {
    return {
      allow_assembled_article_image_update: false,
      allow_quality_audits: false,
      article_number_changable_after_order: false,
      centralized_trade_localization: false,
      display_quality_gate: false,
      has_additional_order_phases: false,
      has_fixtures: false,
      has_instore_areas: false,
      has_stores: false,
      has_windows: false,
      include_costs_in_export: false,
      language_date_selection: false,
      override_article_price_change: false,
      override_assembly_fit: false,
      request_export_documents_information: false,
      respect_weekends: false,
      send_email_to_order_author: false,
      send_emails_based_on_assembly_status_change: false,
      set_article_review_to_draft_on_assembly_status_change: false,
      show_in_progress_store_in_assembly: false,
      status_new_only_after_ready_for_po_number_with_po_number: false,
      translations_country_aware: false,
      translations_hq_approval_necessary: false,
      use_in_cockpit_calculation: false,
      uses_motif_croppings: false
    };
  }

  /**
   * @class Vdc/fragments/ProjectTypeSettingsFragment
   * @extends Fragment
   */
  var _default = _exports.default = _fragment.default.extend({
    /**
     * @memberof Vdc/fragments/ProjectTypeSettingsFragment
     * @instance
     *
     * @type {Boolean}
     */
    centralized_trade_localization: attr('boolean', {
      defaultValue: function defaultValue(model, opts, key) {
        return getDefaults()[key];
      }
    }),
    /**
     * @memberof Vdc/fragments/ProjectTypeSettingsFragment
     * @instance
     *
     * @type {Boolean}
     */
    has_windows: attr('boolean', {
      defaultValue: function defaultValue(model, opts, key) {
        return getDefaults()[key];
      }
    }),
    /**
     * @memberof Vdc/fragments/ProjectTypeSettingsFragment
     * @instance
     *
     * @type {Boolean}
     */
    has_fixtures: attr('boolean', {
      defaultValue: function defaultValue(model, opts, key) {
        return getDefaults()[key];
      }
    }),
    /**
     * @memberof Vdc/fragments/ProjectTypeSettingsFragment
     * @instance
     *
     * @type {Boolean}
     */
    has_instore_areas: attr('boolean', {
      defaultValue: function defaultValue(model, opts, key) {
        return getDefaults()[key];
      }
    }),
    /**
     * @memberof Vdc/fragments/ProjectTypeSettingsFragment
     * @instance
     *
     * @type {Boolean}
     */
    has_stores: attr('boolean', {
      defaultValue: function defaultValue(model, opts, key) {
        return getDefaults()[key];
      }
    }),
    /**
     * @memberof Vdc/fragments/ProjectTypeSettingsFragment
     * @instance
     *
     * @type {Boolean}
     */
    override_assembly_fit: attr('boolean', {
      defaultValue: function defaultValue(model, opts, key) {
        return getDefaults()[key];
      }
    }),
    /**
     * @memberof Vdc/fragments/ProjectTypeSettingsFragment
     * @instance
     *
     * @type {Boolean}
     */
    uses_motif_croppings: attr('boolean', {
      defaultValue: function defaultValue(model, opts, key) {
        return getDefaults()[key];
      }
    }),
    /**
     * @memberof Vdc/fragments/ProjectTypeSettingsFragment
     * @instance
     *
     * @type {Boolean}
     */
    set_article_review_to_draft_on_assembly_status_change: attr('boolean', {
      defaultValue: function defaultValue(model, opts, key) {
        return getDefaults()[key];
      }
    }),
    /**
     * @memberof Vdc/fragments/ProjectTypeSettingsFragment
     * @instance
     *
     * @type {Boolean}
     */
    show_in_progress_store_in_assembly: attr('boolean', {
      defaultValue: function defaultValue(model, opts, key) {
        return getDefaults()[key];
      }
    }),
    /**
     * @memberof Vdc/fragments/ProjectTypeSettingsFragment
     * @instance
     *
     * @type {Boolean}
     */
    display_quality_gate: attr('boolean', {
      defaultValue: function defaultValue(model, opts, key) {
        return getDefaults()[key];
      }
    }),
    /**
     * @memberof Vdc/fragments/ProjectTypeSettingsFragment
     * @instance
     *
     * @type {Boolean}
     */
    has_additional_order_phases: attr('boolean', {
      defaultValue: function defaultValue(model, opts, key) {
        return getDefaults()[key];
      }
    }),
    /**
     * @memberof Vdc/fragments/ProjectTypeSettingsFragment
     * @instance
     *
     * @type {Boolean}
     */
    override_article_price_change: attr('boolean', {
      defaultValue: function defaultValue(model, opts, key) {
        return getDefaults()[key];
      }
    }),
    /**
     * @memberof Vdc/fragments/ProjectTypeSettingsFragment
     * @instance
     *
     * @type {Boolean}
     */
    use_in_cockpit_calculation: attr('boolean', {
      defaultValue: function defaultValue(model, opts, key) {
        return getDefaults()[key];
      }
    }),
    /**
     * @memberof Vdc/fragments/ProjectTypeSettingsFragment
     * @instance
     *
     * @type {Boolean}
     */
    request_export_documents_information: attr('boolean', {
      defaultValue: function defaultValue(model, opts, key) {
        return getDefaults()[key];
      }
    }),
    /**
     * @memberof Vdc/fragments/ProjectTypeSettingsFragment
     * @instance
     *
     * @type {Boolean}
     */
    status_new_only_after_ready_for_po_number_with_po_number: attr('boolean', {
      defaultValue: function defaultValue(model, opts, key) {
        return getDefaults()[key];
      }
    }),
    /**
     * @memberof Vdc/fragments/ProjectTypeSettingsFragment
     * @instance
     *
     * @type {Boolean}
     */
    translations_country_aware: attr('boolean', {
      defaultValue: function defaultValue(model, opts, key) {
        return getDefaults()[key];
      }
    }),
    /**
     * @memberof Vdc/fragments/ProjectTypeSettingsFragment
     * @instance
     *
     * @type {Boolean}
     */
    translations_hq_approval_necessary: attr('boolean', {
      defaultValue: function defaultValue(model, opts, key) {
        return getDefaults()[key];
      }
    }),
    /**
     * @memberof Vdc/fragments/ProjectTypeSettingsFragment
     * @instance
     *
     * @type {Boolean}
     */
    send_emails_based_on_assembly_status_change: attr('boolean', {
      defaultValue: function defaultValue(model, opts, key) {
        return getDefaults()[key];
      }
    }),
    /**
     * @memberof Vdc/fragments/ProjectTypeSettingsFragment
     * @instance
     *
     * @type {Boolean}
     */
    send_email_to_order_author: attr('boolean', {
      defaultValue: function defaultValue(model, opts, key) {
        return getDefaults()[key];
      }
    }),
    /**
     * @memberof Vdc/fragments/ProjectTypeSettingsFragment
     * @instance
     *
     * @type {Boolean}
     */
    allow_assembled_article_image_update: attr('boolean', {
      defaultValue: function defaultValue(model, opts, key) {
        return getDefaults()[key];
      }
    }),
    /**
     * @memberof Vdc/fragments/ProjectTypeSettingsFragment
     * @instance
     *
     * @type {Boolean}
     */
    allow_quality_audits: attr('boolean', {
      defaultValue: function defaultValue(model, opts, key) {
        return getDefaults()[key];
      }
    }),
    /**
     * @memberof Vdc/fragments/ProjectTypeSettingsFragment
     * @instance
     *
     * @type {Boolean}
     */
    language_date_selection: attr('boolean', {
      defaultValue: function defaultValue(model, opts, key) {
        return getDefaults()[key];
      }
    }),
    /**
     * @memberof Vdc/fragments/ProjectTypeSettingsFragment
     * @instance
     *
     * @type {Boolean}
     */
    article_number_changable_after_order: attr('boolean', {
      defaultValue: function defaultValue(model, opts, key) {
        return getDefaults()[key];
      }
    }),
    /**
     * @memberof Vdc/fragments/ProjectTypeSettingsFragment
     * @instance
     *
     * @type {Boolean}
     */
    respect_weekends: attr('boolean', {
      defaultValue: function defaultValue(model, opts, key) {
        return getDefaults()[key];
      }
    }),
    /**
     * @memberof Vdc/fragments/ProjectTypeSettingsFragment
     * @instance
     *
     * @type {Boolean}
     */
    include_costs_in_export: attr('boolean', {
      defaultValue: function defaultValue(model, opts, key) {
        return getDefaults()[key];
      }
    })
  });
});