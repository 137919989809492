define("ln-ember-data-models/models/vdc/supplier-info", ["exports", "ember-data"], function (_exports, _emberData) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var attr = _emberData.default.attr,
    Model = _emberData.default.Model;

  /**
   * @class Vdc/SupplierInfoModel
   * @extends DS.Model
   */
  /* eslint-disable-next-line ligadigital/ember-model-version-attribute */
  var _default = _exports.default = Model.extend({
    /**
     * @memberof Vdc/SupplierInfoModel
     * @instance
     *
     * @type {Boolean}
     */
    force_preferred_supplier: attr('boolean', {
      defaultValue: false
    }),
    /**
     * @memberof Vdc/SupplierInfoModel
     * @instance
     *
     * @type {String} It's a UUID
     */
    /* eslint-disable ligadigital/ember-model-custom-types */
    supplier_id: attr('nullable-string'),
    /**
     * @memberof Vdc/SupplierInfoModel
     * @instance
     *
     * @type {String} It's a UUID
     */
    /* eslint-disable ligadigital/ember-model-custom-types */
    preferred_supplier_id: attr('nullable-string')
  });
});