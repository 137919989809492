define("ln-ember-data-models/models/myliga/event-participant", ["exports", "ember-data", "ember-data-model-fragments/attributes", "ln-ember-data-models/utils/enum-option"], function (_exports, _emberData, _attributes, _enumOption) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.statusOptions = _exports.default = void 0;
  var Model = _emberData.default.Model,
    attr = _emberData.default.attr;

  /**
   * @name Myliga/EventParticipantModel/statusOptions
   * @type {EnumOption[]}
   */
  var statusOptions = _exports.statusOptions = [_enumOption.default.create({
    id: 'ParticipationRequested'
  }), _enumOption.default.create({
    id: 'Invited'
  }), _enumOption.default.create({
    id: 'Accepted'
  }), _enumOption.default.create({
    id: 'Declined'
  }), _enumOption.default.create({
    id: 'Rejected'
  }), _enumOption.default.create({
    id: 'WaitingList'
  })];

  /**
   * @class Myliga/EventParticipantModel
   * @extends Model
   */
  var EventParticipantModel = Model.extend({
    /**
     * @memberof Myliga/EventParticipantModel
     * @instance
     *
     * @type {Number}
     */
    version: attr('number'),
    /**
     * @memberof Myliga/EventParticipantModel
     * @instance
     *
     * @type {Number}
     */
    event_id: attr('id'),
    /**
     * @memberof Myliga/EventParticipantModel
     * @instance
     *
     * @type {Number}
     */
    user_id: attr('id'),
    /**
     * @memberof Myliga/EventParticipantModel
     * @instance
     *
     * @type {String}
     */
    user_name: attr('nullable-string'),
    /**
     * @memberof Myliga/EventParticipantModel
     * @instance
     *
     * @type {String}
     */
    user_email: attr('nullable-string'),
    /**
     * @memberof Myliga/EventParticipantModel
     * @instance
     *
     * @type {String}
     */
    status: attr('enum-string', {
      options: statusOptions,
      defaultValue: 'Invited'
    }),
    /**
     * @memberof Myliga/EventParticipantModel
     * @instance
     *
     * @type {MyLiga/fragments/PictureMeta}
     */
    user_picture: (0, _attributes.fragment)('myliga/fragments/picture'),
    /**
     * @memberof Myliga/EventParticipantModel
     * @instance
     *
     * @type {Boolean}
     */
    isOpen: Ember.computed.equal('status', 'Open'),
    /**
     * @memberof Myliga/EventParticipantModel
     * @instance
     *
     * @type {Boolean}
     */
    isAccepted: Ember.computed.equal('status', 'Accepted'),
    /**
     * @memberof Myliga/EventParticipantModel
     * @instance
     *
     * @type {Boolean}
     */
    isDeclined: Ember.computed.equal('status', 'Declined'),
    /**
     * @memberof Myliga/EventParticipantModel
     * @instance
     *
     * @type {Boolean}
     */
    isInvited: Ember.computed.equal('status', 'Invited'),
    /**
     * @memberof Myliga/EventParticipantModel
     * @instance
     *
     * @type {Boolean}
     */
    isRejected: Ember.computed.equal('status', 'Rejected'),
    /**
     * @memberof Myliga/EventParticipantModel
     * @instance
     *
     * @type {Boolean}
     */
    isParticipationRequested: Ember.computed.equal('status', 'isParticipationRequested'),
    /**
     * @memberof Myliga/EventParticipantModel
     * @instance
     *
     * @type {Boolean}
     */
    isWaitingList: Ember.computed.equal('status', 'WaitingList'),
    /**
     * @memberof Myliga/EventParticipantModel
     * @instance
     *
     * @type {Boolean}
     */
    isCurrentUser: Ember.computed('user_id', 'apiEvents.session.user_id', function () {
      return this.get('user_id') === this.get('apiEvents.session.user_id');
    }),
    apiEvents: Ember.inject.service()
  });
  EventParticipantModel.reopenClass({
    /**
     * @memberof Myliga/EventParticipantModel
     * @static
     *
     * @type {EnumOption[]}
     */
    statusOptions: statusOptions
  });
  var _default = _exports.default = EventParticipantModel;
});