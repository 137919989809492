define("ln-ember-form-elements/templates/components/form-elements/radio-button", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.HTMLBars.template({
    "id": "QGGK2Hhe",
    "block": "{\"symbols\":[\"@name\",\"@label\",\"&default\"],\"statements\":[[7,\"input\",true],[11,\"name\",[23,1,[]]],[11,\"value\",[23,0,[\"buttonValue\"]]],[11,\"checked\",[23,0,[\"checked\"]]],[10,\"type\",\"radio\"],[8],[9],[0,\"\\n\"],[7,\"span\",true],[11,\"class\",[29,[\"radio-circle \",[28,\"if\",[[23,0,[\"checked\"]],\"selected\"],null]]]],[8],[9],[0,\"\\n\"],[7,\"span\",true],[10,\"class\",\"radio-label\"],[8],[1,[23,2,[]],false],[9],[0,\"\\n\"],[14,3]],\"hasEval\":false}",
    "meta": {
      "moduleName": "ln-ember-form-elements/templates/components/form-elements/radio-button.hbs"
    }
  });
});