define("ln-ember-data-models/utils/store-colors", ["exports", "ln-ember-data-models/utils/enum-option"], function (_exports, _enumOption) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = [_enumOption.default.create({
    id: 'Black',
    hex: '#000000'
  }), _enumOption.default.create({
    id: 'Grey',
    hex: '#6F6F6F'
  }), _enumOption.default.create({
    id: 'DarkGreen',
    hex: '#277469'
  }), _enumOption.default.create({
    id: 'LightGreen',
    hex: '#5BB57F'
  }), _enumOption.default.create({
    id: 'Blue',
    hex: '#0075AC'
  }), _enumOption.default.create({
    id: 'Red',
    hex: '#AB0000'
  }), _enumOption.default.create({
    id: 'Purple',
    hex: '#B55B86'
  }), _enumOption.default.create({
    id: 'Yellow',
    hex: '#CFB017'
  }), _enumOption.default.create({
    id: 'DarkYellow',
    hex: '#A29249'
  }), _enumOption.default.create({
    id: 'Brown',
    hex: '#A26949'
  })];
});