define("ln-ember-data-models/utils/enum-option", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.Object.extend({
    name: Ember.computed('id', '_name', {
      get: function get() {
        return String(this.get('id') || '').replace(/([a-z])([A-Z])/g, '$1 $2');
      },
      set: function set(key, value) {
        this.set('_name', value);
        return value;
      }
    }),
    dasherizedId: Ember.computed('name', function () {
      return String(this.get('id')).dasherize();
    }),
    id: null,
    _name: null
  });
});