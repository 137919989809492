define("ln-ember-data-models/models/vdc/fragments/export-document", ["exports", "ember-data", "ember-data-model-fragments/fragment"], function (_exports, _emberData, _fragment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var attr = _emberData.default.attr;

  /**
   * @class Vdc/fragments/ExportDocumentFragment
   * @extends Fragment
   */
  var _default = _exports.default = _fragment.default.extend({
    /**
     * @memberof Vdc/fragments/ExportDocumentFragment
     * @instance
     *
     * @type {String}
     */
    invoice_type: attr('nullable-string'),
    /**
     * @memberof Vdc/fragments/ExportDocumentFragment
     * @instance
     *
     * @type {Boolean}
     */
    eur1_certificate: attr('boolean', {
      defaultValue: function defaultValue() {
        return false;
      }
    }),
    /**
     * @memberof Vdc/fragments/ExportDocumentFragment
     * @instance
     *
     * @type {Boolean}
     */
    certificate_of_origin: attr('boolean', {
      defaultValue: function defaultValue() {
        return false;
      }
    })
  });
});