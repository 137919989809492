define("ln-ember-form-elements/components/form-elements/dropdown-button/button", ["exports", "ln-ember-form-elements/templates/components/form-elements/dropdown-button/button"], function (_exports, _button) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.Component.extend({
    layout: _button.default,
    classNames: ['form-elements--dropdown-button--button-component']
  });
});