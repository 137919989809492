define("ember-collection/components/ember-collection/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.HTMLBars.template({
    "id": "pQRWcQai",
    "block": "{\"symbols\":[\"cell\",\"&default\"],\"statements\":[[4,\"ember-native-scrollable\",null,[[\"content-size\",\"scroll-left\",\"scroll-top\",\"scrollChange\",\"clientSizeChange\"],[[24,[\"_contentSize\"]],[24,[\"_scrollLeft\"]],[24,[\"_scrollTop\"]],[28,\"action\",[[23,0,[]],\"scrollChange\"],null],[28,\"action\",[[23,0,[]],\"clientSizeChange\"],null]]],{\"statements\":[[0,\"  \"],[7,\"div\",true],[8],[4,\"each\",[[24,[\"_cells\"]]],null,{\"statements\":[[7,\"div\",true],[18,\"style\",[23,1,[\"style\"]]],[8],[14,2,[[23,1,[\"item\"]],[23,1,[\"index\"]]]],[9]],\"parameters\":[1]},null],[9],[0,\"\\n\"]],\"parameters\":[]},null]],\"hasEval\":false}",
    "meta": {
      "moduleName": "ember-collection/components/ember-collection/template.hbs"
    }
  });
});