define("ln-ember-form-elements/templates/components/form-elements/toggle-button", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.HTMLBars.template({
    "id": "cg8FsjCZ",
    "block": "{\"symbols\":[],\"statements\":[[7,\"input\",true],[11,\"id\",[23,0,[\"checkboxId\"]]],[11,\"checked\",[23,0,[\"value\"]]],[11,\"onchange\",[28,\"action\",[[23,0,[]],\"toggleValue\"],null]],[10,\"type\",\"checkbox\"],[8],[9],[0,\"\\n\"],[7,\"label\",true],[11,\"for\",[23,0,[\"checkboxId\"]]],[8],[9],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "ln-ember-form-elements/templates/components/form-elements/toggle-button.hbs"
    }
  });
});