define("ln-ember-contact-menu/templates/components/contact-menu-handle", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.HTMLBars.template({
    "id": "LQntdeDp",
    "block": "{\"symbols\":[\"&attrs\",\"@class\",\"&default\",\"@userId\"],\"statements\":[[4,\"if\",[[23,0,[\"isEnabled\"]]],null,{\"statements\":[[0,\"  \"],[7,\"div\",false],[13,1],[12,\"id\",[23,0,[\"_elementId\"]]],[12,\"class\",[29,[[23,2,[]],\" ContactMenuHandle\"]]],[12,\"data-open\",[23,0,[\"isOpen\"]]],[12,\"tabindex\",\"0\"],[12,\"onclick\",[28,\"fn\",[[23,0,[\"openContactMenu\"]],[28,\"component\",[\"contact-menu\"],[[\"userId\"],[[23,4,[]]]]]],null]],[8],[0,\"\\n    \"],[14,3],[0,\"\\n  \"],[9],[0,\"\\n\"]],\"parameters\":[]},{\"statements\":[[0,\"  \"],[7,\"div\",false],[13,1],[12,\"class\",[29,[[23,2,[]],\" ContactMenuHandle\"]]],[8],[0,\"\\n    \"],[14,3],[0,\"\\n  \"],[9],[0,\"\\n\"]],\"parameters\":[]}]],\"hasEval\":false}",
    "meta": {
      "moduleName": "ln-ember-contact-menu/templates/components/contact-menu-handle.hbs"
    }
  });
});