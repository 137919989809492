define("ln-ember-toolkit/components/resized-image", ["exports", "jquery"], function (_exports, _jquery) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  /**
   * ## Example
   *
   * ```
   * {{resized-image src="url/to/image.jpg"}}
   * ```
   *
   *
   * @class ResizedImage
   */
  var _default = _exports.default = Ember.Component.extend({
    resizer: Ember.inject.service(),
    tagName: 'img',
    classNames: ['resized-image-component'],
    classNameBindings: ['animated', 'loaded:is-loaded:is-not-loaded', 'showBeforeLoaded'],
    attributeBindings: ['attrStyle:style', 'attrSrc:src', 'title'],
    /**
     * @memberof ResizedImage
     * @instance
     *
     * @type {String}
     */
    src: null,
    /**
     * Url to placeholder image
     *
     * @memberof ResizedImage
     * @instance
     *
     * @type {String}
     */
    placeholder: 'data:image/gif;base64,R0lGODlhAQABAPAAAP///wAAACH5BAEAAAAALAAAAAABAAEAAAICRAEAOw==',
    /**
     * An image to be inserted when original image fetching errored
     *
     * @memberof ResizedImage
     * @instance
     *
     * @type {String}
     */
    errorPlaceholder: null,
    /**
     * @memberof ResizedImage
     * @instance
     *
     * @type {String}
     * @default 'crop'
     */
    mode: 'crop',
    /**
     * @memberof ResizedImage
     * @instance
     *
     * @type {Boolean}
     * @default false
     */
    animated: false,
    /**
     * @memberof ResizedImage
     * @instance
     *
     * @type {Boolean}
     * @default false
     */
    showBeforeLoaded: false,
    /**
     * @memberof ResizedImage
     * @instance
     *
     * @type {String}
     */
    title: null,
    loadedSrc: null,
    hasError: false,
    width: Ember.computed('size', function () {
      var size = (this.size || '').match(/^(\d*)x\d*$/);
      return size ? size[1] : 0;
    }),
    height: Ember.computed('size', function () {
      var size = (this.size || '').match(/^\d*x(\d*)$/);
      return size ? size[1] : 0;
    }),
    attrStyle: Ember.computed('width', 'height', 'animated', 'loaded', function () {
      var style = [];
      if (this.width || this.height) {
        var width = 'width';
        var height = 'height';
        var widthValue = this.width ? "".concat(this.width, "px") : 'auto';
        var heightValue = this.height ? "".concat(this.height, "px") : 'auto';
        if (this.mode === 'resize') {
          width = 'max-width';
          height = 'max-height';
        }
        style.push("".concat(width, ": ").concat(widthValue));
        style.push("".concat(height, ": ").concat(heightValue));
      }
      return Ember.String.htmlSafe(style.join(';'));
    }),
    loaded: Ember.computed('attrSrc', 'loadedSrc', function () {
      return this.attrSrc === this.loadedSrc;
    }),
    srcUrl: Ember.computed('src', 'src.preview_url', function () {
      var src = this.get('src.preview_url') || this.src;
      if (src && typeof src === 'string') {
        return src;
      }
      return undefined;
    }),
    attrSrc: Ember.computed('srcUrl', 'width', 'height', 'hasError', function () {
      if (!this.hasError) {
        var width = (this.width || 0) * 2;
        var height = (this.height || 0) * 2;
        if (width <= 0 && height <= 0) {
          return undefined;
        }
        var src = this.srcUrl;
        if (src) {
          var url = this.resizer.getUrl(this.mode, src, width, height);
          if (url) {
            return url;
          }
        }
        return this.placeholder;
      }
      return this.errorPlaceholder || this.placeholder;
    }),
    // eslint-disable-next-line ember/no-observers
    imageLoadObserver: Ember.observer('attrSrc', function () {
      var _this = this;
      if (this.isDestroyed || !this.element) {
        return;
      }
      this.get('hasError', false);
      var attrSrc = this.attrSrc;
      if (this.loadedSrc !== attrSrc) {
        this.set('loadedSrc', null);
        Ember.run.scheduleOnce('afterRender', function () {
          if (_this.isDestroyed) {
            return;
          }
          if (_this.element.complete) {
            _this.set('loadedSrc', attrSrc);
          } else {
            (0, _jquery.default)(_this.element).one('load', function () {
              return Ember.run(function () {
                if (_this.isDestroyed) {
                  return;
                }
                if (attrSrc === _this.attrSrc) {
                  _this.set('loadedSrc', attrSrc);
                }
              });
            }).one('error', function () {
              if (_this.isDestroyed) {
                return;
              }
              if (attrSrc === _this.attrSrc) {
                _this.set('hasError', true);
              }
            });
          }
        });
      }
    }),
    didInsertElement: function didInsertElement() {
      this._super.apply(this, arguments);
      this.imageLoadObserver();
    }
  });
});