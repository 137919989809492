define("ln-ember-data-models/models/vdc/text-module-group", ["exports", "ember-data"], function (_exports, _emberData) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var attr = _emberData.default.attr,
    hasMany = _emberData.default.hasMany,
    Model = _emberData.default.Model;

  /**
   * @class Vdc/TextModuleGroupModel
   * @extends DS.Model
   */
  var _default = _exports.default = Model.extend({
    /**
     * @memberof Vdc/TextModuleGroupModel
     * @instance
     *
     * @type {Number}
     */
    version: attr('number'),
    /**
     * @memberof Vdc/TextModuleGroupModel
     * @instance
     *
     * @type {String}
     */
    name: attr('nullable-string'),
    /**
     * @memberof Vdc/TextModuleGroupModel
     * @instance
     *
     * @type {Number}
     */
    project_id: attr('id'),
    /**
     * @memberof Vdc/TextModuleModel
     * @instance
     *
     * @type {Vdc/TextModuleModel[]}
     */
    text_modules: hasMany('vdc/text-module')
  });
});