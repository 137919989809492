define("ln-ember-toolkit/templates/components/svg-icon", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.HTMLBars.template({
    "id": "xWKOxXv7",
    "block": "{\"symbols\":[\"&default\",\"@class\",\"@name\",\"@title\",\"@onClick\",\"&attrs\"],\"statements\":[[7,\"i\",false],[12,\"class\",[29,[\"SvgIcon SvgIcon--\",[23,3,[]],\" \",[23,2,[]]]]],[12,\"title\",[23,4,[]]],[12,\"data-icon\",[23,3,[]]],[12,\"onclick\",[23,5,[]]],[13,6],[8],[0,\"\\n  \"],[1,[28,\"svg-jar\",[[23,3,[]]],null],false],[0,\"\\n\"],[4,\"if\",[[25,1]],null,{\"statements\":[[0,\"    \"],[14,1],[0,\"\\n\"]],\"parameters\":[]},null],[9],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "ln-ember-toolkit/templates/components/svg-icon.hbs"
    }
  });
});