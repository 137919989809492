define("ln-ember-data-models/models/vdc/market", ["exports", "ember-data"], function (_exports, _emberData) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var attr = _emberData.default.attr,
    belongsTo = _emberData.default.belongsTo,
    hasMany = _emberData.default.hasMany,
    Model = _emberData.default.Model;

  /**
   * @class Vdc/MarketModel
   * @extends DS.Model
   */
  var _default = _exports.default = Model.extend({
    /**
     * @memberof Vdc/MarketModel
     * @instance
     *
     * @type {Number}
     */
    version: attr('number'),
    /**
     * @memberof Vdc/MarketModel
     * @instance
     *
     * @type {String}
     */
    name: attr('nullable-string'),
    /**
     * @memberof Vdc/MarketModel
     * @instance
     *
     * @type {String}
     */
    number: attr('nullable-string'),
    /**
     * @memberof Vdc/MarketModel
     * @instance
     *
     * @type {Number}
     */
    client_id: attr('id'),
    /**
     * @memberof Vdc/MarketModel
     * @instance
     *
     * @type {Number[]}
     */
    country_ids: attr('number-array', {
      defaultValue: function defaultValue() {
        return [];
      }
    }),
    /**
     * @memberof Vdc/MarketModel
     * @instance
     *
     * @type {Number}
     */
    longitude: attr('number'),
    /**
     * @memberof Vdc/MarketModel
     * @instance
     *
     * @type {Number}
     */
    latitude: attr('number'),
    /**
     * @memberof Vdc/MarketModel
     * @instance
     *
     * @type {Vdc/Country[]}
     */
    countries: hasMany('vdc/country', {
      async: true
    }),
    /**
     * @memberof Vdc/MarketModel
     * @instance
     *
     * @type {Vdc/Contact}
     */
    contact: belongsTo('vdc/contact', {
      async: false
    }),
    init: function init() {
      var options = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
      this._super.apply(this, arguments);

      // Whenever this resource gets requested, all following strings inside this array will be embedded
      // Check application adapter implementation and tests for more details
      this.defaultEmbeds = typeof options.defaultEmbeds !== 'undefined' ? options.defaultEmbeds : ['picture'];
    }
  });
});