define("ln-ember-form-elements/components/form-elements/upload-button", ["exports", "ln-ember-form-elements/templates/components/form-elements/upload-button", "jquery"], function (_exports, _uploadButton, _jquery) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  /**
   * Renders an upload button. <br>
   *
   * ### Example:
   *
   * ```Handlebars
   * {{form-elements/upload-button
   *     resource=uploadResource
   *     onProgress=(action "uploadProgress")
   *     onCompleted=(action "uploadCompleted")
   *     multiple=true
   * }}
   * ```
   * @class formElements/UploadButtonComponent
   * @extends @ember/component
   */
  var _default = _exports.default = Ember.Component.extend({
    /**
     *  api service injection for uploading files
     *
     * @memberof formElements/UploadButtonComponent
     * @instance
     *
     * @private
     * @type object
     */
    api: Ember.inject.service(),
    layout: _uploadButton.default,
    classNames: [
    // Deprecated: { id: ln-ember-form-elements-class-names, until: 3.0.0 }
    'upload-button-component', 'form-elements--upload-button-component'],
    classNameBindings: ['disabled'],
    /**
     * is the button disabled
     *
     * @memberof formElements/UploadButtonComponent
     * @instance
     *
     * @type {boolean}
     * @default false
     */
    disabled: false,
    /**
     * multi or single file.
     *
     * @memberof formElements/UploadButtonComponent
     * @instance
     *
     * @type {boolean}
     * @default true
     */
    multiple: true,
    /**
     *  resource to map the uploaded file.
     *
     * @memberof formElements/UploadButtonComponent
     * @instance
     *
     * @type {object}
     * @default null
     */
    resource: null,
    /**
     *
     * total Count of files to upload.
     *
     * @memberof formElements/UploadButtonComponent
     * @instance
     *
     * @type {number}
     * @default 0
     */
    totalCount: 0,
    /**
     * files progressed (uploaded/ failed)
     *
     * @memberof formElements/UploadButtonComponent
     * @instance
     *
     * @type {number}
     * @default 0
     */
    progressCount: 0,
    openDialogWhenRendered: false,
    /**
     * Additional form data which will be passed to the request body.
     *
     * @memberof formElements/UploadButtonComponent
     * @instance
     *
     * @type {object}
     * @default null
     */
    formData: null,
    /**
     * event triggered for progress bar handling
     * if null, then no listener is attached in api service
     *
     * @memberof formElements/UploadButtonComponent
     * @instance
     *
     * @type {null|function}
     * @default null
     */
    onSingleProgress: null,
    /**
     * Maximum single file size that can be sent
     *
     * @memberof formElements/UploadButtonComponent
     * @instance
     *
     * @type {null|number}
     * @default null
     */
    maxFileSize: null,
    /**
     * called when a file exceeds the maximum size
     *
     * @memberof formElements/UploadButtonComponent
     * @instance
     *
     * @type {function}
     */
    onFileSizeExceeded: function onFileSizeExceeded() {},
    /**
     * event to trigger on upload progress
     *
     * @memberof formElements/UploadButtonComponent
     * @instance
     *
     * @type {function}
     */
    onProgress: function onProgress() {},
    /**
     * event to trigger on upload completed
     *
     * @memberof formElements/UploadButtonComponent
     * @instance
     *
     * @type {function}
     * @param {object} status
     * @param {boolean} status.success
     * @param {boolean} status.failure
     * @param {number} status.total
     */
    onCompleted: function onCompleted() {},
    /**
     * event to trigger when file was chosen but before upload started
     *
     * @memberof formElements/UploadButtonComponent
     * @instance
     *
     * @type {function}
     * @param {FileList} fileList List of files to upload
     */
    onBeforeUpload: function onBeforeUpload() {},
    /**
     * event to trigger when file was chosen and request was started
     *
     * @memberof formElements/UploadButtonComponent
     * @instance
     *
     * @type {function}
     */
    onRequestStarted: function onRequestStarted() {},
    didInsertElement: function didInsertElement() {
      if (this.get('openDialogWhenRendered')) {
        (0, _jquery.default)('input', this.element).click();
      }
    },
    actions: {
      upload: function upload(eventArgs) {
        var _this = this;
        var files = this.fileListToArray(Ember.get(eventArgs, 'target.files'));
        var filteredFiles = this.validateFileSize(files);
        var count = Ember.get(filteredFiles, 'length');
        this.set('totalCount', count);
        this.set('doneCount', 0);
        if (count > 0) {
          Ember.RSVP.resolve(this.get('onBeforeUpload')(filteredFiles))
          // Bindings are not synced when not doing it with Ember.run.next
          .then(function () {
            return new Ember.RSVP.Promise(function (resolve) {
              return Ember.run.next(function () {
                return resolve();
              });
            });
          }).then(function () {
            return Ember.RSVP.all(filteredFiles.map(function (file) {
              return _this.uploadFile(file);
            }));
          }).then(function (result) {
            return _this.sendCompleted(result);
          });
        }
      }
    },
    /**
     * extract file objects FileList object
     *
     * @memberof formElements/UploadButtonComponent
     * @instance
     *
     * @private
     * @param {FileList} fileList
     * @returns {Array} fileArray
     */
    fileListToArray: function fileListToArray(fileList) {
      var filesArray = [];
      for (var key in fileList) {
        if (!isNaN(parseInt(key))) {
          filesArray.push(fileList[key]);
        }
      }
      return filesArray;
    },
    /**
     * trigger progress event
     *
     * @memberof formElements/UploadButtonComponent
     * @instance
     *
     * @private
     */
    sendProgress: function sendProgress() {
      if (this.get('isDestroyed')) {
        return;
      }
      this.set('progressCount', this.get('progressCount') + 1);
      this.get('onProgress')({
        progress: this.get('progressCount'),
        total: this.get('totalCount')
      });
    },
    /**
     * trigger completed event
     *
     * @memberof formElements/UploadButtonComponent
     * @instance
     *
     * @private
     *
     * @param {array} results
     */
    sendCompleted: function sendCompleted(results) {
      this.get('onCompleted')({
        success: results.filter(function (item) {
          return Ember.get(item, 'status') === 1;
        }),
        failure: results.filter(function (item) {
          return Ember.get(item, 'status') === 0;
        }),
        total: this.get('totalCount')
      });
      (0, _jquery.default)('input', this.element).val('');
      this.set('totalCount', 0);
      this.set('progressCount', 0);
    },
    /**
     * upload single file to the api
     *
     * @memberof formElements/UploadButtonComponent
     * @instance
     *
     * @private
     * @param {object} file
     * @returns {Promise}
     */
    uploadFile: function uploadFile(file) {
      var _this2 = this;
      var resource = this.get('resource');
      var additionalFormData = this.get('formData') || {};
      var formData = Ember.assign({
        file: file
      }, additionalFormData);
      var options = {
        contentType: 'multipart/form-data',
        progress: this.get('onSingleProgress')
      };
      var request = this.get('api').create(resource.namespace, resource.uri, formData, options);
      this.onRequestStarted(request);
      return request.then(function (result) {
        _this2.sendProgress();
        var isError = Array.isArray(result.errors) && result.errors.length;
        return {
          file: file,
          result: result,
          status: isError ? 0 : 1
        };
      }).catch(function (error) {
        _this2.sendProgress();
        return {
          file: file,
          error: error,
          status: 0
        };
      });
    },
    validateFileSize: function validateFileSize(files) {
      var _this3 = this;
      var maxFileSize = this.get('maxFileSize');
      if (!maxFileSize) {
        return files;
      }
      return files.filter(function (file) {
        var isSizeOk = file.size < maxFileSize;
        if (!isSizeOk) {
          _this3.onFileSizeExceeded(file, maxFileSize);
        }
        return isSizeOk;
      });
    }
  });
});