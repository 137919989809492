define("@ember-data/canary-features/index", ["exports", "@ember-data/canary-features/default-features"], function (_exports, _defaultFeatures) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.SAMPLE_FEATURE_FLAG = _exports.RECORD_DATA_STATE = _exports.RECORD_DATA_ERRORS = _exports.FEATURES = void 0;
  function _typeof(o) { "@babel/helpers - typeof"; return _typeof = "function" == typeof Symbol && "symbol" == typeof Symbol.iterator ? function (o) { return typeof o; } : function (o) { return o && "function" == typeof Symbol && o.constructor === Symbol && o !== Symbol.prototype ? "symbol" : typeof o; }, _typeof(o); }
  var ENV = (typeof EmberDataENV === "undefined" ? "undefined" : _typeof(EmberDataENV)) === 'object' && EmberDataENV !== null ? EmberDataENV : {};
  function featureValue(value) {
    if (ENV.ENABLE_OPTIONAL_FEATURES && value === null) {
      return true;
    }
    return value;
  }
  var FEATURES = _exports.FEATURES = Ember.assign({}, _defaultFeatures.default, ENV.FEATURES);
  var SAMPLE_FEATURE_FLAG = _exports.SAMPLE_FEATURE_FLAG = featureValue(FEATURES.SAMPLE_FEATURE_FLAG);
  var RECORD_DATA_ERRORS = _exports.RECORD_DATA_ERRORS = featureValue(FEATURES.RECORD_DATA_ERRORS);
  var RECORD_DATA_STATE = _exports.RECORD_DATA_STATE = featureValue(FEATURES.RECORD_DATA_STATE);
});