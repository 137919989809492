define("ln-ember-form-elements/components/flash-messages/flash-messages", ["exports", "ln-ember-form-elements/templates/components/flash-messages/flash-messages"], function (_exports, _flashMessages) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  /**
   * Shows flash messages that are added to the `FlashMessagesService`. It should
   * be added to the `application.hbs` of the app.
   *
   * ### Example:
   *
   * `application.hbs`:
   *
   * ```Handlebars
   * {{flash-messages/flash-messages}}
   * ```
   *
   * @class flashMessages/FlashMessagesComponent
   * @extends @ember/component
   *
   * @see FlashMessagesService
   */
  var _default = _exports.default = Ember.Component.extend({
    flashMessages: Ember.inject.service(),
    layout: _flashMessages.default,
    classNames: [
    // Deprecated: { id: ln-ember-form-elements-class-names, until: 3.0.0 }
    'flash-messages-component', 'flash-messages--flash-messages-component'],
    _messageClones: null,
    messages: Ember.computed.alias('flashMessages.messages'),
    visibleMessages: Ember.computed('messages.[]', function () {
      var messageClones = Ember.A(this.get('_messageClones'));
      var messages = Ember.A(this.get('messages'));
      messageClones.setEach('visible', false);
      messages.forEach(function (message) {
        var clone = messageClones.findBy('id', Ember.get(message, 'id'));
        if (!clone) {
          messageClones.addObject(Ember.assign({
            visible: true,
            height: null,
            original: message
          }, message));
        } else {
          Ember.set(clone, 'visible', true);
        }
      });
      return messageClones;
    }),
    init: function init() {
      this._super.apply(this, arguments);
      this.set('_messageClones', []);
    },
    actions: {
      setMessageHeight: function setMessageHeight(message, height) {
        var messageClones = Ember.A(this.get('_messageClones'));
        var clone = messageClones.findBy('id', Ember.get(message, 'id'));
        if (clone) {
          Ember.set(clone, 'height', height);
          return;
        }
      },
      removeMessageHeight: function removeMessageHeight(message) {
        var messageClones = Ember.A(this.get('_messageClones'));
        var clone = messageClones.findBy('id', Ember.get(message, 'id'));
        messageClones.removeObject(clone);
      },
      removeMessage: function removeMessage(message, removeAll) {
        if (removeAll) {
          return this.get('flashMessages').removeAllMessages();
        }
        return this.get('flashMessages').removeMessage(message.original);
      }
    }
  });
});