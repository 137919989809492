define("ln-ember-form-elements/templates/components/form-elements/upload-button", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.HTMLBars.template({
    "id": "clD80OCs",
    "block": "{\"symbols\":[\"&default\"],\"statements\":[[7,\"input\",true],[11,\"onchange\",[28,\"action\",[[23,0,[]],\"upload\"],null]],[11,\"disabled\",[23,0,[\"disabled\"]]],[11,\"multiple\",[23,0,[\"multiple\"]]],[10,\"type\",\"file\"],[8],[9],[0,\"\\n\"],[14,1]],\"hasEval\":false}",
    "meta": {
      "moduleName": "ln-ember-form-elements/templates/components/form-elements/upload-button.hbs"
    }
  });
});