define("ln-ember-toolkit/serializers/ln-rest", ["exports", "ember-data/serializers/rest", "ember-inflector", "ln-ember-toolkit/models/versioned-model"], function (_exports, _rest, _emberInflector, _versionedModel) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  function _typeof(o) { "@babel/helpers - typeof"; return _typeof = "function" == typeof Symbol && "symbol" == typeof Symbol.iterator ? function (o) { return typeof o; } : function (o) { return o && "function" == typeof Symbol && o.constructor === Symbol && o !== Symbol.prototype ? "symbol" : typeof o; }, _typeof(o); }
  function _defineProperty(e, r, t) { return (r = _toPropertyKey(r)) in e ? Object.defineProperty(e, r, { value: t, enumerable: !0, configurable: !0, writable: !0 }) : e[r] = t, e; }
  function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == _typeof(i) ? i : i + ""; }
  function _toPrimitive(t, r) { if ("object" != _typeof(t) || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != _typeof(i)) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
  var SIDELOADED_KEY = '__include';
  var _default = _exports.default = _rest.default.extend({
    _isEmbeddedRelationship: function _isEmbeddedRelationship(key) {
      var attrs = this.attrs || {};
      var embeddedRecordsKeys = Object.keys(attrs).filter(function (key) {
        return attrs[key].serialize === 'records' || attrs[key].embedded === 'always';
      });
      return embeddedRecordsKeys.includes(key);
    },
    serializeIntoHash: function serializeIntoHash(hash, _typeClass, snapshot, options) {
      // get rid of root key for save requests
      // @see: https://ligadigital.atlassian.net/browse/EX30-386
      Object.assign(hash, this.serialize(snapshot, options));
    },
    serialize: function serialize(snapshot, options) {
      var _this = this;
      // eslint-disable-next-line prefer-rest-params
      var json = this._super.apply(this, arguments);
      if (options && options.patch) {
        var keysToInclude = Object.keys(snapshot.changedAttributes()).map(this.keyForAttribute);
        keysToInclude.push('version');
        snapshot.eachRelationship(function (key, relationshipMeta) {
          if (_this.shouldIncludeRelationshipIntoPatch(snapshot, key, relationshipMeta.kind)) {
            keysToInclude.push(_this.keyForRelationship(key, relationshipMeta.kind, 'serialize'));
          }
        });
        Object.keys(json).forEach(function (jsonKey) {
          if (!keysToInclude.includes(jsonKey)) {
            delete json[jsonKey];
          }
        });
      }
      return json;
    },
    shouldIncludeEmbeddedIntoPatch: function shouldIncludeEmbeddedIntoPatch(snapshot, key, kind) {
      if (kind === 'belongsTo') {
        var relationship = snapshot.belongsTo(key);
        if (relationship) {
          var serialized = relationship.serialize({
            patch: true
          });
          return Object.keys(serialized).filter(function (k) {
            return k !== 'version';
          }).length > 0;
        }
      }
      if (kind === 'hasMany') {
        var relationships = snapshot.hasMany(key);
        if (relationships) {
          var serializedArray = relationships.map(function (rel) {
            return rel.serialize({
              patch: true
            });
          });
          var firstNonEmpty = serializedArray.find(function (serialized) {
            return Object.keys(serialized).filter(function (k) {
              return k !== 'version';
            }).length > 0;
          });
          return !!firstNonEmpty;
        }
      }
      return false;
    },
    shouldIncludeRelationshipIntoPatch: function shouldIncludeRelationshipIntoPatch(snapshot, key, kind) {
      if ((0, _versionedModel.isVersionedModel)(snapshot.record) && snapshot.record.isRelationshipDirty(key)) {
        return true;
      }
      if (this._isEmbeddedRelationship(key)) {
        return this.shouldIncludeEmbeddedIntoPatch(snapshot, key, kind);
      }
      return false;
    },
    normalizeArrayResponse: function normalizeArrayResponse(store, primaryModelClass, payload, id, requestType) {
      var meta = Ember.assign(payload.meta, {
        summary: payload.summary || {}
      });
      if (typeof meta.count !== 'undefined') {
        meta.result_count = meta.count;
      } else if (typeof meta.result_count !== 'undefined') {
        meta.count = meta.result_count;
      }
      var res = _defineProperty({
        meta: meta
      }, this.typeKey(primaryModelClass), payload.result);
      return this._super(store, primaryModelClass, res, id, requestType);
    },
    normalizeSingleResponse: function normalizeSingleResponse(store, primaryModelClass, payload, id, requestType) {
      var sideloaded = {};
      if (payload && payload[SIDELOADED_KEY]) {
        Object.keys(payload[SIDELOADED_KEY]).forEach(function (k) {
          // Ember REST Serializer treats all the underscorder properties
          // as a sideloaded data collections which are pushed to the store
          sideloaded["_".concat(k)] = payload[SIDELOADED_KEY][k];
        });
        delete payload[SIDELOADED_KEY];
      }
      payload = Object.assign(_defineProperty({
        meta: payload && payload.meta || {}
      }, this.typeKey(primaryModelClass), payload), sideloaded);
      return this._super(store, primaryModelClass, payload, id, requestType);
    },
    typeKey: function typeKey(typeClass) {
      return (0, _emberInflector.pluralize)(typeClass.modelName);
    },
    serializeBelongsTo: function serializeBelongsTo(snapshot, json, relationship) {
      this._super(snapshot, json, relationship);
      var key = this.keyForRelationship(relationship.key, relationship.kind);
      if (typeof json[key] === 'string') {
        // Sometimes API requires integer ids
        // if it can't be converted to a number, let's assume it's an UUID
        // and leave it as is
        json[key] = isNaN(Number(json[key])) ? json[key] : Number(json[key]);
      }
    },
    keyForRelationship: function keyForRelationship(key, relationshipKind) {
      key = Ember.String.underscore(key);
      if (relationshipKind === 'belongsTo') {
        return "".concat(key, "_id");
      } else if (relationshipKind === 'hasMany') {
        return "".concat((0, _emberInflector.singularize)(key), "_ids");
      } else {
        return key;
      }
    },
    /**
     * Converts camelCased attributes to underscored when serializing.
     * @method keyForAttribute
     * @param {String} attr
     * @return {String}
     */
    keyForAttribute: function keyForAttribute(attr) {
      return Ember.String.underscore(attr);
    }
  });
});