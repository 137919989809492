define("ln-ember-data-models/models/vdc/task", ["exports", "ember-data", "ln-ember-data-models/utils/enum-option"], function (_exports, _emberData, _enumOption) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.typeOptions = _exports.statusOptions = _exports.default = void 0;
  var ONE_DAY = 1000 * 60 * 60 * 24;
  var attr = _emberData.default.attr,
    belongsTo = _emberData.default.belongsTo,
    Model = _emberData.default.Model;

  /**
   * @memberof Vdc/TaskModel/statusOptions
   * @type {EnumOption[]}
   */
  var statusOptions = _exports.statusOptions = [_enumOption.default.create({
    id: 'Open',
    prefix: 'task.status',
    isOpen: true
  }), _enumOption.default.create({
    id: 'Denied',
    prefix: 'task.status',
    isOpen: false
  }), _enumOption.default.create({
    id: 'Done',
    prefix: 'task.status',
    isOpen: false
  }), _enumOption.default.create({
    id: 'Deferred',
    prefix: 'task.status',
    isOpen: false
  })];

  /**
   * @name Vdc/TaskModel/typeOptions
   * @type {EnumOption[]}
   */
  var typeOptions = _exports.typeOptions = [_enumOption.default.create({
    id: 'Task',
    prefix: 'task.type'
  }), _enumOption.default.create({
    id: 'Milestone',
    prefix: 'task.type'
  })];

  /**
   * @class Vdc/TaskModel
   * @extends DS.Model
   */
  var TaskModel = Model.extend({
    /**
     * @memberof Vdc/TaskModel
     * @instance
     *
     * @type {Number}
     */
    version: attr('number'),
    /**
     * @memberof Vdc/TaskModel
     * @instance
     *
     * @type {Number}
     */
    position: attr('number'),
    /**
     * @memberof Vdc/TaskModel
     * @instance
     *
     * @type {Number}
     */
    ancestor_id: attr('id'),
    /**
     * @memberof Vdc/TaskModel
     * @instance
     *
     * @type {Number}
     */
    author_id: attr('id'),
    /**
     * @memberof Vdc/TaskModel
     * @instance
     *
     * @type {String}
     */
    description: attr('nullable-string'),
    /**
     * @memberof Vdc/TaskModel
     * @instance
     *
     * @type {String}
     */
    end_date: attr('date-string'),
    /**
     * @memberof Vdc/TaskModel
     * @instance
     *
     * @type {Array}
     * @default []
     */
    files: attr({
      defaultValue: function defaultValue() {
        return [];
      }
    }),
    /**
     * @memberof Vdc/TaskModel
     * @instance
     *
     * @type {String}
     */
    name: attr('nullable-string'),
    /**
     * @memberof Vdc/TaskModel
     * @instance
     *
     * @type {String}
     */
    service_external_id: attr('nullable-string'),
    //eslint-disable-line

    /**
     * @memberof Vdc/TaskModel
     * @instance
     *
     * @type {Number}
     */
    context_id: attr('id'),
    /**
     * @memberof Vdc/TaskModel
     * @instance
     *
     * @type {String}
     */
    context_name: attr('nullable-string'),
    /**
     * @memberof Vdc/TaskModel
     * @instance
     *
     * @type {String}
     */
    context_type: attr('nullable-string'),
    /**
     * @memberof Vdc/TaskModel
     * @instance
     *
     * @type {Number}
     */
    role_id: attr('id', {
      defaultValue: null
    }),
    /**
     * @memberof Vdc/TaskModel
     * @instance
     *
     * @type {Object}
     */
    role: attr({
      defaultValue: null
    }),
    /**
     * @memberof Vdc/TaskModel
     * @instance
     *
     * @type {String}
     */
    start_date: attr('date-string'),
    /**
     * @memberof Vdc/TaskModel
     * @instance
     *
     * @type {String}
     * @default "Open"
     */
    status: attr('enum-string', {
      options: statusOptions,
      defaultValue: 'Open'
    }),
    /**
     * @memberof Vdc/TaskModel
     * @instance
     *
     * @type {Number}
     */
    estimated_effort: attr('number', {
      defaultValue: 0
    }),
    /**
     * @memberof Vdc/TaskModel
     * @instance
     *
     * @type {Number}
     */
    duration: attr('number', {
      defaultValue: 0
    }),
    /**
     * @memberof Vdc/TaskModel
     * @instance
     *
     * @type {String}
     */
    type: attr('enum-string', {
      options: typeOptions,
      defaultValue: 'Task'
    }),
    /**
     * @memberof Vdc/TaskModel
     * @instance
     *
     * @type {Number}
     */
    user_id: attr('id'),
    /**
     * @memberof Vdc/TaskModel
     * @instance
     *
     * @type {Array}
     */
    visible_to_role_ids: attr('number-array', {
      defaultValue: function defaultValue() {
        return [];
      }
    }),
    /**
     * @memberof Vdc/TaskModel
     * @instance
     *
     * @type {Number}
     */
    work_package_id: attr('id'),
    /**
     * @memberof Vdc/TaskModel
     * @instance
     *
     * @type {String}
     */
    work_package_name: attr('nullable-string'),
    /**
     * @memberof Vdc/TaskModel
     * @instance
     *
     * @type {Vdc/UserModel}
     */
    author: belongsTo('vdc/user'),
    /**
     * @memberof Vdc/TaskModel
     * @instance
     *
     * @type {Vdc/UserModel}
     */
    user: belongsTo('vdc/user'),
    /**
     * @memberof Vdc/TaskModel
     * @instance
     *
     * @type {Boolean}
     */
    isMilestone: Ember.computed.equal('type', 'Milestone'),
    /**
     * @memberof Vdc/TaskModel
     * @instance
     *
     * @type {Boolean}
     */
    isTask: Ember.computed.equal('type', 'Task'),
    /**
     * @memberof Vdc/TaskModel
     * @instance
     *
     * @type {Boolean}
     */
    isOpen: Ember.computed.alias('statusOption.isOpen'),
    /**
     * @memberof Vdc/TaskModel
     * @instance
     *
    * @type {Object}
    */
    statusOption: Ember.computed('status', function () {
      return statusOptions.findBy('id', this.get('status') || '');
    }),
    /**
     * **Critical**: Tasks is overdue.
     *
     * => traffic signal: **red**
     *
     * @memberof Vdc/TaskModel
     * @instance
     *
     * @type {Boolean}
     */
    isCritical: Ember.computed('isOpen', 'daysOverdue', function () {
      return this.get('isOpen') && this.get('daysOverdue') > 0;
    }),
    /**
     * **Notable**: Tasks has to be completed within the next 3 days
     * recorded.
     *
     * => traffic signal: **yellow**
     *
     * @memberof Vdc/TaskModel
     * @instance
     *
     * @type {Boolean}
     */
    isNotable: Ember.computed('isOpen', 'daysLeft', 'daysOverdue', function () {
      return this.get('isOpen') && this.get('daysLeft') < 3 && this.get('daysOverdue') === 0;
    }),
    /**
     * **OK**: All good! More than 3 days left to complete the task
     *
     * => traffic signal: **green**
     *
     * @memberof Vdc/TaskModel
     * @instance
     *
     * @type {Boolean}
     */
    isOk: Ember.computed('isOpen', 'daysLeft', function () {
      return !this.get('isOpen') || this.get('daysLeft') >= 3;
    }),
    /**
     * @memberof Vdc/TaskModel
     * @instance
     *
     * @type {Number}
     */
    daysLeft: Ember.computed('end_date', function () {
      if (!this.get('end_date')) {
        return 0;
      }
      var today = new Date();
      today.setHours(0, 0, 0, 0);
      var date = new Date(this.get('end_date'));
      date.setHours(0, 0, 0, 0);
      return Math.max(0, Math.round((date.getTime() - today.getTime()) / ONE_DAY));
    }),
    /**
     * @memberof Vdc/TaskModel
     * @instance
     *
     * @type {Number}
     */
    daysOverdue: Ember.computed('end_date', function () {
      if (!this.get('end_date')) {
        return 0;
      }
      var today = new Date();
      today.setHours(0, 0, 0, 0);
      var date = new Date(this.get('end_date'));
      date.setHours(0, 0, 0, 0);
      return Math.max(0, Math.round((today.getTime() - date.getTime()) / ONE_DAY));
    }),
    /**
     * @memberof Vdc/TaskModel
     * @instance
     *
     * @type {Boolean}
     */
    toDoToday: Ember.computed('isOpen', 'daysLeft', 'daysOverdue', function () {
      return this.get('isOpen') && this.get('daysLeft') === 0 && this.get('daysOverdue') === 0;
    })
  });
  TaskModel.reopenClass({
    /**
     * array of options to choose from for the tasks status attribute
     * @memberof Vdc/TaskModel
     * @static
     *
     * @type {Array}
     */
    statusOptions: statusOptions,
    /**
     * array of options to choose from for the tasks type attribute
     * @memberof Vdc/TaskModel
     * @static
     *
     * @type {Array}
     */
    typeOptions: typeOptions
  });
  var _default = _exports.default = TaskModel;
});