define("ln-ember-data-models/models/myliga/fragments/jobprofile-objective-setting", ["exports", "ember-data", "ember-data-model-fragments/fragment", "ln-ember-data-models/utils/enum-option"], function (_exports, _emberData, _fragment, _enumOption) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = _exports.bonusPaymentTypeOptions = void 0;
  var attr = _emberData.default.attr;

  /**
   * @name Myliga/JobprofileModel/bonusPaymentTypeOptions
   * @type {EnumOption[]}
   */
  var bonusPaymentTypeOptions = _exports.bonusPaymentTypeOptions = [_enumOption.default.create({
    id: 'Miles'
  }), _enumOption.default.create({
    id: 'EUR'
  }), _enumOption.default.create({
    id: 'MonthlySalary'
  })];

  /**
   * @class Myliga/JobprofileObjectiveSettingFragment
   * @extends Fragment
   */
  var JobprofileObjectiveSettingFragment = _fragment.default.extend({
    /**
     * @memberof Myliga/JobprofileObjectiveSettingFragment
     * @instance
     *
     * @type {String}
     */
    bonus_payment_type: attr('enum-string', {
      options: bonusPaymentTypeOptions
    }),
    /**
     * @memberof Myliga/JobprofileObjectiveSettingFragment
     * @instance
     *
     * @type {Number}
     */
    max_bonus_payment: attr('number'),
    /**
     * @memberof Myliga/JobprofileObjectiveSettingFragment
     * @instance
     *
     * @type {Boolean}
     */
    objective_enabled: attr('boolean')
  });
  JobprofileObjectiveSettingFragment.reopenClass({
    /**
     * @memberof Myliga/JobprofileModel
     * @static
     *
     * @type {EnumOption[]}
     */
    bonusPaymentTypeOptions: bonusPaymentTypeOptions
  });
  var _default = _exports.default = JobprofileObjectiveSettingFragment;
});