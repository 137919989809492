define("ln-ember-data-models/models/myliga/file", ["exports", "ember-data"], function (_exports, _emberData) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var attr = _emberData.default.attr,
    Model = _emberData.default.Model;

  /**
   * @class Myliga/FileModel
   * @extends DS.Model
   */
  var _default = _exports.default = Model.extend({
    /**
     * @memberof Myliga/FileModel
     * @instance
     *
     * @type {Number}
     */
    version: attr('number'),
    /**
     * @memberof Myliga/FileModel
     * @instance
     *
     * @type {String}
     */
    date_created: attr('date-time-string'),
    /**
     * @memberof Myliga/FileModel
     * @instance
     *
     * @type {String}
     */
    path: attr('nullable-string'),
    /**
     * @memberof Myliga/FileModel
     * @instance
     *
     * @type {String}
     */
    filename: attr('nullable-string'),
    /**
     * @memberof Myliga/FileModel
     * @instance
     *
     * @type {String}
     */
    file_type: attr('nullable-string'),
    /**
     * @memberof Myliga/FileModel
     * @instance
     *
     * @type {Number}
     */
    file_size: attr('number'),
    /**
     * @memberof Myliga/FileModel
     * @instance
     *
     * @type {String}
     */
    mime_type: attr('nullable-string'),
    /**
     * @memberof Myliga/FileModel
     * @instance
     *
     * @type {Number}
     */
    user_id: attr('id'),
    /**
     * @memberof Myliga/FileModel
     * @instance
     *
     * @type {Boolean}
     */
    preview_ready: attr('boolean'),
    /**
     * @memberof Myliga/FileModel
     * @instance
     *
     * @type {String}
     */
    preview_path: attr('nullable-string'),
    /**
     * @memberof Myliga/FileModel
     * @instance
     *
     * @type {Number}
     */
    position: attr('number'),
    /**
     * @memberof Myliga/FileModel
     * @instance
     *
     * @type {Object}
     * @property {Number} id
     * @property {Number} domain_id
     * @property {string} type
     */
    file_container: attr({
      defaultValue: function defaultValue() {
        return {
          domain_id: null,
          id: null,
          type: null
        };
      }
    }),
    fileToUpload: null
  });
});