define("ln-ember-data-models/serializers/vdc/fragments/-fragment-marketing-category", ["exports", "ln-ember-data-models/serializers/application"], function (_exports, _application) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  /**
   * @class Vdc/Fragments/-FragmentMarketingCategorySerializer
   * @extends ApplicationSerializer
   */
  var _default = _exports.default = _application.default.extend({
    extractAttributes: function extractAttributes(modelClass, resourceHash) {
      var _this = this;
      var attributeKey;
      var attributes = {};
      modelClass.eachAttribute(function (key) {
        attributeKey = _this.keyForAttribute(key, 'deserialize');
        if (resourceHash[attributeKey] !== undefined) {
          attributes[key] = resourceHash[attributeKey];
        } else {
          if (attributeKey === 'marketing_category_id') {
            var attribute = resourceHash.marketing_category && resourceHash.marketing_category.id;
            if (attribute !== undefined) {
              attributes[key] = attribute;
            }
          }
        }
      });
      return attributes;
    },
    serialize: function serialize(snapshot, options) {
      var json = this._super(snapshot, options);
      delete json[this.primaryKey];
      return json;
    }
  });
});