define("ln-ember-data-models/adapters/vdc/contract", ["exports", "ln-ember-data-models/adapters/application"], function (_exports, _application) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  /**
   * @class Vdc/StoreAdapter
   * @extends ApplicationAdapter
   */
  var _default = _exports.default = _application.default.extend({
    buildURL: function buildURL(modelName, id, snapshot) {
      var storeId = Ember.get(snapshot.record, 'store_id');
      var url = [this.baseUrlFor(modelName)];
      var path;
      if (modelName) {
        path = "stores/".concat(storeId, "/contracts");
        url.push(path);
      }
      if (id) {
        url.push(encodeURIComponent(id));
      }
      url = url.join('/');
      return url;
    }
  });
});