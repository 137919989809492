define("ln-ember-data-models/models/vdc/vendor", ["exports", "ember-data"], function (_exports, _emberData) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var Model = _emberData.default.Model,
    belongsTo = _emberData.default.belongsTo,
    attr = _emberData.default.attr;

  /**
   * @class Vdc/StoreModel
   * @extends DS.Model
   */
  var _default = _exports.default = Model.extend({
    /**
     * @memberof Vdc/StoreModel
     * @instance
     *
     * @type {Number}
     */
    version: attr('number'),
    /**
     * @memberof Vdc/StoreModel
     * @instance
     *
     * @type {Number}
     */
    client_id: attr('id'),
    /**
     * @memberof Vdc/StoreModel
     * @instance
     *
     * @type {String}
     */
    company_name: attr('nullable-string'),
    /**
     * @memberof Vdc/StoreModel
     * @instance
     *
     * @type {Number}
     */
    address_id: attr('id'),
    /**
     * @memberof Vdc/StoreModel
     * @instance
     *
     * @type {String}
     */
    trades: attr('nullable-string'),
    /**
     * @memberof Vdc/StoreModel
     * @instance
     *
     * @type {String}
     */
    comments: attr('nullable-string'),
    /**
     * @memberof Vdc/StoreModel
     * @instance
     *
     * @type {Vdc/AddressModel}
     */
    address: belongsTo('vdc/address', {
      async: false
    })
  });
});