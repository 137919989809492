define("ln-ember-data-models/adapters/application", ["exports", "ember-data", "ember-inflector"], function (_exports, _emberData, _emberInflector) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  function _typeof(o) { "@babel/helpers - typeof"; return _typeof = "function" == typeof Symbol && "symbol" == typeof Symbol.iterator ? function (o) { return typeof o; } : function (o) { return o && "function" == typeof Symbol && o.constructor === Symbol && o !== Symbol.prototype ? "symbol" : typeof o; }, _typeof(o); }
  function _slicedToArray(r, e) { return _arrayWithHoles(r) || _iterableToArrayLimit(r, e) || _unsupportedIterableToArray(r, e) || _nonIterableRest(); }
  function _nonIterableRest() { throw new TypeError("Invalid attempt to destructure non-iterable instance.\nIn order to be iterable, non-array objects must have a [Symbol.iterator]() method."); }
  function _unsupportedIterableToArray(r, a) { if (r) { if ("string" == typeof r) return _arrayLikeToArray(r, a); var t = {}.toString.call(r).slice(8, -1); return "Object" === t && r.constructor && (t = r.constructor.name), "Map" === t || "Set" === t ? Array.from(r) : "Arguments" === t || /^(?:Ui|I)nt(?:8|16|32)(?:Clamped)?Array$/.test(t) ? _arrayLikeToArray(r, a) : void 0; } }
  function _arrayLikeToArray(r, a) { (null == a || a > r.length) && (a = r.length); for (var e = 0, n = Array(a); e < a; e++) n[e] = r[e]; return n; }
  function _iterableToArrayLimit(r, l) { var t = null == r ? null : "undefined" != typeof Symbol && r[Symbol.iterator] || r["@@iterator"]; if (null != t) { var e, n, i, u, a = [], f = !0, o = !1; try { if (i = (t = t.call(r)).next, 0 === l) { if (Object(t) !== t) return; f = !1; } else for (; !(f = (e = i.call(t)).done) && (a.push(e.value), a.length !== l); f = !0); } catch (r) { o = !0, n = r; } finally { try { if (!f && null != t.return && (u = t.return(), Object(u) !== u)) return; } finally { if (o) throw n; } } return a; } }
  function _arrayWithHoles(r) { if (Array.isArray(r)) return r; }
  var RESTAdapter = _emberData.default.RESTAdapter,
    InvalidError = _emberData.default.InvalidError,
    AdapterError = _emberData.default.AdapterError;

  /**
   * @class ApplicationAdapter
   * @extends DS.ApplicationAdapter
   */
  var _default = _exports.default = RESTAdapter.extend({
    _token: null,
    config: Ember.computed(function () {
      var config = Ember.getOwner(this).resolveRegistration('config:environment');
      return config.APP.config;
    }),
    headers: Ember.computed('_host.clientId', '_token', 'language', 'session.token', function () {
      var headers = {
        Authorization: "Bearer ".concat(this._token),
        'Accept-Language': this.language
      };
      if (this.get('_host.clientId')) {
        headers['Client-Id'] = this.get('_host.clientId');
      }
      return headers;
    }),
    apiEvents: Ember.inject.service(),
    _host: Ember.inject.service('host'),
    init: function init() {
      var _this = this;
      this._super.apply(this, arguments);
      var apiEvents = this.apiEvents;
      if (false === 'loadToken' in this) {
        // the whole api-events pattern is bad.
        // The recommended approach is to inject a "session" service into the ApiService
        // The only responsibility of the "session" service is to provide the token
        (false && !(false) && Ember.deprecate('"loadToken" is not defined', false, {
          id: 'ln-ember-api-service.missing-loadToken',
          until: '6.0.0',
          url: 'https://ligadigital.atlassian.net/browse/LGOS-764'
        }));
        this.set('session', apiEvents.getSession());
        apiEvents.on('session-change', function (session) {
          _this.set('session', session);
        });
      }
      this.set('language', apiEvents.getLanguage());
      apiEvents.on('language-change', function (language) {
        _this.set('language', language);
      });
    },
    // Modeltypes have the form of 'vdc/comment' or 'myliga/comment', but the vdc or myliga part is not included in the api path.
    // So we take out the namespace in here and format the actual modelname for the api
    pathForType: function pathForType(type) {
      (false && !(type.match('^[^/]+/[^/]+$')) && Ember.assert('All models must have a valid namespace: <api>/<resource>', type.match('^[^/]+/[^/]+$')));
      var _type$split = type.split('/'),
        _type$split2 = _slicedToArray(_type$split, 2),
        path = _type$split2[1];
      return (0, _emberInflector.pluralize)(Ember.String.underscore(path));
    },
    handleResponse: function handleResponse(status, headers, payload) {
      if (this.isSuccess(status, headers, payload)) {
        return payload;
      }
      var _this$normalizeErrorR = this.normalizeErrorResponse(status, payload),
        status_code = _this$normalizeErrorR.status_code,
        message = _this$normalizeErrorR.message,
        details = _this$normalizeErrorR.details;
      this.apiEvents.triggerErrorResponse({
        status_code: status_code,
        message: message,
        details: details
      });
      if (status_code === 400) {
        return this._getErrorCodeWithStatus(new InvalidError(details, message), status_code);
      }
      return this._getErrorCodeWithStatus(new AdapterError(details, message), status_code);
    },
    normalizeErrorResponse: function normalizeErrorResponse(status, payload) {
      payload = payload || {
        status_code: status,
        message: 'Unknow error',
        details: []
      };

      // ⚠️ Error 500s in the myliga api are plain text (Content-Type:text/plain)
      if (typeof payload === 'string') {
        return {
          status_code: status,
          message: payload,
          details: []
        };
      }

      // ⚠️ Error responses look diffrent in myliga and vdc
      // - vdc:    `{ status_code, error_code, message, details }`
      // - myliga: `{ status_code, error_message, details }`

      var _payload = payload,
        status_code = _payload.status_code,
        error_message = _payload.error_message,
        message = _payload.message,
        details = _payload.details;

      // (1) Normalize all detailts to { reference, message }
      details = details.map(function (_ref) {
        var reference = _ref.reference,
          message = _ref.message,
          error_message = _ref.error_message;
        return {
          reference: reference && reference.replace(/^obj\./, ''),
          message: message || error_message
        };
      });

      // (2) Normalize the message and add details without a reference to it
      var unreferencedDetails = details.filter(function (_ref2) {
        var reference = _ref2.reference;
        return !reference;
      }).map(function (_ref3) {
        var message = _ref3.message;
        return message;
      });
      message = [message || error_message].concat(unreferencedDetails).join('\n');

      // (3) Remove details without a refernce
      details = details.filter(function (_ref4) {
        var reference = _ref4.reference;
        return reference;
      });
      return {
        status_code: status_code,
        message: message,
        details: details
      };
    },
    buildURL: function buildURL(modelName, id, snapshot, requestType, query) {
      switch (requestType) {
        case 'findRecord':
          return this.urlForFindRecord(id, modelName, snapshot);
        case 'findAll':
          return this.urlForFindAll(modelName, snapshot);
        case 'query':
          return this.urlForQuery(query, modelName, snapshot);
        case 'queryRecord':
          return this.urlForQueryRecord(query, modelName, snapshot);
        case 'findMany':
          return this.urlForFindMany(id, modelName, snapshot);
        case 'findHasMany':
          return this.urlForFindHasMany(id, modelName, snapshot);
        case 'findBelongsTo':
          return this.urlForFindBelongsTo(id, modelName, snapshot);
        case 'createRecord':
          return this.urlForCreateRecord(modelName, snapshot);
        case 'updateRecord':
          return this.urlForUpdateRecord(id, modelName, snapshot);
        case 'deleteRecord':
          return this.urlForDeleteRecord(id, modelName, snapshot);
        default:
          return this._buildURL(modelName, id, null, snapshot);
      }
    },
    urlForFindRecord: function urlForFindRecord(id, modelName, snapshot) {
      return this._buildURL(modelName, id, null, snapshot);
    },
    urlForFindAll: function urlForFindAll(modelName, snapshot) {
      return this._buildURL(modelName, null, null, snapshot);
    },
    urlForQuery: function urlForQuery(query, modelName, snapshot) {
      return this._buildURL(modelName, null, null, snapshot);
    },
    urlForQueryRecord: function urlForQueryRecord(query, modelName, snapshot) {
      return this._buildURL(modelName, null, null, snapshot);
    },
    urlForFindMany: function urlForFindMany(id, modelName, snapshot) {
      return this._buildURL(modelName, id, null, snapshot);
    },
    urlForFindHasMany: function urlForFindHasMany(id, modelName, snapshot) {
      return this._buildURL(modelName, id, null, snapshot);
    },
    urlForFindBelongsTo: function urlForFindBelongsTo(id, modelName, snapshot) {
      return this._buildURL(modelName, id, null, snapshot);
    },
    urlForCreateRecord: function urlForCreateRecord(modelName, snapshot) {
      var transactionId = null;
      if (Ember.get(snapshot, 'adapterOptions.transaction')) {
        transactionId = snapshot.adapterOptions.transaction.get('id');
      }
      return this._buildURL(modelName, null, transactionId, snapshot, false);
    },
    urlForUpdateRecord: function urlForUpdateRecord(id, modelName, snapshot) {
      return this._buildURL(modelName, id, null, snapshot, false);
    },
    urlForDeleteRecord: function urlForDeleteRecord(id, modelName, snapshot) {
      return this._buildURL(modelName, id, null, snapshot, false);
    },
    _buildURL: function _buildURL(modelName, id, transactionId, snapshot) {
      var addDefaultEmbeds = arguments.length > 4 && arguments[4] !== undefined ? arguments[4] : true;
      if (modelName) {
        var path = this.pathForType(modelName);
        if (path) {
          var defaultEmbeds = null;
          if (id) {
            path += "/".concat(encodeURIComponent(id));
          }
          if (snapshot && addDefaultEmbeds) {
            if (snapshot.record) {
              defaultEmbeds = snapshot.record.get('defaultEmbeds');
            } else if (snapshot.snapshots().length > 0) {
              var snapshots = snapshot.snapshots();
              defaultEmbeds = snapshots[0].record.get('defaultEmbeds');
            }
          }
          return this.buildURLFor(path, transactionId, modelName, defaultEmbeds);
        }
      }
    },
    _getErrorCodeWithStatus: function _getErrorCodeWithStatus(error, statusCode) {
      return Ember.assign(error, {
        statusCode: statusCode
      });
    },
    buildURLFor: function buildURLFor(path, transactionId, modelName, defaultEmbeds) {
      var paramString = '';
      if (transactionId || defaultEmbeds) {
        paramString += '?';
      }
      if (transactionId) {
        paramString += "transaction=".concat(transactionId);
      }
      if (defaultEmbeds) {
        defaultEmbeds.forEach(function (item, index) {
          if (index === 0 && !transactionId) {
            paramString += "embed%5B%5D=".concat(item);
          } else {
            paramString += "&embed%5B%5D=".concat(item);
          }
        });
      }
      if (path.indexOf('http') === 0) {
        return path += paramString;
      }
      path = path.replace(/^\//, '');
      var url = [];
      url.push(this.baseUrlFor(modelName));
      url.push(path);
      var urlString = url.join('/');
      urlString += paramString;
      return urlString;
    },
    findHasMany: function findHasMany(store, snapshot, path) {
      var defaultEmbeds = snapshot.record.get('defaultEmbeds');
      return this.ajax(this.buildURLFor(path, null, null, defaultEmbeds), 'GET');
    },
    // Ember Data 2.0 Reload behavior
    shouldReloadRecord: function shouldReloadRecord() {
      return true;
    },
    shouldReloadAll: function shouldReloadAll() {
      return true;
    },
    shouldBackgroundReloadRecord: function shouldBackgroundReloadRecord() {
      return true;
    },
    shouldBackgroundReloadAll: function shouldBackgroundReloadAll() {
      return true;
    },
    baseUrlFor: function baseUrlFor() {
      var modelName = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : '';
      (false && !(modelName.match('^[^/]+/[^/]+$')) && Ember.assert('All models must have a valid namespace: <api>/<resource>', modelName.match('^[^/]+/[^/]+$')));
      var _modelName$split = modelName.split('/'),
        _modelName$split2 = _slicedToArray(_modelName$split, 1),
        api = _modelName$split2[0];
      var _ref5 = this.get("config.api-".concat(api)) === undefined ? {} : this.get("config.api-".concat(api)),
        url = _ref5.url,
        version = _ref5.version;
      (false && !(url && version) && Ember.assert("There is no valid config for \"api-".concat(api, "\" for model \"").concat(modelName, "\""), url && version));
      return "".concat(url, "/").concat(version);
    },
    ajax: function ajax() {
      var _this2 = this;
      for (var _len = arguments.length, args = new Array(_len), _key = 0; _key < _len; _key++) {
        args[_key] = arguments[_key];
      }
      var tokenPromise = Ember.RSVP.resolve('loadToken' in this ? this.loadToken() : this.get('session.token'));
      var _super = this._super.bind(this);
      return tokenPromise.then(function (token) {
        _this2.set('_token', token);
        return _super.apply(void 0, args);
      });
    },
    ajaxOptions: function ajaxOptions(url, type, options) {
      this.modifyAjaxOptions(options);
      return this._super(url, type, options);
    },
    modifyAjaxOptions: function modifyAjaxOptions(options) {
      if (!options) {
        return;
      }
      var data = options.data;
      if (data && _typeof(data) === 'object') {
        Object.keys(data).forEach(function (key) {
          if (Array.isArray(data[key])) {
            data[key] = Ember.A(data[key]).uniq();
          }
        });
      }
      return {
        data: data
      };
    }
  });
});