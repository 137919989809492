define("ln-ember-data-models/models/vdc/fragments/image-dimension", ["exports", "ember-data", "ember-data-model-fragments/fragment"], function (_exports, _emberData, _fragment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var attr = _emberData.default.attr;

  /**
   * @class Vdc/fragments/ImageDimensionFragment
   * @extends Fragment
   */
  var _default = _exports.default = _fragment.default.extend({
    /**
     * @memberof Vdc/fragments/ImageDimensionFragment
     * @instance
     *
     * @type {Number}
     */
    width: attr('number'),
    /**
     * @memberof Vdc/fragments/ImageDimensionFragment
     * @instance
     *
     * @type {Number}
     */
    height: attr('number'),
    /**
     * @memberof Vdc/fragments/ImageDimensionFragment
     * @instance
     *
     * @type {Number}
     */
    ratio: attr('number')
  });
});