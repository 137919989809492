define("ln-ember-toolkit/services/rights", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var PromiseArray = Ember.ArrayProxy.extend(Ember.PromiseProxyMixin);

  /**
   * @class Rights
   * @extends Ember.Service
   */
  var _default = _exports.default = Ember.Service.extend({
    api: Ember.inject.service(),
    rights: Ember.computed(function () {
      var promise = this.api.read('myliga', ['rights', {
        permission: true
      }]).then(function (resp) {
        return resp.result;
      });
      return PromiseArray.create({
        promise: promise
      });
    }),
    /**
     * @memberof Rights
     * @instance
     *
     * @method
     * @param  {String} type
     * @param  {String} resource
     * @return {Ember.RSVP.Promise}
     */
    can: function can(type, resource) {
      return this.rights.then(function (rights) {
        var right = rights.filterBy('type', type).filterBy('resource', resource);
        return right.length > 0;
      });
    },
    /**
     * @memberof Rights
     * @instance
     *
     * @method
     */
    reset: function reset() {
      this.notifyPropertyChange('rights');
    }
  });
});