// Polyfill for Event constructor in IE11, based on CustomEvent polyfill from MDN
// see: https://developer.mozilla.org/en-US/docs/Web/API/CustomEvent/CustomEvent

(function() {
  if (typeof window.Event === 'function') {
    return false;
  }

  function Event(event, params) {
    params = params || { bubbles: false, cancelable: false, detail: undefined };

    const evt = document.createEvent('Event');

    evt.initEvent(event, params.bubbles, params.cancelable, params.detail);

    return evt;
  }

  Event.prototype = window.Event.prototype;

  window.Event = Event;
})();
