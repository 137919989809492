define("ln-ember-data-models/serializers/vdc/fragments/additional-cost", ["exports", "ln-ember-data-models/serializers/application"], function (_exports, _application) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  /**
   * @class Vdc/Fragments/AdditionalCostSerializer
   * @extends ApplicationSerializer
   */
  var VdcFragmentAdditionalCostSerializer = _application.default.extend({
    serialize: function serialize(snapshot, options) {
      var json = this._super(snapshot, options);
      if (!snapshot.attr('quantity')) {
        delete json.quantity;
      }
      return json;
    }
  });
  var _default = _exports.default = VdcFragmentAdditionalCostSerializer;
});