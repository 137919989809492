define("ln-ember-data-models/models/vdc/store-subtype", ["exports", "ember-data", "ln-ember-data-models/utils/store-colors"], function (_exports, _emberData, _storeColors) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var attr = _emberData.default.attr,
    Model = _emberData.default.Model;

  /**
   * @class Vdc/StoreSubtypeModel
   * @extends DS.Model
   */
  var StoreSubtypeModel = Model.extend({
    /**
     * @memberof Vdc/StoreSubtypeModel
     * @instance
     *
     * @type {Number}
     */
    version: attr('number'),
    /**
     * @memberof Vdc/StoreSubtypeModel
     * @instance
     *
     * @type {String}
     */
    name: attr('nullable-string'),
    /**
     * @memberof Vdc/StoreSubtypeModel
     * @instance
     *
     * @type {Number}
     */
    position: attr('number'),
    /**
     * @memberof Vdc/StoreSubtypeModel
     * @instance
     *
     * @type {Number}
     */
    client_id: attr('id'),
    /**
     * @memberof Vdc/StoreSubtypeModel
     * @instance
     *
     * @type {Boolean}
     */
    show_on_map: attr('boolean', {
      defaultValue: function defaultValue() {
        return false;
      }
    }),
    /**
     * @memberof Vdc/StoreSubtypeModel
     * @instance
     *
     * @type {string}
     */
    store_marker_color: attr('enum-string', {
      options: _storeColors.default
    }),
    /**
     * @memberof Vdc/StoreSubtypeModel
     * @instance
     *
     * @type {Boolean}
     */
    show_in_flyout_navigation: attr('boolean', {
      defaultValue: function defaultValue() {
        return false;
      }
    })
  });
  StoreSubtypeModel.reopenClass({
    /**
     * @memberof Vdc/StoreSubtypeModel
     * @instance
     *
     * @type {EnumOption[]}
     */
    StoreColorOptions: _storeColors.default
  });
  var _default = _exports.default = StoreSubtypeModel;
});