define("ln-ember-data-models/models/myliga/personal-jobprofile", ["exports", "ember-data"], function (_exports, _emberData) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var attr = _emberData.default.attr,
    Model = _emberData.default.Model;

  /**
   * @class Myliga/PersonalJobprofileModel
   * @extends DS.Model
   */
  var _default = _exports.default = Model.extend({
    /**
     * @memberof Myliga/PersonalJobprofileModel
     * @instance
     *
     * @type {number}
     */
    version: attr('number'),
    /**
     * @memberof Myliga/PersonalJobprofileModel
     * @instance
     *
     * @type {number}
     */
    user_id: attr('id'),
    /**
     * @memberof Myliga/PersonalJobprofileModel
     * @instance
     *
     * @type {string}
     */
    date_start: attr('date-string'),
    /**
     * @memberof Myliga/PersonalJobprofileModel
     * @instance
     *
     * @type {number}
     */
    job_profile_id: attr('id'),
    /**
     * @memberof Myliga/PersonalJobprofileModel
     * @instance
     *
     * @type {string}
     */
    title: attr('nullable-string'),
    /**
     * @memberof Myliga/PersonalJobprofileModel
     * @instance
     *
     * @type {number}
     */
    employee_id: attr('id', {
      readOnly: true
    }),
    /**
     * @memberof Myliga/PersonalJobprofileModel
     * @instance
     *
     * @type {string}
     */
    time_created: attr('date-time-string', {
      readOnly: true
    }),
    /**
     * @memberof Myliga/PersonalJobprofileModel
     * @instance
     *
     * @type {string}
     */
    time_changed: attr('date-time-string', {
      readOnly: true
    }),
    /**
     * @memberof Myliga/PersonalJobprofileModel
     * @instance
     *
     * @type {number}
     */
    changed_by_user_id: attr('id', {
      readOnly: true
    })
  });
});