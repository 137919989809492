define("ln-ember-toolkit/mixins/resize-events", ["exports", "jquery"], function (_exports, _jquery) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  function triggerDidResize() {
    this.set('isResizing', false);
    this.trigger('didResize');
  }
  function triggerResize() {
    this.set('isResizing', true);
    this.trigger('resize');
  }
  var _default = _exports.default = Ember.Mixin.create({
    didInsertElement: function didInsertElement() {
      this._super.apply(this, arguments);
      this.bindResizing();
    },
    willDestroyElement: function willDestroyElement() {
      this._super.apply(this, arguments);
      this.unbindResizing();
    },
    bindResizing: function bindResizing() {
      var _this = this;
      var elId = this.elementId;
      (0, _jquery.default)(window).on("resize.resize-event-".concat(elId), function () {
        Ember.run.throttle(_this, triggerResize, 100);
        Ember.run.debounce(_this, triggerDidResize, 250, false);
      });
    },
    unbindResizing: function unbindResizing() {
      var elId = this.elementId;
      (0, _jquery.default)(window).off("resize.resize-event-".concat(elId));
    }
  });
});