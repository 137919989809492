define("ln-ember-contact-menu/components/fake-contact-menu", ["exports", "ln-ember-contact-menu/templates/components/fake-contact-menu"], function (_exports, _fakeContactMenu) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.Component.extend({
    layout: _fakeContactMenu.default,
    classNames: ['contact-menu-component', 'is-fake']
  });
});