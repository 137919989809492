define("ln-ember-form-elements/components/form-elements/date-picker", ["exports", "jquery", "moment", "ln-ember-form-elements/templates/components/form-elements/date-picker"], function (_exports, _jquery, _moment, _datePicker) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  function _slicedToArray(r, e) { return _arrayWithHoles(r) || _iterableToArrayLimit(r, e) || _unsupportedIterableToArray(r, e) || _nonIterableRest(); }
  function _nonIterableRest() { throw new TypeError("Invalid attempt to destructure non-iterable instance.\nIn order to be iterable, non-array objects must have a [Symbol.iterator]() method."); }
  function _unsupportedIterableToArray(r, a) { if (r) { if ("string" == typeof r) return _arrayLikeToArray(r, a); var t = {}.toString.call(r).slice(8, -1); return "Object" === t && r.constructor && (t = r.constructor.name), "Map" === t || "Set" === t ? Array.from(r) : "Arguments" === t || /^(?:Ui|I)nt(?:8|16|32)(?:Clamped)?Array$/.test(t) ? _arrayLikeToArray(r, a) : void 0; } }
  function _arrayLikeToArray(r, a) { (null == a || a > r.length) && (a = r.length); for (var e = 0, n = Array(a); e < a; e++) n[e] = r[e]; return n; }
  function _iterableToArrayLimit(r, l) { var t = null == r ? null : "undefined" != typeof Symbol && r[Symbol.iterator] || r["@@iterator"]; if (null != t) { var e, n, i, u, a = [], f = !0, o = !1; try { if (i = (t = t.call(r)).next, 0 === l) { if (Object(t) !== t) return; f = !1; } else for (; !(f = (e = i.call(t)).done) && (a.push(e.value), a.length !== l); f = !0); } catch (r) { o = !0, n = r; } finally { try { if (!f && null != t.return && (u = t.return(), Object(u) !== u)) return; } finally { if (o) throw n; } } return a; } }
  function _arrayWithHoles(r) { if (Array.isArray(r)) return r; }
  /**
   *
   * Renders an input field that opens up a date picker on desktop screens.
   *
   * On mobile devices the native date inputs get used.
   *
   * ### Examples:
   *
   * ```Handlebars
   * {{form-elements/date-picker value="2016-05-19T16:01:41.565+02:00" showTime=false}}
   * ```
   *
   * ```Handlebars
   * {{form-elements/date-picker
   *    value="2016-05-19T16:01:41.565+02:00"
   *    min="2016-05-11T16:01:41.565+02:00"
   *    max="2016-05-25T16:01:41.565+02:00"
   *  }}
   * ```
   *
   * @class formElements/DatePickerComponent
   * @extends @ember/component
   */
  var _default = _exports.default = Ember.Component.extend({
    formElements: Ember.inject.service(),
    layout: _datePicker.default,
    classNames: [
    // Deprecated: { id: ln-ember-form-elements-class-names, until: 3.0.0 }
    'date-picker-component', 'form-elements--date-picker-component'],
    classNameBindings: ['hasFocus', 'hasValue', 'error:has-error', 'isActive:is-active:is-inactive', 'themeClass'],
    /**
     * @memberof formElements/DatePickerComponent
     * @instance
     *
     * @type String
     * @default light
     */
    theme: null,
    /**
     * if you open the datepicker today will be set by default.
     * default is true, for myliga backward compatibilty.
     *
     * @memberof formElements/DatePickerComponent
     * @instance
     *
     * @type Boolean
     * @default true
     */
    setDefaultValueOnOpen: true,
    // TODO What is this? Pickaday theme?
    themeName: 'default',
    picker: null,
    /**
     * Adds an time selection to the datepicker.
     *
     * @memberof formElements/DatePickerComponent
     * @instance
     *
     * @type Boolean
     * @default false
     */
    showTime: false,
    // Automatically show/hide the datepicker on field focus (default true if field is set)
    bound: false,
    // TODO documentation?
    error: null,
    /**
     * Earliest selectable date
     *
     * @memberof formElements/DatePickerComponent
     * @instance
     *
     * @type String
     * @default null
     */
    min: null,
    /**
     * Latest selectable date
     *
     * @memberof formElements/DatePickerComponent
     * @instance
     *
     * @type String
     * @default null
     */
    max: null,
    /**
     * If true sets pikaday's container as this component DOM element.
     * It will cause that pikday calendar will be contained inside this component.
     *
     * @memberof formElements/DatePickerComponent
     * @instance
     *
     * @type Boolean
     * @default false
     */
    componentIsContainer: false,
    /**
     * If `componentIsContainer` is `false`, you can pass an id for DOM element
     * which will be container for pikaday calendar
     * Example: `'custom-container'`
     *
     * @memberof formElements/DatePickerComponent
     * @instance
     *
     * @type String
     * @default null
     */
    customContainerId: null,
    /**
     * The label of the date picker
     *
     * @memberof formElements/DatePickerComponent
     * @instance
     *
     * @type String
     * @default null
     */
    label: null,
    isActive: true,
    /**
     * Activates week numbers in the date picker.
     *
     * @memberof formElements/DatePickerComponent
     * @instance
     *
     * @type Boolean
     * @default true
     */
    showWeekNumber: true,
    inputValue: null,
    inputMobileValue: null,
    hasFocus: false,
    defaultTime: null,
    /**
     * Fired when the input value changes.
     *
     * @memberof formElements/DatePickerComponent
     * @instance
     *
     * @type {function}
     * @default null
     */
    onChange: null,
    /**
     * Placeholdertext for the input field.
     *
     * @memberof formElements/DatePickerComponent
     * @instance
     *
     * @type String
     * @default "Select a date"
     */
    placeholder: Ember.String.loc('Select a date'),
    isDisabled: Ember.computed.not('isActive'),
    /**
     * @instance
     *
     * @type {String}
     * @default 'off'
     */
    autocomplete: 'off',
    hasValue: Ember.computed.bool('date').readOnly(),
    themeClass: Ember.computed('theme', function () {
      return "theme-".concat(this.get('theme') || 'light');
    }),
    mobileInputType: Ember.computed('showTime', function () {
      return this.get('showTime') ? 'datetime-local' : 'date';
    }).readOnly(),
    // Format for the visible input (not mobile)
    dateDisplayFormat: Ember.computed('showTime', function () {
      var key = this.get('showTime') ? 'formatDateTime' : 'formatDate';
      return this.get("formElements.defaults.".concat(key));
    }).readOnly(),
    // Format for value
    dateFormat: Ember.computed('showTime', function () {
      return this.get('showTime') ? 'YYYY-MM-DD[T]HH:mm:ss.SSSZ' : 'YYYY-MM-DD';
    }).readOnly(),
    minDate: Ember.computed('min', function () {
      var min = this.get('min');
      return min ? new Date(min) : null;
    }).readOnly(),
    maxDate: Ember.computed('max', function () {
      var max = this.get('max');
      return max ? new Date(max) : null;
    }).readOnly(),
    // Should only be set from the outside the component via binding
    // Use setDate Instead!
    value: Ember.computed('date', {
      get: function get() {
        return this.format(this.get('date'));
      },
      set: function set(key, dateStr) {
        if (this.get('isInitialized')) {
          var date = this.convertToDate(dateStr);
          (false && !(Boolean(this.get('onChange'))) && Ember.deprecate('[DatePickerComponent] Data binding is deprecated. onChange action should be handled.', Boolean(this.get('onChange')), {
            for: 'ln-ember-form-elements',
            id: 'date-picker-component-data-binding',
            since: '3.0.0-beta.115',
            until: '3.0.0'
          }));
          if (this.get('onChange')) {
            this.get('onChange')(dateStr);
          } else {
            this.set('date', date);
            this.pickerCall('setDate', date, true);
          }
        }
        return dateStr;
      }
    }),
    init: function init() {
      this._super.apply(this, arguments);
      if (!this.get('theme')) {
        this.set('theme', this.get('formElements.defaults.theme'));
      }
      this.set('date', this.convertToDate(this.get('value')));
      if (this.get('date')) {
        this.set('inputMobileValue', this.convertToLocalDate(this.get('date')));
      }
      this.set('isInitialized', true);
    },
    didInsertElement: function didInsertElement() {
      this._super.apply(this, arguments);
      this.setupPikaday();
    },
    didUpdateAttrs: function didUpdateAttrs() {
      this._super.apply(this, arguments);
      var min = this.get('min');
      var max = this.get('max');
      var minDate = min ? new Date(min) : null;
      var maxDate = max ? new Date(max) : null;

      // https://github.com/dbushell/Pikaday/issues/19
      // this enables us to dynamically change the date ranges
      this.get('picker').config({
        minDate: minDate,
        maxDate: maxDate
      });
    },
    willDestroyElement: function willDestroyElement() {
      this.pickerCall('destroy');
    },
    actions: {
      // Open Datepicker, if not already open
      open: function open() {
        if (this.pickerCall('isVisible') !== false) {
          return;
        }
        if (this.get('setDefaultValueOnOpen')) {
          this.ensureHasValue();
        }
        this.pickerCall('show');
      },
      // Close Datepicker, if no time inputs are focused
      close: function close() {
        if (this.pickerCall('isVisible') !== true) {
          return;
        }
        this.validateInput();
        this.closeIfhasNoFocus();
      },
      keyUp: function keyUp() {
        Ember.run.debounce(this, this.validateInput, 1000);
      },
      mobileFocusIn: function mobileFocusIn() {
        this.set('hasFocus', true);
      },
      mobileFocusOut: function mobileFocusOut() {
        this.set('hasFocus', false);
        this.validateMobileInput();
      }
    },
    setDate: function setDate(date) {
      date = this.stripDate(date);
      if (this.isChangedDate(date)) {
        this.set('date', date);
      }
    },
    setupPikaday: function setupPikaday() {
      var _this = this;
      var customContainer = this.get('customContainerId') && (0, _jquery.default)("#".concat(this.get('customContainerId'))).get(0);
      var container = this.get('componentIsContainer') ? this.element : customContainer;
      Ember.run.once('afterRender', function () {
        // See full list of options: https://github.com/owenmead/Pikaday
        _this.set('picker', new Pikaday({
          field: (0, _jquery.default)('input', _this.element).get(0),
          theme: _this.get('themeName'),
          showTime: _this.get('showTime'),
          useTimeInput: true,
          use24hour: true,
          bound: _this.get('bound'),
          container: container,
          showWeekNumber: _this.get('showWeekNumber'),
          firstDay: 1,
          // 0: Sunday, 1: Monday,...

          format: _this.get('dateDisplayFormat'),
          minDate: _this.get('minDate'),
          maxDate: _this.get('maxDate'),
          i18n: {
            previousMonth: Ember.String.loc('Prev'),
            nextMonth: Ember.String.loc('Next'),
            months: ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December'].map(function (m) {
              return Ember.String.loc(m);
            }),
            weekdays: ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'].map(function (d) {
              return Ember.String.loc(d);
            }),
            weekdaysShort: ['Su', 'Mo', 'Tu', 'We', 'Th', 'Fr', 'Sa'].map(function (d) {
              return Ember.String.loc(d);
            })
          },
          onSelect: function onSelect(date) {
            if (!_this.isChangedDate(date)) {
              return;
            }
            var isChangedDateDay = _this.isChangedDateDay(date);
            _this.setDate(date);
            Ember.run.next(function () {
              if (_this.get('showTime') && isChangedDateDay) {
                // New Date Clicked, focus the input
                _this.ensureAnyInputHasFocus();
              }
              _this.send('close');
            });
          },
          onDraw: function onDraw() {
            if (_this.get('showTime')) {
              // When ever Pikaday rerenders assign blur listener to the time inputs
              var pickerEl = _this.get('picker.el');
              (0, _jquery.default)('input.pika-select-minute', pickerEl).on('blur', function () {
                return _this.send('close');
              });
              (0, _jquery.default)('input.pika-select-hour', pickerEl).on('blur', function () {
                return _this.send('close');
              });
            }
          }
        }));
        _this.pickerCall('setDate', _this.get('date'));
        _this.ensureDateIsVisible();
        _this.pickerCall('hide');
      });
    },
    anyInputHasFocus: function anyInputHasFocus() {
      return (0, _jquery.default)(this.element) && (0, _jquery.default)('.pika-select-hour, .pika-select-minute, .date-picker-input', this.element).is(':focus');
    },
    isChangedDate: function isChangedDate(date) {
      return this.get('value') !== this.format(date);
    },
    isValidDateStr: function isValidDateStr(dateStr) {
      var key = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : 'date';
      return (0, _moment.default)(dateStr, this.get("".concat(key, "Format")), true).isValid();
    },
    isValidDate: function isValidDate(date) {
      return date && (0, _moment.default)(date).isValid();
    },
    isChangedDateDay: function isChangedDateDay(date) {
      var lastDay = (0, _moment.default)(this.get('value')).format('YYYY-MM-DD');
      var newDay = (0, _moment.default)(date).format('YYYY-MM-DD');
      return lastDay !== newDay;
    },
    convertToDate: function convertToDate(dateStr) {
      var key = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : 'date';
      if (this.isValidDateStr(dateStr, key)) {
        return (0, _moment.default)(dateStr, this.get("".concat(key, "Format")), true).toDate();
      }
      return null;
    },
    // See http://encosia.com/setting-the-value-of-a-datetime-local-input-with-javascript/
    convertToLocalDate: function convertToLocalDate(currentDate) {
      if (!currentDate) {
        return null;
      }
      if (this.get('showTime')) {
        var timezoneOffset = currentDate.getTimezoneOffset() * 60 * 1000;
        var localDate = new Date(currentDate.getTime() - timezoneOffset);
        var localDateISOString = localDate.toISOString().replace('Z', '');
        return localDateISOString;
      } else {
        var _localDate = (0, _moment.default)(currentDate).format(this.get('formElements.defaults.formatDate'));
        return _localDate;
      }
    },
    format: function format(date) {
      var key = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : 'date';
      if (this.isValidDate(date)) {
        return (0, _moment.default)(date).format(this.get("".concat(key, "Format")));
      }
      return null;
    },
    stripDate: function stripDate(date) {
      return this.convertToDate(this.format(date));
    },
    pickerCall: function pickerCall(func) {
      if (this.get('picker')) {
        var _this$get;
        for (var _len = arguments.length, args = new Array(_len > 1 ? _len - 1 : 0), _key = 1; _key < _len; _key++) {
          args[_key - 1] = arguments[_key];
        }
        return (_this$get = this.get('picker'))[func].apply(_this$get, args);
      }
    },
    ensureAnyInputHasFocus: function ensureAnyInputHasFocus() {
      if (!this.anyInputHasFocus()) {
        (0, _jquery.default)('.date-picker-input', this.element).focus();
      }
    },
    ensureHasValue: function ensureHasValue() {
      if (!this.get('hasValue')) {
        var date = new Date();
        if (this.get('defaultTime')) {
          var _this$get$split = this.get('defaultTime').split(':'),
            _this$get$split2 = _slicedToArray(_this$get$split, 2),
            hours = _this$get$split2[0],
            minutes = _this$get$split2[1];
          date.setHours(hours, minutes);
        }
        this.setDate(date);
      }
    },
    ensureDateIsVisible: function ensureDateIsVisible() {
      var now = (0, _moment.default)();
      var maxDate = this.get('maxDate');
      var minDate = this.get('minDate');
      if (maxDate && now.endOf('day').toDate() > maxDate) {
        this.pickerCall('gotoDate', maxDate);
      } else if (minDate && now.startOf('day').toDate() < minDate) {
        this.pickerCall('gotoDate', minDate);
      }
    },
    validateInput: function validateInput() {
      var _this2 = this;
      Ember.run.once('afterRender', function () {
        var dateStr = _this2.get('inputValue');
        if (!dateStr) {
          _this2.setDate(null);
        } else if (_this2.isValidDateStr(dateStr, 'dateDisplay')) {
          _this2.setDate(_this2.convertToDate(dateStr, 'dateDisplay'));
        } else {
          _this2.set('inputValue', _this2.format(_this2.get('date'), 'dateDisplay'));
        }
      });
    },
    validateMobileInput: function validateMobileInput() {
      var _this3 = this;
      Ember.run.once('afterRender', function () {
        var dateStr = _this3.get('inputMobileValue');
        var date = null;
        if (dateStr) {
          date = new Date(dateStr);
          date.setMinutes(date.getMinutes() + date.getTimezoneOffset());
        }
        _this3.setDate(date);
      });
    },
    closeIfhasNoFocus: function closeIfhasNoFocus() {
      var _this4 = this;
      Ember.run.later(this, function () {
        if (!_this4.anyInputHasFocus()) {
          _this4.pickerCall('hide');
        }
      }, 100);
    }
  });
});