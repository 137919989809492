define("ln-ember-data-models/serializers/vdc/project", ["exports", "ln-ember-data-models/serializers/application"], function (_exports, _application) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  /**
   * @class Vdc/ProjectSerializer
   * @extends ApplicationSerializer
   */
  var _default = _exports.default = _application.default.extend({
    // Serializer's `attr` property is included in `mergedProperties`, so it's not leaking.
    // eslint-disable-next-line ember/avoid-leaking-state-in-ember-objects
    attrs: {
      marketing_objects: {
        deserialize: 'records',
        serialize: 'ids'
      }
    },
    extractAttributes: function extractAttributes() {
      var result = this._super.apply(this, arguments);
      if (result.marketing_categories === null) {
        delete result.marketing_categories;
      }
      return result;
    },
    extractRelationships: function extractRelationships() {
      var result = this._super.apply(this, arguments);
      if (result.marketing_objects && result.marketing_objects.data === null) {
        delete result.marketing_objects;
      }
      return result;
    },
    serializeIntoHash: function serializeIntoHash(hash, typeClass, snapshot, options) {
      this._super.apply(this, arguments);
      var serialized = this.serialize(snapshot, options);
      if (serialized.marketing_objects && serialized.marketing_objects.length) {
        hash.marketing_objects = serialized.marketing_objects.map(Number);
      }
    }
  });
});