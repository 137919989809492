define("ln-ember-form-elements/components/form-elements/date-week-input", ["exports", "moment", "ln-ember-form-elements/components/form-elements/date-input-base", "ln-ember-form-elements/templates/components/form-elements/date-week-input", "ln-ember-form-elements/utils/date-helper"], function (_exports, _moment, _dateInputBase, _dateWeekInput, _dateHelper) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  /**
   * @class formElements/DateWeekInputComponent
   * @extends formElements/DateInputBaseComponent
   */
  var _default = _exports.default = _dateInputBase.default.extend({
    /**
     *
     * ### Example
     *
     * ```
     * {{form-elements/date-week-input weekStatusComponent=(component "week-status")}}
     * ```
     *
     * @memberof formElements/DateWeekInputComponent
     * @instance
     *
     * @type {@ember/component}
     */
    weekStatusComponent: null,
    classNames: ['form-elements--date-week-input-component'],
    layout: _dateWeekInput.default,
    range: Ember.computed('value', function () {
      var value = this.get('value');
      return value ? {
        start: (0, _moment.default)(value).startOf('isoWeek').format('YYYY-MM-DD'),
        end: (0, _moment.default)(value).endOf('isoWeek').format('YYYY-MM-DD')
      } : null;
    }),
    displayValue: Ember.computed('value', 'showDropDown', {
      get: function get() {
        return this.formatDate(this.get('value'));
      },
      set: function set(key, value) {
        return value;
      }
    }),
    actions: {
      onCalendarChange: function onCalendarChange(value) {
        var start = (0, _dateHelper.formatDate)((0, _moment.default)(value).startOf('isoWeek'), 'YYYY-MM-DD');
        this.triggerChange(start);
      },
      onInputChange: function onInputChange(value) {
        if (!value) {
          return this.triggerChange(null);
        }
        var dateFormat = this.get('formElements.defaults.formatDateWeek');
        var date = (0, _moment.default)(value, dateFormat, true);
        if (date.isValid()) {
          this.triggerChange(date.format('YYYY-MM-DD'));
        }
      }
    },
    formatDate: function formatDate(date) {
      return (0, _dateHelper.formatDate)(date, this.get('formElements.defaults.formatDateWeek'));
    },
    getVisibleDate: function getVisibleDate() {
      return (0, _moment.default)(this.get('value') || undefined).format('YYYY-MM-DD');
    },
    isValid: function isValid(value) {
      var date = (0, _moment.default)(value, 'YYYY-MM-DD', true);
      return date.isValid() && date.startOf('isoWeek').isSame(date);
    },
    triggerChange: function triggerChange(range) {
      this.get('onChange')(range);
    }
  });
});