define("ln-ember-data-models/models/vdc/language", ["exports", "ember-data"], function (_exports, _emberData) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var Model = _emberData.default.Model,
    attr = _emberData.default.attr;

  /**
   * @class Vdc/LanguageModel
   * @extends DS.Model
   */
  var _default = _exports.default = Model.extend({
    /**
     * @memberof Vdc/LanguageModel
     * @instance
     *
     * @type {Number}
     */
    version: attr('number'),
    /**
     * @memberof Vdc/LanguageModel
     * @instance
     *
     * @type {String}
     */
    name: attr('nullable-string'),
    /**
     * @memberof Vdc/LanguageModel
     * @instance
     *
     * @type {String}
     */
    iso_code: attr('nullable-string'),
    /**
     * @memberof Vdc/LanguageModel
     * @instance
     *
     * @type {Number}
     */
    number: attr('number'),
    /**
     * @memberof Vdc/LanguageModel
     * @instance
     *
     * @type {String}
     */
    code: Ember.computed.alias('iso_code'),
    /**
     * @memberof Vdc/LanguageModel
     * @instance
     *
     * @type {String}
     */
    fullName: Ember.computed(function () {
      return "".concat(this.get('name'), " (").concat(this.get('iso_code'), ")");
    })
  });
});