define("ln-ember-data-models/adapters/myliga/file", ["exports", "ln-ember-data-models/adapters/application", "ember-inflector"], function (_exports, _application, _emberInflector) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  // TODO Extend and use abstract/SubResourceAdapter
  /**
   * @class Myliga/FileAdapter
   * @extends ApplicationAdapter
   */
  var _default = _exports.default = _application.default.extend({
    api: Ember.inject.service(),
    buildURL: function buildURL(modelName, id, snapshot, requestType, query) {
      var pathOnly = arguments.length > 5 && arguments[5] !== undefined ? arguments[5] : false;
      var url = [];
      if (snapshot) {
        url.push((0, _emberInflector.pluralize)(snapshot.attr('file_container').type).toLowerCase());
        url.push(snapshot.attr('file_container').domain_id);
      }
      if (query && query.for && query.forId) {
        url.push((0, _emberInflector.pluralize)(Ember.get(query, 'for')));
        url.push(Ember.get(query, 'forId'));
        delete query.for;
        delete query.forId;
      }
      url.push('files');
      if (id) {
        url.push(encodeURIComponent(id));
      }
      if (!pathOnly) {
        url.unshift(this.baseUrlFor(modelName));
      }
      return url.join('/');
    },
    createRecord: function createRecord(store, type, snapshot) {
      if (snapshot.record && snapshot.record.fileToUpload) {
        var url = this.buildURL(type.modelName, null, snapshot, 'createRecord', null, true);
        var dataObject = {
          position: snapshot.attr('position'),
          file: snapshot.record.fileToUpload
        };
        return this.get('api').create('myliga', url, dataObject, {
          contentType: 'multipart/form-data'
        });
      } else {
        return this._super(store, type, snapshot);
      }
    }
  });
});