define("ln-ember-data-models/adapters/abstract/myliga/sub-resource", ["exports", "ember-data", "ln-ember-data-models/adapters/application", "ember-inflector"], function (_exports, _emberData, _application, _emberInflector) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  // TODO add tests
  /**
   * @class abstract/SubResourceAdapter
   * @extends ApplicationAdapter
   */
  var _default = _exports.default = _application.default.extend({
    resourcePath: null,
    parentResource: null,
    api: Ember.inject.service(),
    buildURL: function buildURL(modelName, id, snapshot, requestType, query) {
      var pathOnly = arguments.length > 5 && arguments[5] !== undefined ? arguments[5] : false;
      var resource = this.get('resourcePath') || this.pathForType(modelName);
      var parentResource = this.get('parentResource');
      var parentIdProp = "".concat(parentResource, "_id");
      (false && !(parentResource) && Ember.assert('[abstract/SubResourceAdapter] parentResource needs to be set in subclass.', parentResource));
      var url = [];
      var prefix = this.urlPrefix();
      if (prefix) {
        url.push(prefix);
      }
      if (snapshot) {
        url.push((0, _emberInflector.pluralize)(parentResource));
        url.push(snapshot.attr(parentIdProp));
      }
      url.push((0, _emberInflector.pluralize)(resource));
      if (id) {
        url.push(encodeURIComponent(id));
      }
      var urlString = url.join('/').replace(/^\/*/, '/');
      if (pathOnly) {
        return urlString;
      }
      return "".concat(this.baseUrlFor(modelName)).concat(urlString);
    }
  });
});