define("ember-tooltips/templates/components/lazy-render-wrapper", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.HTMLBars.template({
    "id": "tANy9ArD",
    "block": "{\"symbols\":[\"&default\"],\"statements\":[[4,\"if\",[[24,[\"_shouldRender\"]]],null,{\"statements\":[[0,\"  \"],[7,\"div\",true],[8],[0,\"\\n\"],[4,\"component\",[[24,[\"tetherComponentName\"]]],[[\"passedPropertiesObject\",\"class\",\"id\",\"classNames\",\"_shouldShowOnRender\",\"_isInProcessOfShowing\",\"_shouldTargetGrandparentElement\"],[[24,[\"passedPropertiesObject\"]],[24,[\"class\"]],[24,[\"id\"]],[24,[\"classNames\"]],[24,[\"_shouldShowOnRender\"]],[24,[\"_isInProcessOfShowing\"]],[24,[\"_shouldTargetGrandparentElement\"]]]],{\"statements\":[[4,\"if\",[[25,1]],null,{\"statements\":[[0,\"        \"],[14,1,[[28,\"hash\",null,[[\"hide\"],[[28,\"action\",[[23,0,[]],\"hide\"],null]]]]]],[0,\"\\n\"]],\"parameters\":[]},{\"statements\":[[0,\"        \"],[1,[22,\"text\"],false],[0,\"\\n\"]],\"parameters\":[]}]],\"parameters\":[]},null],[0,\"  \"],[9],[0,\"\\n\"]],\"parameters\":[]},null],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "ember-tooltips/templates/components/lazy-render-wrapper.hbs"
    }
  });
});