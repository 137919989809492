define("ln-ember-form-elements/templates/components/form-elements/dropdown-button/dropdown", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.HTMLBars.template({
    "id": "FOFM3RoK",
    "block": "{\"symbols\":[\"&default\"],\"statements\":[[14,1]],\"hasEval\":false}",
    "meta": {
      "moduleName": "ln-ember-form-elements/templates/components/form-elements/dropdown-button/dropdown.hbs"
    }
  });
});