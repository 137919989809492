define("ln-ember-data-models/serializers/application", ["exports", "ember-data", "ember-inflector"], function (_exports, _emberData, _emberInflector) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  function _typeof(o) { "@babel/helpers - typeof"; return _typeof = "function" == typeof Symbol && "symbol" == typeof Symbol.iterator ? function (o) { return typeof o; } : function (o) { return o && "function" == typeof Symbol && o.constructor === Symbol && o !== Symbol.prototype ? "symbol" : typeof o; }, _typeof(o); }
  function _slicedToArray(r, e) { return _arrayWithHoles(r) || _iterableToArrayLimit(r, e) || _unsupportedIterableToArray(r, e) || _nonIterableRest(); }
  function _nonIterableRest() { throw new TypeError("Invalid attempt to destructure non-iterable instance.\nIn order to be iterable, non-array objects must have a [Symbol.iterator]() method."); }
  function _unsupportedIterableToArray(r, a) { if (r) { if ("string" == typeof r) return _arrayLikeToArray(r, a); var t = {}.toString.call(r).slice(8, -1); return "Object" === t && r.constructor && (t = r.constructor.name), "Map" === t || "Set" === t ? Array.from(r) : "Arguments" === t || /^(?:Ui|I)nt(?:8|16|32)(?:Clamped)?Array$/.test(t) ? _arrayLikeToArray(r, a) : void 0; } }
  function _arrayLikeToArray(r, a) { (null == a || a > r.length) && (a = r.length); for (var e = 0, n = Array(a); e < a; e++) n[e] = r[e]; return n; }
  function _iterableToArrayLimit(r, l) { var t = null == r ? null : "undefined" != typeof Symbol && r[Symbol.iterator] || r["@@iterator"]; if (null != t) { var e, n, i, u, a = [], f = !0, o = !1; try { if (i = (t = t.call(r)).next, 0 === l) { if (Object(t) !== t) return; f = !1; } else for (; !(f = (e = i.call(t)).done) && (a.push(e.value), a.length !== l); f = !0); } catch (r) { o = !0, n = r; } finally { try { if (!f && null != t.return && (u = t.return(), Object(u) !== u)) return; } finally { if (o) throw n; } } return a; } }
  function _arrayWithHoles(r) { if (Array.isArray(r)) return r; }
  function _defineProperty(e, r, t) { return (r = _toPropertyKey(r)) in e ? Object.defineProperty(e, r, { value: t, enumerable: !0, configurable: !0, writable: !0 }) : e[r] = t, e; }
  function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == _typeof(i) ? i : i + ""; }
  function _toPrimitive(t, r) { if ("object" != _typeof(t) || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != _typeof(i)) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
  var RESTSerializer = _emberData.default.RESTSerializer,
    EmbeddedRecordsMixin = _emberData.default.EmbeddedRecordsMixin;

  /**
   * @class ApplicationSerializer
   * @extends DS.RESTSerializer
   */
  var _default = _exports.default = RESTSerializer.extend(EmbeddedRecordsMixin, {
    config: Ember.computed(function () {
      var config = Ember.getOwner(this).resolveRegistration('config:environment');
      return config.APP.config;
    }),
    isNewSerializerAPI: true,
    normalizeArrayResponse: function normalizeArrayResponse(store, typeClass, payload) {
      var meta = Ember.assign(payload.meta, {
        summary: payload.summary || {}
      });
      if (typeof meta.count !== 'undefined') {
        meta.result_count = meta.count;
      } else if (typeof meta.result_count !== 'undefined') {
        meta.count = meta.result_count;
      }
      var normalized = _defineProperty({
        meta: meta
      }, this.typeKey(typeClass), payload.result);
      return this._super(store, typeClass, normalized);
    },
    normalizeSingleResponse: function normalizeSingleResponse(store, typeClass, payload, id, requestType) {
      payload = _defineProperty({
        meta: payload.meta || {}
      }, this.typeKey(typeClass), payload);
      return this._super(store, typeClass, payload, id, requestType);
    },
    normalizeDeleteRecordResponse: function normalizeDeleteRecordResponse() {
      return {
        meta: []
      };
    } /* store, primaryModelClass, payload, id, requestType */,
    typeKey: function typeKey(typeClass) {
      return (0, _emberInflector.pluralize)(typeClass.modelName);
    },
    serializeIntoHash: function serializeIntoHash(hash, typeClass, snapshot, options) {
      var serialized = this.serialize(snapshot, options);
      Object.keys(serialized).forEach(function (key) {
        if (Ember.get(typeClass, 'relationshipNames.hasMany').includes(key)) {
          hash[key] = null;
          return;
        }
        if (Ember.get(typeClass, 'relationshipNames.belongsTo').includes(key)) {
          hash[key] = null;
          return;
        }
        hash[key] = serialized[key];
      });
    },
    serialize: function serialize(snapshot, options) {
      var json = this._super(snapshot, options);
      json[Ember.get(this, 'primaryKey')] = Number(snapshot.id);
      return json;
    },
    extractErrors: function extractErrors(store, typeClass, payload) {
      return (payload.errors || []).filterBy('reference').reduce(function (out, _ref) {
        var reference = _ref.reference,
          message = _ref.message;
        out[reference] = message;
        return out;
      }, {});
    },
    extractRelationships: function extractRelationships(modelClass, resourceHash) {
      var relationships = {};
      modelClass.eachRelationship(function (key, relationshipMeta) {
        var relationship = null;
        if (relationshipMeta.kind === 'hasMany' && !resourceHash[key]) {
          var related = this.buildRelationshipLink(key, relationshipMeta, modelClass, resourceHash);
          if (related) {
            relationship = {
              links: {
                related: related
              }
            };
          }
        } else if (relationshipMeta.kind === 'hasMany') {
          relationship = {
            data: resourceHash[key]
          };
        } else if (relationshipMeta.kind === 'belongsTo') {
          relationship = {
            data: resourceHash[key]
          };
        }
        if (relationship) {
          relationships[key] = relationship;
        }
      }, this);
      return relationships;
    },
    buildRelationshipLink: function buildRelationshipLink(key, relationshipMeta, modelClass, resourceHash) {
      var modelName = modelClass.modelName;
      var baseUrl = this.baseUrlFor(modelName);
      var routelink = Ember.get(resourceHash, "links.".concat(key));
      if (routelink) {
        return baseUrl + routelink;
      }
      var linkKey = this.keyForLink(key, relationshipMeta.kind);
      var contextType = Ember.get(relationshipMeta, 'options.contextType');
      if (contextType) {
        return "".concat(baseUrl, "/").concat(linkKey, "?context_type=").concat(contextType, "&context=").concat(resourceHash.id);
      }
      if (key === 'files' || key === 'participants' || key === 'likes' || key === 'contracts') {
        var resource = this.pathForType(modelName);
        return "".concat(baseUrl, "/").concat(resource, "/").concat(resourceHash.id, "/").concat(linkKey);
      }
      var _modelClass$modelName = modelClass.modelName.split('/'),
        _modelClass$modelName2 = _slicedToArray(_modelClass$modelName, 2),
        actualModelName = _modelClass$modelName2[1];
      return "".concat(baseUrl, "/").concat(linkKey, "?").concat(actualModelName, "=").concat(resourceHash.id);
    },
    pathForType: function pathForType(type) {
      (false && !(type.match('^[^/]+/[^/]+$')) && Ember.assert('All models must have a valid namespace: <api>/<resource>', type.match('^[^/]+/[^/]+$')));
      var _type$split = type.split('/'),
        _type$split2 = _slicedToArray(_type$split, 2),
        path = _type$split2[1];
      return (0, _emberInflector.pluralize)(Ember.String.underscore(path));
    },
    baseUrlFor: function baseUrlFor() {
      var modelName = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : '';
      (false && !(modelName.match('^[^/]+/[^/]+$')) && Ember.assert('All models must have a valid namespace: <api>/<resource>', modelName.match('^[^/]+/[^/]+$')));
      var _modelName$split = modelName.split('/'),
        _modelName$split2 = _slicedToArray(_modelName$split, 1),
        api = _modelName$split2[0];
      var _this$get = this.get("config.api-".concat(api)),
        url = _this$get.url,
        version = _this$get.version;
      (false && !(url && version) && Ember.assert("There is no valid config for \"api-".concat(api, "\" for model \"").concat(modelName, "\""), url && version));
      return "".concat(url, "/").concat(version);
    },
    /**
     * If an attribute is readonly, it won't be serialized.
     * Usage: In the model set for example:
     * ```
     *   name: attr('string', { readOnly: true })
     * ```
     *
     * @param {DS.Snapshot} snapshot
     * @param {Object} json
     * @param {String} key
     * @param {Object} attribute
     */
    serializeAttribute: function serializeAttribute(snapshot, json, key, attribute) {
      var isReadOnly = Ember.get(attribute, 'options.readOnly') === undefined ? false : Ember.get(attribute, 'options.readOnly');
      if (isReadOnly) {
        json[key] = null;
        return;
      }
      this._super.apply(this, arguments);
    },
    /**
     * If an hasMany attribute is readonly, it won't be serialized.
     * Usage: In the model set for example:
     * ```
     *   name: hasMany('className', { readOnly: true })
     * ```
     *
     * @param {DS.Snapshot} snapshot
     * @param {Object} json
     * @param {Object} relationship
     */
    serializeHasMany: function serializeHasMany(snapshot, json, relationship) {
      var isReadOnly = Ember.get(relationship, 'options.readOnly') === undefined ? false : Ember.get(relationship, 'options.readOnly');
      if (isReadOnly) {
        json[relationship.key] = null;
        return;
      }
      this._super.apply(this, arguments);
    },
    /**
     * If an belongsTo attribute is readonly, it won't be serialized.
     * Usage: In the model set for example:
     * ```
     *   name: belongsTo('className', { readOnly: true })
     * ```
     *
     * @param {DS.Snapshot} snapshot
     * @param {Object} json
     * @param {Object} relationship
     */
    serializeBelongsTo: function serializeBelongsTo(snapshot, json, relationship) {
      var isReadOnly = Ember.get(relationship, 'options.readOnly') === undefined ? true : Ember.get(relationship, 'options.readOnly');
      if (isReadOnly) {
        json[relationship.key] = null;
        return;
      }
      this._super.apply(this, arguments);
    },
    /**
     * Overwrites the requirement for the relationship to be explicitly stated.
     * https://github.com/emberjs/data/blob/v2.15.3/addon/serializers/embedded-records-mixin.js#L527
     * @param {*} attr
     * @returns {Object}
     */
    attrsOption: function attrsOption(attr) {
      return this._super(attr) || {
        serialize: 'records',
        deserialize: 'records'
      };
    }
  });
});