define("ln-ember-form-elements/utils/weak-find", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = weakFind;
  /**
   * Checks if the array includes the val regarding datatype differences.
   *
   * @param {Array} array  array to check
   * @param {Object|String|Number} val value to be checked
   * @param {?String} property property to save
   * @returns {Boolean}
   */
  function weakFind(array, val) {
    var property = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : null;
    return property ? array.find(function (item) {
      return String(Ember.get(item, property)) === String(Ember.get(val, property) || val);
    }) : array.find(function (item) {
      return String(item) === String(val);
    });
  }
});