define("ln-ember-form-elements/templates/components/form-elements/dropdown-button/button", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.HTMLBars.template({
    "id": "cdHX3+Pv",
    "block": "{\"symbols\":[\"@isOpen\",\"&default\",\"@hasDropdown\",\"@loading\",\"@disabled\",\"@toggleDropdown\",\"@action\"],\"statements\":[[4,\"form-elements/styled-button\",null,[[\"action\",\"theme\",\"disabled\",\"loading\"],[[28,\"unless\",[[23,3,[]],[23,7,[]],[23,6,[]]],null],[28,\"if\",[[23,3,[]],\"light-highlight\",\"light\"],null],[23,5,[]],[23,4,[]]]],{\"statements\":[[0,\"  \"],[14,2],[0,\"\\n\"],[4,\"if\",[[23,3,[]]],null,{\"statements\":[[0,\"    \"],[7,\"span\",true],[11,\"class\",[29,[\"dropdown-arrow \",[28,\"if\",[[23,1,[]],\"up\"],null]]]],[8],[9],[0,\"\\n\"]],\"parameters\":[]},null]],\"parameters\":[]},null]],\"hasEval\":false}",
    "meta": {
      "moduleName": "ln-ember-form-elements/templates/components/form-elements/dropdown-button/button.hbs"
    }
  });
});