define('ln-ember-guide-tooltips/services/guide-tooltips', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var Service = Ember.Service,
      computed = Ember.computed,
      _Ember$run = Ember.run,
      once = _Ember$run.once,
      _next = _Ember$run.next,
      $ = Ember.$,
      Evented = Ember.Evented,
      ObjectProxy = Ember.ObjectProxy;
  exports.default = Service.extend(Evented, {

    step: 0,

    guidesConfig: null,

    stepObjects: computed('guidesConfig.@each.show', 'step', function () {
      var configs = this.get('guidesConfig');
      var step = this.get('step');

      return (configs || []).map(function (config) {
        config.set('isCurrent', step === config.get('step') && config.get('show'));
        return config;
      });
    }),

    setGuides: function setGuides(guidesConfig, label) {
      this._removeEventListeners();

      this.set('step', 0);
      this.set('label', label);
      var configObjects = (guidesConfig || []).map(function (config, index) {
        return ObjectProxy.create({
          isCurrent: index === 0,
          content: config
        });
      });

      this.set('guidesConfig', configObjects);

      this._addEventListeners();
    },
    next: function next(config) {
      var step = config.get('step');
      var allInCurrentStep = (this.get('guidesConfig') || []).filterBy('step', step);

      config.set('done', true);
      if (allInCurrentStep.length > 1 && allInCurrentStep.isAny('done', false)) {
        return;
      }

      once(this, this.incrementProperty, 'step');
      _next(this, this._checkIfFinished);
    },
    _addEventListeners: function _addEventListeners() {
      var _this = this;

      var guidesConfig = this.get('guidesConfig');

      (guidesConfig || []).forEach(function (config) {
        $(config.get('target')).on(config.get('nextOn') + '.guideTooltips', function () {
          if (_this.get('step') === config.get('step')) {
            _this.next(config);
          }
        });
      });
    },
    _removeEventListeners: function _removeEventListeners() {
      var guidesConfig = this.get('guidesConfig');

      (guidesConfig || []).forEach(function (config) {
        $(config.get('target')).off(config.get('nextOn') + '.guideTooltips');
      });
    },
    _checkIfFinished: function _checkIfFinished() {
      var step = this.get('step');
      var guidesConfig = this.get('guidesConfig');

      var seen = {};
      var uniqSteps = guidesConfig.filter(function (config) {
        if (config.step in seen) {
          return false;
        }

        seen[config.step] = true;
        return true;
      });

      if (step <= uniqSteps.length) {
        return false;
      }

      this.trigger('guides-finished', this.get('label'));
      this._removeEventListeners();
      return true;
    }
  });
});