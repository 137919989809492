define("ln-ember-data-models/models/vdc/fixture-catalog", ["exports", "ember-data", "ember-data-model-fragments/attributes"], function (_exports, _emberData, _attributes) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var attr = _emberData.default.attr,
    belongsTo = _emberData.default.belongsTo,
    Model = _emberData.default.Model;

  /**
   * @class Vdc/FixtureCatalogModel
   * @extends DS.Model
   */
  var _default = _exports.default = Model.extend({
    /**
     * @memberof Vdc/FixtureCatalogModel
     * @instance
     *
     * @type {Number}
     */
    version: attr('number'),
    /**
     * @memberof Vdc/FixtureCatalogModel
     * @instance
     *
     * @type {String}
     */
    name: attr('nullable-string'),
    /**
     * @memberof Vdc/FixtureCatalogModel
     * @instance
     *
     * @type {Number}
     */
    client_id: attr('id'),
    /**
     * @memberof Vdc/FixtureCatalogModel
     * @instance
     *
     * @type {Number}
     */
    fixture_type_id: attr('id'),
    /**
     * @memberof Vdc/FixtureCatalogModel
     * @instance
     *
     * @type {String}
     */
    fixture_type_name: attr('nullable-string'),
    /**
     * @memberof Vdc/FixtureCatalogModel
     * @instance
     *
     * @type {Vdc/AttributeSetModel}
     */
    attribute_set: belongsTo('vdc/attribute-set'),
    /**
     * @memberof Vdc/FixtureCatalogModel
     * @instance
     *
     * @type {Vdc/PictureModel}
     */
    picture: belongsTo('vdc/picture', {
      async: true
    }),
    /**
     * @memberof Vdc/FixtureCatalogModel
     * @instance
     *
     * @type {Vdc/PictureModel}
     */
    preview_picture: belongsTo('vdc/picture'),
    /**
     * @memberof Vdc/FixtureCatalogModel
     * @instance
     *
     * @type {Vdc/fragments/AttributeValueFragment[]}
     */
    attribute_values: (0, _attributes.fragmentArray)('vdc/fragments/attribute-value')
  });
});