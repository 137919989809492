define("ln-ember-data-models/models/myliga/absence", ["exports", "ember-data", "ln-ember-data-models/utils/enum-option", "ember-data-model-fragments/attributes"], function (_exports, _emberData, _enumOption, _attributes) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.typeOptions = _exports.default = void 0;
  var attr = _emberData.default.attr,
    Model = _emberData.default.Model;

  /**
   * @memberof Myliga/AbsenceModel/typeOptions
   * @type {EnumOption[]}
   */
  var typeOptions = _exports.typeOptions = [_enumOption.default.create({
    id: 'Holiday'
  }), _enumOption.default.create({
    id: 'Sickness'
  }), _enumOption.default.create({
    id: 'Compensation'
  }), _enumOption.default.create({
    id: 'SpecialLeave'
  })];

  /**
   * @class Myliga/AbsenceModel
   * @extends DS.Model
   */
  var AbsenceModel = Model.extend({
    /**
     * @memberof Myliga/AbsenceModel
     * @instance
     *
     * @type {Number}
     */
    version: attr('number'),
    /**
     * @memberof Myliga/AbsenceModel
     * @instance
     *
     * @type {Number}
     */
    user_id: attr('id'),
    /**
     * @memberof Myliga/AbsenceModel
     * @instance
     *
     * @type {String}
     */
    date_from: attr('date-string'),
    /**
     * @memberof Myliga/AbsenceModel
     * @instance
     *
     * @type {String}
     */
    date_till: attr('date-string'),
    /**
     * @memberof Myliga/AbsenceModel
     * @instance
     *
     * @type {String}
     */
    type: attr('enum-string', {
      options: typeOptions
    }),
    /**
     * @memberof Myliga/AbsenceModel
     * @instance
     *
     * @type {Boolean}
     */
    from_full_day: attr('boolean', {
      defaultValue: true
    }),
    /**
     * @memberof Myliga/AbsenceModel
     * @instance
     *
     * @type {Boolean}
     */
    till_full_day: attr('boolean', {
      defaultValue: true
    }),
    /**
     * @memberof Myliga/AbsenceModel
     * @instance
     *
     * @type {String}
     */
    reason: attr('nullable-string'),
    /**
     * @memberof Myliga/AbsenceModel
     * @instance
     *
     * @type {MyLiga/fragments/UserMeta}
     */
    user_meta: (0, _attributes.fragment)('myliga/fragments/user-meta'),
    /**
     * Return wether a given date is full day absence or not.
     *
     * This is defined by `from_full_day` and `from_full_day`, all days in between
     * are always full days.
     *
     * @memberof Myliga/AbsenceModel
     * @instance
     *
     * @param {String} date - In the format `YYYY-MM-DD`
     * @return {Boolean}
     */
    isFullDay: function isFullDay(date) {
      if (date === this.get('date_from')) {
        return this.get('from_full_day');
      }
      if (date === this.get('date_till')) {
        return this.get('till_full_day');
      }
      return true;
    }
  });
  AbsenceModel.reopenClass({
    /**
     * @memberof Myliga/AbsenceModel
     * @static
     *
     * @type {EnumOption[]}
     */
    typeOptions: typeOptions
  });
  var _default = _exports.default = AbsenceModel;
});