define('ln-ember-guide-tooltips/utils/guide-config', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var EmberObject = Ember.Object,
      assert = Ember.assert,
      computed = Ember.computed;
  exports.default = EmberObject.extend({

    nextOn: 'mouseenter',

    side: 'left',

    isVisible: true,

    done: false,

    show: computed('isVisible', 'done', function () {
      return this.get('isVisible') && !this.get('done');
    }),

    init: function init() {
      assert('A target has to be set for a guide tooltip', this.get('target'));
      assert('A content has to be set for a guide tooltip', this.get('content'));
    }
  });
});