define("ln-ember-data-models/models/vdc/store", ["exports", "ember-data", "ember-data-model-fragments/attributes", "ln-ember-data-models/utils/enum-option"], function (_exports, _emberData, _attributes, _enumOption) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.storeStatuses = _exports.default = _exports.assemblyTargetTypes = _exports.aggregatedAssembliesStatuses = void 0;
  function _slicedToArray(r, e) { return _arrayWithHoles(r) || _iterableToArrayLimit(r, e) || _unsupportedIterableToArray(r, e) || _nonIterableRest(); }
  function _nonIterableRest() { throw new TypeError("Invalid attempt to destructure non-iterable instance.\nIn order to be iterable, non-array objects must have a [Symbol.iterator]() method."); }
  function _unsupportedIterableToArray(r, a) { if (r) { if ("string" == typeof r) return _arrayLikeToArray(r, a); var t = {}.toString.call(r).slice(8, -1); return "Object" === t && r.constructor && (t = r.constructor.name), "Map" === t || "Set" === t ? Array.from(r) : "Arguments" === t || /^(?:Ui|I)nt(?:8|16|32)(?:Clamped)?Array$/.test(t) ? _arrayLikeToArray(r, a) : void 0; } }
  function _arrayLikeToArray(r, a) { (null == a || a > r.length) && (a = r.length); for (var e = 0, n = Array(a); e < a; e++) n[e] = r[e]; return n; }
  function _iterableToArrayLimit(r, l) { var t = null == r ? null : "undefined" != typeof Symbol && r[Symbol.iterator] || r["@@iterator"]; if (null != t) { var e, n, i, u, a = [], f = !0, o = !1; try { if (i = (t = t.call(r)).next, 0 === l) { if (Object(t) !== t) return; f = !1; } else for (; !(f = (e = i.call(t)).done) && (a.push(e.value), a.length !== l); f = !0); } catch (r) { o = !0, n = r; } finally { try { if (!f && null != t.return && (u = t.return(), Object(u) !== u)) return; } finally { if (o) throw n; } } return a; } }
  function _arrayWithHoles(r) { if (Array.isArray(r)) return r; }
  var Model = _emberData.default.Model,
    belongsTo = _emberData.default.belongsTo,
    attr = _emberData.default.attr,
    hasMany = _emberData.default.hasMany;

  /**
   * @name Vdc/StoreModel/aggregatedAssembliesStatuses
   * @type {EnumOption[]}
   */
  var aggregatedAssembliesStatuses = _exports.aggregatedAssembliesStatuses = [_enumOption.default.create({
    id: 'NotAssembled'
  }), _enumOption.default.create({
    id: 'Assembled'
  }), _enumOption.default.create({
    id: 'Approved'
  }), _enumOption.default.create({
    id: 'NotApproved'
  }), _enumOption.default.create({
    id: 'Estimated'
  }), _enumOption.default.create({
    id: 'AdaptWithComments'
  }), _enumOption.default.create({
    id: 'Revised'
  }), _enumOption.default.create({
    id: 'Ordered'
  }), _enumOption.default.create({
    id: 'Proposal'
  })];

  /**
   * @name Vdc/StoreModel/assemblyTargetTypes
   * @type {EnumOption[]}
   */
  var assemblyTargetTypes = _exports.assemblyTargetTypes = [_enumOption.default.create({
    id: 'Fixture'
  }), _enumOption.default.create({
    id: 'Store'
  }), _enumOption.default.create({
    id: 'Window'
  })];

  /**
   * @name Vdc/StoreModel/storeStatuses
   * @type {EnumOption[]}
   */
  var storeStatuses = _exports.storeStatuses = [{
    id: 'closed',
    name: 'Closed'
  }, {
    id: 'in_progress',
    name: 'In Progress'
  }, {
    id: 'opened',
    name: 'Open'
  }];

  /**
   * @class Vdc/StoreModel
   * @extends DS.Model
   */
  var StoreModel = Model.extend({
    /**
     * @memberof Vdc/StoreModel
     * @instance
     *
     * @type {Number}
     */
    version: attr('number'),
    /**
     * @memberof Vdc/StoreModel
     * @instance
     *
     * @type {String}
     */
    name: attr('nullable-string'),
    /**
     * @memberof Vdc/StoreModel
     * @instance
     *
     * @type {Number}
     */
    store_type_id: attr('id'),
    /**
     * @memberof Vdc/StoreModel
     * @instance
     *
     * @type {Number}
     */
    store_subtype_id: attr('id'),
    /**
     * @memberof Vdc/StoreModel
     * @instance
     *
     * @type {String}
     */
    store_type_name: attr('nullable-string'),
    /**
     * @memberof Vdc/StoreModel
     * @instance
     *
     * @type {String}
     */
    store_subtype_name: attr('nullable-string'),
    /**
     * @memberof Vdc/StoreModel
     * @instance
     *
     * @type {String}
     */
    opening_date: attr('date-string'),
    /**
     * @memberof Vdc/StoreModel
     * @instance
     *
     * @type {String}
     */
    closing_date: attr('date-string'),
    /**
     * @memberof Vdc/StoreModel
     * @instance
     *
     * @type {Number}
     */
    country_id: attr('id'),
    /**
     * @memberof Vdc/StoreModel
     * @instance
     *
     * @type {String}
     */
    number: attr('nullable-string'),
    /**
     * @memberof Vdc/StoreModel
     * @instance
     *
     * @type {Number}
     */
    client_id: attr('id'),
    /**
     * @memberof Vdc/StoreModel
     * @instance
     *
     * @type {transforms/number-array}
     */
    language_ids: attr('number-array', {
      defaultValue: function defaultValue() {
        return [];
      }
    }),
    /**
     * @memberof Vdc/StoreModel
     * @instance
     *
     * @type {String}
     */
    delivery_number: attr('nullable-string'),
    /**
     * @memberof Vdc/StoreModel
     * @instance
     *
     * @type {String}
     */
    last_pos_update: attr('nullable-string'),
    /**
     * @memberof Vdc/StoreModel
     * @instance
     *
     * @type {String}
     */
    last_pos_update_completed: attr('nullable-string'),
    /**
     * @memberof Vdc/StoreModel
     * @instance
     *
     * @type {EnumString}
     */
    status: attr('enum-string', {
      options: storeStatuses
    }),
    /**
     * @memberof Vdc/StoreModel
     * @instance
     *
     * @type {Boolean}
     */
    default_address_is_delivery_address: attr('boolean', {
      defaultValue: true
    }),
    /**
     * @memberof Vdc/StoreModel
     * @instance
     *
     * @type {Boolean}
     */
    default_address_is_invoice_address: attr('boolean', {
      defaultValue: true
    }),
    /**
     * @memberof Vdc/StoreModel
     * @instance
     *
     * @type {Number}
     */
    default_address_id: attr('id'),
    /**
     * @memberof Vdc/StoreModel
     * @instance
     *
     * @type { Number }
     */
    delivery_address_id: attr('id'),
    /**
     * @memberof Vdc/StoreModel
     * @instance
     *
     * @type { Number }
     */
    invoice_address_id: attr('id'),
    /**
     * @memberof Vdc/StoreModel
     * @instance
     *
     * @type {Number}
     */
    budget: attr('number'),
    /**
     * @memberof Vdc/StoreModel
     * @instance
     *
     * @type {String}
     */
    customer_identifier: attr('nullable-string'),
    /**
     * @memberof Vdc/StoreModel
     * @instance
     *
     * @type {String}
     */
    aggregated_assembly_status: attr('enum-string', {
      options: aggregatedAssembliesStatuses
    }),
    /**
     * @memberof Vdc/StoreModel
     * @instance
     *
     * @type {Number}
     */
    aggregated_assembly_article_amount: attr('number'),
    /**
     * @memberof Vdc/StoreModel
     * @instance
     *
     * API sends a fragment of this attribute set, with only those attributes that belong to store_type
     * but with still the same id
     * If we set this as `belongsTo('vdc/attribute-set')`, then EmberData will overwrite the values.
     *
     * @type {Vdc/AttributeSetModel}
     */
    attribute_set: attr(),
    /**
     * @memberof Vdc/StoreModel
     * @instance
     *
     * @type {Vdc/AddressModel}
     */
    default_address: belongsTo('vdc/address', {
      async: false
    }),
    /**
     * @memberof Vdc/StoreModel
     * @instance
     *
     * @type {Vdc/MarketModel}
     */
    market: belongsTo('vdc/market', {
      async: false
    }),
    /**
     * @memberof Vdc/StoreModel
     * @instance
     *
     * @type {Vdc/AssemblyModel}
     */
    assembly: belongsTo('vdc/assembly'),
    /**
     * @memberof Vdc/StoreModel
     * @instance
     *
     * @type {Vdc/AddressModel}
     */
    delivery_address: belongsTo('vdc/address', {
      async: false
    }),
    /**
     * @memberof Vdc/StoreModel
     * @instance
     *
     * @type {Vdc/AddressModel}
     */
    invoice_address: belongsTo('vdc/address', {
      async: false
    }),
    /**
     * @memberof Vdc/StoreModel
     * @instance
     *
     * @type {Vdc/ContractModel}
     */
    contracts: hasMany('vdc/contract', {
      async: true
    }),
    /**
     * @memberof Vdc/StoreModel
     * @instance
     *
     * @type {Array}
     */
    pictures: hasMany('vdc/picture', {
      async: false
    }),
    /**
     * @memberof Vdc/StoreModel
     * @instance
     *
     * @type {Vdc/fragments/AttributeValueFragment[]}
     */
    attribute_values: (0, _attributes.fragmentArray)('vdc/fragments/attribute-value'),
    /**
     * @memberof Vdc/StoreModel
     * @instance
     *
     * @type {Boolean}
     */
    isClosed: Ember.computed('closing_date', function () {
      var closingDate = this.get('closing_date');
      var _Date$toJSON$split = new Date().toJSON().split('T'),
        _Date$toJSON$split2 = _slicedToArray(_Date$toJSON$split, 1),
        today = _Date$toJSON$split2[0]; // To get the timestamp as '2017-01-24'

      if (!closingDate) {
        return false;
      }
      return closingDate < today;
    }),
    /**
     * @memberof Vdc/StoreModel
     * @instance
     *
     * @type {Boolean}
     */
    isInProgress: Ember.computed('opening_date', function () {
      var openingDate = this.get('opening_date');
      var _Date$toJSON$split3 = new Date().toJSON().split('T'),
        _Date$toJSON$split4 = _slicedToArray(_Date$toJSON$split3, 1),
        today = _Date$toJSON$split4[0]; // To get the timestamp as '2017-01-24'

      if (!openingDate) {
        return false;
      }
      return openingDate > today;
    })
  });
  StoreModel.reopenClass({
    /**
     * @memberof Vdc/StoreModel
     * @instance
     *
     * @type {EnumOption[]}
     */
    aggregatedAssembliesStatuses: aggregatedAssembliesStatuses,
    /**
     * @memberof Vdc/StoreModel
     * @instance
     *
     * @type {EnumOption[]}
     */
    assemblyTargetTypes: assemblyTargetTypes,
    /**
     * @memberof Vdc/StoreModel
     * @instance
     *
     * @type {EnumOption[]}
     */
    storeStatuses: storeStatuses
  });
  var _default = _exports.default = StoreModel;
});