define("ln-ember-toolkit/mixins/media-events", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  function triggerMediaDidChange(mediaVersion) {
    this.trigger('mediaDidChange', mediaVersion);
  }
  var _default = _exports.default = Ember.Mixin.create({
    mediaMatcher: Ember.inject.service(),
    isDesktop: Ember.computed.alias('mediaMatcher.isDesktop'),
    isMobile: Ember.computed.alias('mediaMatcher.isMobile'),
    didInsertElement: function didInsertElement() {
      this._super.apply(this, arguments);
      this.set('mediaVersion', this.get('mediaMatcher.mediaVersion'));
      this.mediaMatcher.on('mediaDidChange', this, triggerMediaDidChange);
    },
    willDestroyElement: function willDestroyElement() {
      this._super.apply(this, arguments);
      this.mediaMatcher.off('mediaDidChange', this, triggerMediaDidChange);
    }
  });
});