define("ln-ember-data-models/models/vdc/fragments/project-marketing-category", ["exports", "ember-data", "ember-data-model-fragments/fragment"], function (_exports, _emberData, _fragment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var attr = _emberData.default.attr;

  /**
   * @class Vdc/fragments/ProjectMarketingCategoryFragment
   * @extends Fragment
   */
  var ProjectMarketingCategoryFragment = _fragment.default.extend({
    /**
     * @memberof Vdc/fragments/ProjectMarketingCategoryFragment
     * @instance
     *
     * @type {Number}
     */
    marketing_category_id: attr('id'),
    /**
     * @memberof Vdc/fragments/ProjectMarketingCategoryFragment
     * @instance
     *
     * @type {Number}
     */
    slots_amount: attr('number'),
    marketing_category: Ember.computed('_marketingCategoryLoaded', 'marketing_category_id', function () {
      var _this = this;
      if (!this.marketing_category_id) {
        return null;
      }
      var marketingCategory = this.store.peekRecord('vdc/marketing-category', this.marketing_category_id);
      if (!marketingCategory && !this._marketingCategoryLoaded) {
        return this.store.findRecord('vdc/marketing-category', this.marketing_category_id).finally(function () {
          // Side-effect is needed for caching
          // eslint-disable-next-line ember/no-side-effects
          _this.set('_marketingCategoryLoaded', true);
        });
      }
      return marketingCategory;
    }),
    _marketingCategoryLoaded: null
  });
  var _default = _exports.default = ProjectMarketingCategoryFragment;
});