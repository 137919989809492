define("ln-ember-form-elements/utils/weak-equals", ["exports", "ln-ember-form-elements/utils/weak-find"], function (_exports, _weakFind) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = weakEquals;
  /**
     * Checks if the  two arrays are equal regarding datatype differences.
     *
     * @param {Array} array  array to check
     * @param {Array} otherArray array to be checked
     * @returns {Boolean}
     */
  function weakEquals(array, otherArray) {
    if (array.length !== otherArray.length) {
      return false;
    }
    for (var i = 0; i < array.length; i++) {
      if (!(0, _weakFind.default)(otherArray, array[i])) {
        return false;
      }
    }
    return true;
  }
});